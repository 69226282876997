import { type WarehouseContextValue } from '../../../utils/contexts/warehouse/WarehouseContext'
import { type AccessControlConfig } from './accessControl.utils'
import { useAccessControlCommon } from './useAccessControlCommon'
import { useOptionalWarehouseContext } from '../../../utils/contexts/warehouse/WarehouseContext.hooks'
import { useOptionalPartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'

/**
 * Returns true if the access control requirements are met for the
 * current user. Otherwise returns false.
 */
function useAccessControl(
  overrideWarehouseContext?: WarehouseContextValue
): (accessControlConfig: AccessControlConfig | AccessControlConfig[]) => boolean {
  const warehouseContext = useOptionalWarehouseContext()
  const partnerContext = useOptionalPartnerContext()

  let context
  if (overrideWarehouseContext?.validId) {
    context = overrideWarehouseContext
  } else if (warehouseContext?.validId) {
    context = warehouseContext
  } else {
    context = partnerContext
  }

  return useAccessControlCommon(context)
}

export default useAccessControl
