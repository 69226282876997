const EN_US = {
  'storefrontOnboardingDomain.title': 'Storefront Onboarding',
  'storefrontOnboardingDomain.ios.title': 'Storefront iOS Onboarding',
  'storefrontOnboardingDomain.android.title': 'Storefront Android App Onboarding',
  'storefrontOnboardingDomain.web.title': 'Storefront Web Onboarding',
  'storefrontOnboardingDomain.webStatus.title': 'Storefront Web Onboarding Status Page',
  'storefrontOnboardingDomain.web.nav': 'Web onboarding',
  'storefrontOnboardingDomain.android.nav': 'Android app onboarding',
  'storefrontOnboardingDomain.ios.nav': 'iOS app onboarding',
  'storefrontOnboardingDomain.upload.retryButton': 'Retry',
  'storefrontOnboardingDomain.upload.fileTypeError': 'Wrong file type',
  'retailerCollectionsDomain.upload.uploadText': 'Drag file here to select for upload',
  'retailerCollectionsDomain.upload.or': 'or',
  'retailerCollectionsDomain.upload.uploadButton': 'browse to select a file',
  'storefrontOnboardingDomain.upload.uploadError': 'Error while uploading',
  'storefrontOnboardingDomain.upload.noFileError': 'No file selected',
  'storefrontOnboardingDomain.upload.success': 'Upload succeeded',
  'storefrontOnboardingDomain.upload.uploadButton': 'Submit File',
  'storefrontOnboardingDomain.upload.uploadButtonWithFile': 'Submit ',
  'storefrontOnboardingDomain.upload.cancelButton': 'Cancel',
  'storefrontOnboardingDomain.wizard.complete': 'Complete',
  'storefrontOnboardingDomain.wizard.notComplete': 'Not Complete',
  'storefrontOnboardingDomain.wizard.submit': 'Submit',
  'storefrontOnboardingDomain.modal.close': 'Close',
  'storefrontOnboardingDomain.upload.fileViewable':
    'A file already exists, click the icon to view it',
  'storefrontOnboardingDomain.upload.fileNotViewable': 'A file exists, but is not viewable',
  'storefrontOnboardingDomain.error.formValidations':
    'The form has one or more validation errors and cannot be submitted.',
  'storefrontOnboardingDomain.notification.saveSuccess': 'Save succeeded',
  'storefrontOnboardingDomain.web.formValidationError':
    'Cannot submit form while fields have invalid values.',
  'storefrontOnboardingDomain.web.triggerWorkflowSuccess':
    'An ISC task has been successfully triggered.',
  'storefrontOnboardingDomain.mobileAppTitle': 'Mobile App Setup Wizard',
  'storefrontOnboardingDomain.mobileApp.buildInstructions':
    'Once all the data is staged, you can build the app by clicking the button below.',
  'storefrontOnboardingDomain.mobileApp.buildButton': 'Build App',
  'storefrontOnboardingDomain.mobileApp.buildBranch': 'Enter the branch to build',
  'storefrontOnboardingDomain.mobileApp.previousBuilds': 'Previous builds',
  'storefrontOnboardingDomain.mobileApp.previousBuildsCols': 'id / created at / status',
  'storefrontOnboardingDomain.mobileApp.buildTriggeredNotification': 'App build triggered',
  'storefrontOnboardingDomain.workflowList.noStoreConfig':
    'Please create a storefront configuration first',
  'storefrontOnboardingDomain.workflowList.refresh': 'Refresh',
  'storefrontOnboardingDomain.workflowList.error':
    'There is an error fetching the workflow list. Please refresh or contact SFX support team.',
  'storefrontOnboardingDomain.workflowList.empty':
    'There is no async workflow for this store configuration currently. Please refresh to see the latest result.',
} as const

export default EN_US
