import React, { lazy } from 'react'
import {
  ViewType,
  HideMarketplace,
  type RetailerScopeWrapperProps,
} from '@retailer-platform/dashboard/gin-and-tonic'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { siteManagementAccessControl } from '../access-control/siteManagementAccess.configuration'

const NavigationDesktopPage = lazy(() => import('../pages/navigation/NavigationDesktopPage'))
const NavigationMobileWebPage = lazy(() => import('../pages/navigation/NavigationMobileWebPage'))
const NavigationNativePage = lazy(() => import('../pages/navigation/NavigationNativePage'))
const NavigationDesktopStylesPage = lazy(
  () => import('../pages/navigation/NavigationDesktopStylesPage')
)
const NavigationMwebStylesPage = lazy(
  () => import('../pages/navigation/NavigationMobileWebStylesPage')
)
const NavigationNativeStylesPage = lazy(
  () => import('../pages/navigation/NavigationNativeStylesPage')
)
// @hygen:inject page-import

const commonNavigationScopePicker = {
  titles: ['siteManagementDomain.title'],
  selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
  storeConfigOperationIntent: 'modify',
  storeConfigFilter: HideMarketplace,
} as RetailerScopeWrapperProps

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const siteManagementRouter = (
  <DomainRoute route="site-management">
    <DashSwitch>
      <DomainRoute
        exact
        route="site-management-navigation-desktop"
        component={NavigationDesktopPage}
        accessControl={siteManagementAccessControl}
        scopePicker={commonNavigationScopePicker}
      />
      <DomainRoute
        exact
        route="site-management-navigation-mweb"
        component={NavigationMobileWebPage}
        scopePicker={commonNavigationScopePicker}
      />
      <DomainRoute
        exact
        route="site-management-navigation-native"
        component={NavigationNativePage}
        scopePicker={commonNavigationScopePicker}
      />
      <DomainRoute
        exact
        route="site-management-navigation-desktop-styles"
        component={NavigationDesktopStylesPage}
      />
      <DomainRoute
        exact
        route="site-management-navigation-mweb-styles"
        component={NavigationMwebStylesPage}
      />
      <DomainRoute
        exact
        route="site-management-navigation-native-styles"
        component={NavigationNativeStylesPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
