export enum Permission {
  StoreConfigsModify = 'store_configs.modify',
  StoreConfigsView = 'store_configs.view',
  Storefront = 'storefront',
  StorefrontNavigationModify = 'storefront_navigation.modify',
  StorefrontNavigationView = 'storefront_navigation.view',
  PageBuilderModify = 'page_builder.modify',
  PageBuilderView = 'page_builder.view',
  PlacementManagerModify = 'placement_manager.modify',
  PlacementManagerView = 'placement_manager.view',
  ReadOnly = 'revoke_writes.legacy_permission',
  StoreConfigurationDraftModify = 'store_configurations_draft.modify',
  StoreConfigurationDraftView = 'store_configurations_draft.view',
  AdminPanel = 'admin_panel.legacy_permission',
  AutosuggestModify = 'autosuggest_links.modify',
  AutosuggestView = 'autosuggest_links.view',
  DepartmentsView = 'retailer_collections_departments.view',
  DepartmentsModify = 'retailer_collections_departments.modify',
  CreativeManagerView = 'creative_manager.view',
  CreativeManagerModify = 'creative_manager.modify',
}
