import { NewSelect } from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useMemo } from 'react'
import { usePartnerId } from '@retailer-platform/dashboard/routing'
import { useDomainMessages, type DomainMessages } from '../../../../utils/domain/intl'
import { type ReportTypeEnum, useGetNormalizedReportTypesQuery } from '../../../../api'

export interface ReportsNormalizedReportsListReportTypeSelectProps {
  selectedReportType: ReportTypeEnum
  onChange: (r: ReportTypeEnum) => void
}

// add a translation to display a report type in the dropdown
const reportTypeMessageIds: Partial<Record<ReportTypeEnum, DomainMessages>> = {
  WEEKLY_RETAILER_UPDATE: 'reportsDomain.normalizedReports.list.reportTypes.WEEKLY_RETAILER_UPDATE',
  OUT_OF_STOCK: 'reportsDomain.normalizedReports.list.reportTypes.OUT_OF_STOCK',
  ORDER_RATINGS: 'reportsDomain.normalizedReports.list.reportTypes.ORDER_RATINGS',
  LIVE_ISSUE: 'reportsDomain.normalizedReports.list.reportTypes.LIVE_ISSUE',
  UNATA_EXPRESS_WEEKLY: 'reportsDomain.normalizedReports.list.reportTypes.UNATA_EXPRESS_WEEKLY',
  RUNNER_HOURS: 'reportsDomain.normalizedReports.list.reportTypes.RUNNER_HOURS',
  REPLACEMENTS_AND_REFUNDS:
    'reportsDomain.normalizedReports.list.reportTypes.REPLACEMENTS_AND_REFUNDS',
  STORE_LEVEL_PARTNER_PERFORMANCE_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.STORE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  DLOG_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.DLOG_MONTHLY',
  MLOG_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.MLOG_MONTHLY',
  UUID_COMPLIANCE: 'reportsDomain.normalizedReports.list.reportTypes.UUID_COMPLIANCE',
  DISTRIBUTION_SYSTEM_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.DISTRIBUTION_SYSTEM_REPORT',
  SHOPPER_BREAKS: 'reportsDomain.normalizedReports.list.reportTypes.SHOPPER_BREAKS',
  RETAILER_PICKUP: 'reportsDomain.normalizedReports.list.reportTypes.RETAILER_PICKUP',
  RETAILER_TAX_LOGS: 'reportsDomain.normalizedReports.list.reportTypes.RETAILER_TAX_LOGS',
  TAXES_PAID_AT_POS: 'reportsDomain.normalizedReports.list.reportTypes.TAXES_PAID_AT_POS',
  MPF_TAXES_SEMI_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.MPF_TAXES_SEMI_MONTHLY',
  ADS_DAILY: 'reportsDomain.normalizedReports.list.reportTypes.ADS_DAILY',
  ADS_WEEKLY: 'reportsDomain.normalizedReports.list.reportTypes.ADS_WEEKLY',
  ADS_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.ADS_MONTHLY',
  DISPLAY_PREVIEW_DAILY: 'reportsDomain.normalizedReports.list.reportTypes.DISPLAY_PREVIEW_DAILY',
  DAILY_ULTRAFAST_DASHBOARD:
    'reportsDomain.normalizedReports.list.reportTypes.DAILY_ULTRAFAST_DASHBOARD',
  DAILY_ULTRAFAST_ORDER_REPORTING:
    'reportsDomain.normalizedReports.list.reportTypes.DAILY_ULTRAFAST_ORDER_REPORTING',
  MERGED_RETAILER_TAX_LOGS:
    'reportsDomain.normalizedReports.list.reportTypes.MERGED_RETAILER_TAX_LOGS',
  MERGED_TAXES_PAID_AT_POS:
    'reportsDomain.normalizedReports.list.reportTypes.MERGED_TAXES_PAID_AT_POS',
  MERGED_SHOPPER_REIMBURSEMENTS_MONTHLY:
    'reportsDomain.normalizedReports.list.reportTypes.MERGED_SHOPPER_REIMBURSEMENTS_MONTHLY',
  NEW_VERTICALS_WEEKLY_RETAILER_UPDATE:
    'reportsDomain.normalizedReports.list.reportTypes.NEW_VERTICALS_WEEKLY_RETAILER_UPDATE',
  DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT',
  SCAN_AND_PAY: 'reportsDomain.normalizedReports.list.reportTypes.SCAN_AND_PAY',
  CAPER_DAILY_TRANSACTIONS_SUMMARY:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_DAILY_TRANSACTIONS_SUMMARY',
  CAPER_DAILY_TRANSACTIONS_DETAILS:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_DAILY_TRANSACTIONS_DETAILS',
  ALDI_ACI_DAILY_STORE_LEVEL_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.ALDI_ACI_DAILY_STORE_LEVEL_REPORT',
  ALDI_ACI_DAILY_REPORT: 'reportsDomain.normalizedReports.list.reportTypes.ALDI_ACI_DAILY_REPORT',
  GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT',
  CAPER_INCENTIVES_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_INCENTIVES_REPORT',
  BYPASS_ORDERS_FEEDBACK_OUTSTANDING_FILES_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.BYPASS_ORDERS_FEEDBACK_OUTSTANDING_FILES_REPORT',
  SHOPPER_REIMBURSEMENTS_MONTHLY:
    'reportsDomain.normalizedReports.list.reportTypes.SHOPPER_REIMBURSEMENTS_MONTHLY',
  STORE_IN_STORE_PERFORMANCE_AGG:
    'reportsDomain.normalizedReports.list.reportTypes.STORE_IN_STORE_PERFORMANCE_AGG',
  STORE_IN_STORE_PERFORMANCE_DTL:
    'reportsDomain.normalizedReports.list.reportTypes.STORE_IN_STORE_PERFORMANCE_DTL',
}

export const ReportsNormalizedReportsListReportTypeSelect: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListReportTypeSelectProps>
> = ({ selectedReportType, onChange }) => {
  const partnerId = usePartnerId()
  const { data, error } = useGetNormalizedReportTypesQuery({ variables: { partnerId } })

  const messages = useDomainMessages({
    all: 'reportsDomain.normalizedReports.list.reportTypes.ALL',
    ...reportTypeMessageIds,
  })

  const options = useMemo(() => {
    const reportTypesWithi18n =
      data?.normalizedReportTypes
        .map(
          item =>
            messages[item.value] && {
              ...item,
              label: messages[item.value] ?? item.label,
            }
        )
        .filter(Boolean) ?? []

    return [{ value: null, label: messages.all }, ...reportTypesWithi18n]
  }, [messages, data])

  return (
    <NewSelect
      aria-label="ReportsNormalizedReportsListReportTypeSelect"
      options={options}
      value={selectedReportType}
      onChange={val => onChange(val)}
      compact
    />
  )
}
