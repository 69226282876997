import React, { useState, useMemo, useEffect } from 'react'
import {
  NewSelect,
  spacing,
  useDebouncedState,
  Badge,
  Button,
} from '@retailer-platform/shared-components'
import { components, type OptionProps } from 'react-select'
import styled from '@emotion/styled'
import { OpenInIcon } from '@instacart/ids-core'
import { useParams } from 'react-router'
import { useRetailersStoreConfigurations } from '@retailer-platform/dashboard/api'
import { useOptionalWarehouseContext } from '@retailer-platform/dashboard/utils'
import { clientEnv } from '@retailer-platform/dashboard/env'
import { useDomainMessages } from '../../../utils/domain/intl'
import {
  type RetailerCollectionSearchArgs,
  useGetRetailerCollections,
} from '../../../api/getRetailerCollections.hooks'
import { StoreConfigurationRelationshipEnum } from '../../../api'

const MIN_COLLECTION_SEARCH_TERM_LENGTH = 3

export enum CollectionSelectionValue {
  CollectionId = 'collectionId',
  CollectionSlug = 'collectionSlug',
}

interface Collection {
  label: string
  value: string
  id?: string
  name?: string
  slug?: string
  startDate?: string
  endDate?: string
}

export interface Props extends React.ComponentProps<typeof NewSelect> {
  collectionTypes?: RetailerCollectionSearchArgs['collectionTypes']
  initialSlug?: string
  isDisabled?: boolean
  initialCollections?: Collection[]
  additionalOptions?: { label: string; value: string }[]
  showTags?: boolean
  excludeDynamicRetailerCollections?: boolean
  selectionValueType?: CollectionSelectionValue
  setCollectionId?: (value: string) => void
  retailerId?: string
}

interface OptionData {
  label: string
  value: string
  id?: string
  name?: string
  slug?: string
  collectionType?: string
}

const Container = styled.div({
  '> *': {
    lineHeight: spacing.X24,
  },
})

const OptionContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const LabelContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: `${spacing.X8} 0`,
})

const StyledBadge = styled(Badge)({
  padding: `0px ${spacing.X8}`,
  marginRight: spacing.X4,
})

const Option = ({ data, ...props }: OptionProps<Props>) => {
  const i18n = useDomainMessages({
    icOwned: 'storefrontDomain.collections-selector.icOwned',
    retailerOwned: 'storefrontDomain.collections-selector.retailerOwned',
  })

  const STG_URL = 'https://web-instacart-customers-stg.instacart.team'
  const LOCALE_DOMAIN_MAP = {
    CA: 'https://instacart.ca',
    US: 'https://instacart.com',
  }

  const storeConfigData = useRetailersStoreConfigurations({
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
    throwOnMissing: false,
  })

  const localeCode = useOptionalWarehouseContext()?.warehouse?.locales?.[0].localeCode
  const country = localeCode?.split('_')[1] || ''
  const { slug: warehouseSlug } = useOptionalWarehouseContext()?.warehouse || {}
  const env = clientEnv.PUBLIC_CLIENT_ENVIRONMENT
  const optionData: OptionData = data

  const params = useParams<{ storeConfigId?: string }>()

  const handlePreviewClick = () => {
    let domainName = ''

    if (params?.storeConfigId) {
      domainName = storeConfigData.data.storeConfigurationsByRetailerIds?.find(
        sc => sc.id === params?.storeConfigId
      ).domainName
    } else if (['development', 'staging'].includes(env)) {
      domainName = STG_URL
    } else {
      domainName = LOCALE_DOMAIN_MAP[country] ?? LOCALE_DOMAIN_MAP.US
    }

    if (optionData.value) {
      window.open(`${domainName}/store/${warehouseSlug}/collections/${optionData.slug}`, '_blank')
    } else {
      console.warn('Collection slug not found')
    }
  }

  return (
    <OptionContainer>
      <components.Option {...props} data={optionData} css={{ flexGrow: 1 }}>
        <LabelContainer>
          {props.children}
          <div>
            {/* Hardcoded collection may not have slug */}
            <StyledBadge>
              {optionData?.slug?.replace('^rc-', '').replace('^n-', '') ?? ''}
            </StyledBadge>
            <StyledBadge>
              {optionData?.collectionType === 'department' ? i18n.icOwned : i18n.retailerOwned}
            </StyledBadge>
          </div>
        </LabelContainer>
      </components.Option>
      <Button compact variant="transparent" onClick={handlePreviewClick}>
        <OpenInIcon size={16} />
      </Button>
    </OptionContainer>
  )
}

const CollectionSelector = (props: Props) => {
  const {
    initialSlug,
    initialCollections,
    isDisabled,
    additionalOptions,
    showTags,
    excludeDynamicRetailerCollections,
    collectionTypes,
    selectionValueType = CollectionSelectionValue.CollectionSlug,
    retailerId,
  } = props
  const i18n = useDomainMessages({
    placeholder: 'storefrontDomain.collections-selector.placeholder',
    icOwned: 'storefrontDomain.collections-selector.icOwned',
    retailerOwned: 'storefrontDomain.collections-selector.retailerOwned',
  })

  useEffect(() => {
    setSlugs([initialSlug])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState('', 200)
  const [slugs, setSlugs] = useState<string[] | undefined>()
  const shouldPerformSearch = debouncedSearchTerm.length >= MIN_COLLECTION_SEARCH_TERM_LENGTH
  const searchTermIsSlug =
    debouncedSearchTerm.startsWith('rc-') || debouncedSearchTerm.startsWith('n-')

  // We need to keep track of the selected options so that we can make sure they are always available in the dropdown regardless of the search term
  const [selectedOptionsCache, setSelectedOptionsCache] = useState(initialCollections ?? [])

  const getSlugs = () => {
    if (searchTermIsSlug) return [debouncedSearchTerm.replace('rc-', '').replace('n-', '')]
    if (debouncedSearchTerm.length || !slugs) return
    return slugs.filter(Boolean).map(s => s.replace('rc-', '').replace('n-', ''))
  }

  const getSearchTerm = () => {
    if (searchTermIsSlug) return
    return debouncedSearchTerm
  }

  const { apiResult, apiLoading } = useGetRetailerCollections({
    ...(retailerId && { retailerId }),
    searchTerm: getSearchTerm(),
    skip: !shouldPerformSearch && !initialSlug,
    collectionTypes,
    slugs: getSlugs(),
    excludeDynamicRetailerCollections,
  })

  const options: OptionData[] = useMemo(
    () =>
      (apiResult ?? []).map(x => ({
        ...x,
        label: x.name,
        value: selectionValueType === CollectionSelectionValue.CollectionId ? x.id : x.slug,
      })),
    [apiResult, selectionValueType]
  )

  // Look at removing this block and use the search in new select
  const filteredAdditionalOptions: OptionData[] = useMemo(() => {
    if (!additionalOptions || !shouldPerformSearch) return []

    const searchTerm = debouncedSearchTerm.toLowerCase()
    return additionalOptions.filter(o => o.label.toLowerCase().includes(searchTerm))
  }, [additionalOptions, debouncedSearchTerm, shouldPerformSearch])

  return (
    <Container>
      <NewSelect
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        components={{ Option: showTags ? Option : components.Option } as any}
        // Add selected options here so they are always shown in the selected bar
        options={[...selectedOptionsCache, ...filteredAdditionalOptions, ...options]}
        css={{ zIndex: 100 }}
        isLoading={apiLoading}
        onInputChange={(value, meta) => {
          // Don't clear search if a value is being set as it causes the dropdown to close
          if (meta?.action === 'set-value' && !value) return
          return setSearchTerm(value)
        }}
        placeholder={i18n.placeholder}
        {...props}
        onChange={(value, options) => {
          // need to cast to OptionData for setCollectionId
          const collectionResult = options as OptionData
          if (props?.setCollectionId && collectionResult?.id) {
            props.setCollectionId(collectionResult.id)
          }

          if (props.onChange) {
            props.onChange(value, options)
          }

          setSelectedOptionsCache(Array.isArray(options) ? options : [options])
        }}
        isDisabled={isDisabled}
      />
    </Container>
  )
}

CollectionSelector.defaultProps = {
  collectionTypes: ['retailer_collection'],
  showTags: true,
}

export default CollectionSelector
