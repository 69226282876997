import { SFXVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'
import { FeatureEntitlements } from './featureEntitlements'

export const brandingV2AccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: FeatureToggle.brandingV2,
}

export const pageBuilderViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
  featureEntitlementIds: [FeatureEntitlements.PageBuilder],
}

export const pageBuilderModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.PageBuilderModify],
  featureEntitlementIds: [FeatureEntitlements.PageBuilder],
}

export const marketplaceOnlyPageBuilderViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
  featureToggles: [FeatureToggle.pageBuilderSingleRetailer], // Feature toggle for now since internal only
}

export const marketplaceOnlyPageBuilderModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.PageBuilderModify],
  featureToggles: [FeatureToggle.pageBuilderSingleRetailer], // Feature toggle for now since internal only
}

export const readOnlyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ReadOnly],
}

export const storeConfigurationDraftViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.StoreConfigurationDraftView],
  minSfxVersion: SFXVersion.r2023_09_0,
}

export const storeConfigurationDraftModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.StoreConfigurationDraftModify],
}

export const storefrontBrandingCustomFontsControl: DomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.StorefrontBrandingCustomFonts],
}

export const storefrontCustomImagesAccessControl: DomainAccessControlConfig = {
  // note: internal only feature for now
  permissions: [
    Permission.StoreConfigsView,
    Permission.StoreConfigsModify,
    Permission.StoreConfigurationDraftView,
    Permission.StoreConfigurationDraftModify,
  ],
}
