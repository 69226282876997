const EN_US = {
  'scanAndPayDomain.title': 'Scan And Pay',
  'scanAndPayDomain.refundModal.unitPrice': 'Unit Price',
  'scanAndPayDomain.refundModal.qtyInOrder': 'Qty in order',
  'scanAndPayDomain.refundModal.chargedAmount': 'Charged Amount',
  'scanAndPayDomain.refundModal.refund': 'Refund',
  'scanAndPayDomain.refundModal.item': ' item',
  'scanAndPayDomain.refundModal.items': ' items',
  'scanAndPayDomain.refundModal.refundItems': 'Refund items',
  'scanAndPayDomain.refundModal.itemList': 'Item List',
  'scanAndPayDomain.refundModal.prevRefundedItems': 'Previously Refunded Items',
  'scanAndPayDomain.refundModal.orderBreakdown': 'Order Breakdown',
  'scanAndPayDomain.refundModal.originalOrderTotal': 'Original Order Total',
  'scanAndPayDomain.refundModal.previouslyRefunded': 'Previously Refunded',
  'scanAndPayDomain.refundModal.pendingRefunds': 'Proposed Refund',
  'scanAndPayDomain.refundModal.refundsMayDiffer':
    'The final refund amount may differ based on promotions.',
  'scanAndPayDomain.refundModal.refundAmount': 'Proposed Refund Amount:',
  'scanAndPayDomain.refundModal.completeRefund': 'Complete Refund',
  'scanAndPayDomain.refundModal.reviewRefund': 'Review Refund',
  'scanAndPayDomain.refundModal.reviewBack': 'Back',
  'scanAndPayDomain.refundModal.requestRefund': 'Request Refund',
  'scanAndPayDomain.refundModal.itemDescription': 'Item',
  'scanAndPayDomain.refundModal.chargePrice': 'Charge Price',
  'scanAndPayDomain.refundModal.currency': 'USD',
  'scanAndPayDomain.refundModal.quantity': 'Original Qt.',
  'scanAndPayDomain.refundModal.action': 'Action',
  'scanAndPayDomain.refundModal.orderNumber': 'Order #',
  'scanAndPayDomain.refundModal.subTotal': 'Refund Subtotal',
  'scanAndPayDomain.refundModal.refundRequested': 'Refund has been requested',
  'scanAndPayDomain.refundModal.refundProcessInfo':
    'Please allow for 3 to 5 business days for it to process.',
  'scanAndPayDomain.refundModal.done': 'Done',
  'scanAndPayDomain.refundModal.refunded': 'Refunded',
  'scanAndPayDomain.refundModal.newOrderTotal': 'New Order Total',
  'scanAndPayDomain.refundModal.afterRefund': 'After Refund',
  'scanAndPayDomain.refundModal.refundRequestSending': 'Request for refund is being sent...',
  'scanAndPayDomain.refundModal.pleaseWait': 'Please wait',
  'scanAndPayDomain.refundModal.refundRequestError':
    'There was an error while requesting for refund...',
} as const

export default EN_US
