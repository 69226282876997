import React, { type FunctionComponent } from 'react'
import { matchPath, useLocation } from 'react-router'
import {
  spacing,
  fontWeight,
  fontSize,
  colors,
  Tooltip,
} from '@retailer-platform/shared-components'
import { InformationIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import clientEnv from '../../utils/global/clientEnv'
import { errors } from '../../utils/error-handling/errors'
import { useCoreContext } from '../../utils/core/RPPCoreContext'
import { useCurrentAccountDetails } from '../../api/current-account/currentAccount.hooks'
import { Permission } from '../../legacy/common/types'
import { useTotem } from '../../api/totem/useTotem.hooks'
import { useDashMessages } from '../../intl/intl.hooks'

const SLACK_CHANNEL_TYPE_PUBLIC = 'SLACK_CHANNEL_TYPE_PUBLIC'

const HeaderContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: spacing.X32,
  minHeight: spacing.X32,
  paddingLeft: spacing.X16,
  alignItems: 'center',
  fontSize: fontSize.X12,
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})
const ContactSpan = styled.span({
  marginRight: spacing.X24,
  marginLeft: spacing.X4,
  b: {
    fontWeight: fontWeight.SEMIBOLD,
  },
  a: {
    textDecoration: 'underline',
  },
})

export const ContactHeader: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { account } = useCurrentAccountDetails()
  const { totemInfoByRoute } = useCoreContext()
  const { pathname } = useLocation()
  const i18n = useDashMessages({
    help: 'app.admin.contactHeader.help',
    team: 'app.admin.contactHeader.team',
    slack: 'app.admin.contactHeader.slack',
    oncall: 'app.admin.contactHeader.oncall',
  })

  const totemInfo = React.useMemo(() => {
    const matchedKey = Object.keys(totemInfoByRoute).find(key => {
      if (matchPath(pathname, { path: key, exact: true })?.path) {
        return true
      }
    })
    return totemInfoByRoute[matchedKey]
  }, [pathname, totemInfoByRoute])

  const hasTotemAccess = React.useMemo(
    () => account?.permissions.some(p => p.slug === Permission.Totem),
    [account]
  )

  const shouldDisplay = React.useMemo(
    () =>
      clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'production' ||
      (totemInfo?.hasDevTooling && clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'enterprise-dev'),
    [totemInfo]
  )

  const baseTotemUrl =
    clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'production'
      ? 'https://totem.instacart.tools'
      : 'https://totem.dev.instacart.tools'

  const { data, loading, error } = useTotem({
    // having no externalId will skip the query
    externalId: hasTotemAccess && shouldDisplay && totemInfo?.routePattern,
  })

  if (!hasTotemAccess || !shouldDisplay || !data || loading || error) {
    if (error) {
      errors.captureException(error)
    }
    return <></>
  }

  // find team name associated with the system. it will exist
  const teamName = data?.owner_team?.workday_org_name

  // find the first public slack channels associated with the system. it may not exist
  const slackChannels = data?.contact_details?.general_slack_channels?.channels?.filter(
    channel => channel.type === SLACK_CHANNEL_TYPE_PUBLIC
  )
  const mainSlackChannel = slackChannels && slackChannels.length > 0 && slackChannels[0]

  // find any on-call handle associated with the system. it may not exist
  const onCallHandle = data?.contact_details?.oncall_slack_group?.handle

  return (
    <HeaderContainer>
      <Tooltip placement="bottom" target={<InformationIcon size={'20px'} />}>
        {i18n.help}
      </Tooltip>
      {teamName && (
        <ContactSpan>
          <b>{i18n.team}: </b>
          <a
            href={`${baseTotemUrl}/teams/${data.owner_team.name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {teamName}
          </a>
        </ContactSpan>
      )}
      {mainSlackChannel && (
        <ContactSpan>
          <b>{i18n.slack}: </b>
          <a
            href={`https://instacart.enterprise.slack.com/archives/${mainSlackChannel.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            #{mainSlackChannel.handle}
          </a>
        </ContactSpan>
      )}
      {onCallHandle && (
        <ContactSpan>
          <b>{i18n.oncall}: </b>@{onCallHandle}
        </ContactSpan>
      )}
    </HeaderContainer>
  )
}
