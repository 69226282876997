import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'

export const retailerFundedMarketingAccessControlV2: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.campaignsV2Enabled],
  },
]

export const retailerFundedMarketingAccessControlV2TemplateAwareness: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.campaignsV2TemplateAwarenessEnabled],
  },
]
