import { useListStoreGroupsQuery } from '../../../../__codegen__/api'
import { edgesToCollection } from '../../../apollo/utils/edgesToCollection'
import { useRetailerId } from '../../../../utils/routing/params.hooks'

export const storeGroupListDefaultParams = {
  numGroups: 200,
}

// Right now we are using the old table implementation, and API pagination hasn't been implemented
// Once we have updated it and implemented API pagination, we can remove the cap for max_pagination_nodes
// and replace it with proper pagination
export const useListStoreGroups = () => {
  const retailerId = useRetailerId()

  const { data, error, loading, refetch } = useListStoreGroupsQuery({
    variables: {
      ...storeGroupListDefaultParams,
      retailerId,
    },
  })

  const edges = (data && data.retailerLocationGroupsByRetailer.edges) || []
  const storeGroups = edgesToCollection(edges)

  return {
    storeGroups,
    loading,
    error,
    refetch,
  }
}
