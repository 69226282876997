import React, { useState } from 'react'
import { useCheckLaunchOnboardingProgress } from '@retailer-platform/domain-onboarding'
import { onboardingAccessControl } from '@retailer-platform/domain-onboarding'
import { useBooleanState } from '@retailer-platform/shared-components'
import clientEnv from '../../../../../utils/global/clientEnv'
import { useCurrentAccountContext } from '../../../../../utils/current-account/CurrentAccountContext'
import { usePartnerContext } from '../../../../../utils/contexts/partner/PartnerContext.hooks'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import useAccessControl from '../../../../../legacy/components/AccessControl/useAccessControl'
import { AppAdminSectionAccessControl } from '../../../../../sections/admin/AdminSection.configuration'
import { useNavContext } from '../../../nav/utils/NavContext'
import { StripeAssociationsAccessControl } from '../../../../../legacy/routes/settings/stripe/StripeAssociations.configuration'
import { NavEntryPoint } from '../../../../../legacy/components/Nav/nav.types'
import { type AccessControlConfig } from '../../../../../legacy/common/accessControl/accessControl.types'
import { PartnerType } from '../../../../../exports/utils'
import { useCurrentAccountDetails } from '../../../../../api/current-account/currentAccount.hooks'
import { trackEvent } from '../../../../../utils/events/trackEvent'
import { AdminControlModal } from '../../../admin-controls-modal/AdminControlsModal'
import { Permission } from '../../../../../legacy/common/types'
import { CollapsibleOption } from './CollapsibleOption'
import { Option } from './Option'
import { SubOption } from './SubOption'
import { UserMenu } from './UserMenu'
import { Divider, TitleText } from './UserSettingsMenu.common'
import { NavEntryPointGroup } from './NavEntryPointGroup'

type Props = {
  setShowPartnerSelector: (value: boolean) => void
  setCurrentlySelectedItem: (value: number) => void
  setShowManageAccount: (value: boolean) => void
  navMenuHierarchy: NavMenuHierarchy
}

export const UserSettingsMenuRetailer: React.FC<React.PropsWithChildren<Props>> = ({
  setShowPartnerSelector,
  setCurrentlySelectedItem,
  setShowManageAccount,
  navMenuHierarchy,
}) => {
  const [currentlyOpenSubMenu, setCurrentlyOpenSubMenu] = useState(-1)
  const [isAdminControlsOpen, openAdminControls, closeAdminControls] = useBooleanState(false)

  const context = useCurrentAccountContext()
  const partnerContext = usePartnerContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const hasAccess = useAccessControl()
  const { hasInProgressLaunchOnboarding, hasLaunchOnboarding } = useCheckLaunchOnboardingProgress()
  const {
    setNavV2Enabled,
    setMostRecentClickedRoute,
    setIsNavV2OptOutSurveyOpen,
    navV2AnnouncementData,
    setNavV2AnnouncementData,
  } = useNavContext()
  const isInternalAdmin = hasAccess({
    permissions: Permission.AdminPanel,
  })

  const { account: currentAccount } = useCurrentAccountDetails()
  const accountHasNavV2 = !!currentAccount?.featureToggles?.find(
    feature => feature.name === 'rt_ia_nav_v2'
  )

  const hasAppAdminAccess = hasAccess(AppAdminSectionAccessControl)

  // for now, hide the settings menu since there are no available tools for IDP partners
  const showSettings = partnerContext.partnerType !== PartnerType.IDP

  const onClose = () => {
    setIsOpen(false)
    setMostRecentClickedRoute(null)
  }

  const docsUrl =
    partnerContext.partnerType === PartnerType.IDP
      ? 'https://docs.instacart.com/developer_platform_api/'
      : clientEnv.PUBLIC_CLIENT_PARTNER_DOCS_URL
  const termsUrl =
    partnerContext.partnerType === PartnerType.IDP
      ? 'https://docs.instacart.com/developer_platform_api/guide/terms_and_policies/developer_terms/'
      : 'https://docs.instacart.com/instacart/ipp-terms/'

  return (
    <UserMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setCurrentlySelectedItem={setCurrentlySelectedItem}
      title={partnerContext.partnerName}
      navMenuHierarchy={navMenuHierarchy}
    >
      <div>
        <TitleText padding={'8px 24px'}>{partnerContext.partnerName}</TitleText>
        <Option
          onClick={() => {
            setShowPartnerSelector(true)
            onClose()
          }}
          title="navV2.userSettings.switchPartner"
        />
        {hasInProgressLaunchOnboarding && (
          <Option
            onClick={onClose}
            route="onboarding-launch-checklist"
            title="onboarding.launchChecklist"
            accessControl={onboardingAccessControl as AccessControlConfig}
          />
        )}
        <Divider />
        {showSettings && (
          <CollapsibleOption
            index={0}
            currentlyOpen={currentlyOpenSubMenu}
            setCurrentlyOpen={setCurrentlyOpenSubMenu}
            title="settings.title"
            onClose={onClose}
          >
            <NavEntryPointGroup
              navEntryPoint={NavEntryPoint.UserMenuSettings}
              onClose={onClose}
              optionType="subOption"
            >
              <SubOption
                onClick={onClose}
                route="stripeSettings"
                title="settings.stripe.title"
                accessControl={StripeAssociationsAccessControl}
                position={0}
              />
              {hasLaunchOnboarding && (
                <SubOption
                  onClick={onClose}
                  route="onboarding-retailer-settings"
                  title="onboardingDomain.navItem.retailerProfile"
                  position={3}
                />
              )}
            </NavEntryPointGroup>
            {isInternalAdmin && (
              <SubOption
                onClick={() => {
                  onClose()
                  openAdminControls()
                }}
                title="app.admin.nav.links.adminControls"
              />
            )}
            <AdminControlModal isOpen={isAdminControlsOpen} onClose={closeAdminControls} />
          </CollapsibleOption>
        )}
        <CollapsibleOption
          index={1}
          currentlyOpen={currentlyOpenSubMenu}
          setCurrentlyOpen={setCurrentlyOpenSubMenu}
          title="navV2.userSettings.userManagement"
          onClose={onClose}
        >
          <NavEntryPointGroup
            navEntryPoint={NavEntryPoint.UserMenuUserManagement}
            onClose={onClose}
            optionType="subOption"
          />
        </CollapsibleOption>
        <CollapsibleOption
          index={2}
          currentlyOpen={currentlyOpenSubMenu}
          setCurrentlyOpen={setCurrentlyOpenSubMenu}
          title="help"
          onClose={onClose}
        >
          <SubOption onClick={onClose} href={docsUrl} title="navV2.userSettings.documentation" />
          <SubOption onClick={onClose} href="https://instacart.com/press" title="press" />
          <SubOption onClick={onClose} href="https://instacart.com/privacy" title="privacy" />
          <SubOption
            onClick={onClose}
            href="https://instacart.safebase.us/"
            title="security_and_trust"
          />
          <SubOption onClick={onClose} href={termsUrl} title="terms" />
        </CollapsibleOption>

        <Divider />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '12px',
            paddingLeft: '27px',
          }}
        >
          <TitleText fontWeight={600} fontSize="14px" lineHeight="20px">
            {context.account.givenName}
          </TitleText>
          <TitleText fontWeight={400} fontSize="12px" lineHeight="16px">
            {context.account.email}
          </TitleText>
        </div>
        {(hasAppAdminAccess || partnerContext?.hasIaNavV2 || accountHasNavV2) && (
          <Option
            onClick={() => {
              setNavV2Enabled(false)
              onClose()
              trackEvent({
                id: 'navV2.navigation.switchToNavV1',
                description: 'Tracks whenever the user switches back to NavV1',
                data: {
                  accountID: context.account.id,
                },
              })
              if (!navV2AnnouncementData?.navV2ManuallyOptedOut) {
                setIsNavV2OptOutSurveyOpen(true)
                setNavV2AnnouncementData({
                  ...navV2AnnouncementData,
                  navV2ManuallyOptedOut: true,
                })
              }
            }}
            title="navV2.userSettings.switchToNavV1"
          />
        )}
        <Option
          onClick={() => {
            setShowManageAccount(true)
            onClose()
          }}
          title="navV2.userSettings.manageAccount"
        />
        <Option onClick={onClose} route="logout" title="logout" />
      </div>
    </UserMenu>
  )
}
