import React, { type FunctionComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import FeatureEducationModal from '../../legacy/components/FeatureHighlights/FeatureEducationModal'
import { NavV2OptOutSurveyController } from '../../gin-and-tonic/common/retailer-satisfaction-survey/nav-v2/NavV2OptOutSurveyController'
import useFeatureEducation from '../../legacy/components/FeatureHighlights/useFeatureEducation'
import { AppContentContainer } from '../../gin-and-tonic/containers/app-content-container/AppContentContainer'
import ErrorBoundary from '../../legacy/components/ErrorBoundary'
import { type DashRouteComponentProps } from '../../legacy/dash-components/dash-route/utils/dashRoute.types'
import { AdminNav } from '../../gin-and-tonic/containers/nav/AdminNav'
import { Z_INDEX_THIRD, DASH_NAV_WIDTH, DASH_NAV_V2_HEIGHT } from '../../legacy/common/theme'
import { CurrentAccountProvider } from '../../utils/current-account/CurrentAccountProvider'
import { useAdminControls } from '../../utils/contexts/admin-controls/AdminControlsContext'
import { ContactHeader } from '../../routes/utils/ContactHeader'
import { MenuBarTop } from '../../gin-and-tonic/containers/nav-v2/components/MenuBarTop'
import { useNavHierarchyAdmin } from '../../gin-and-tonic/containers/nav-v2/NavMenuHierarchy'
import { LeftShortcutsPaneAdmin } from '../../gin-and-tonic/containers/nav-v2/components/LeftShortcutsPane/LeftShortcutsPaneAdmin'
import { useWindowSizeIsWide } from '../../utils/window/windows.hooks'
import { useNavContext } from '../../gin-and-tonic/containers/nav/utils/NavContext'
import { useNavV2Visibility } from '../../routes/logged-in/partner-scoped/partnerScoped.hooks'
import { trackEvent } from '../../utils/events/trackEvent'
import { useCurrentAccountDetails } from '../../api/current-account/currentAccount.hooks'
import { AdminSectionRoutes } from './AdminSectionRoutes'

interface Props extends DashRouteComponentProps {}

const AdminNavContainer = styled.aside({
  position: 'fixed',
  height: '100%',
  display: 'flex',
  width: DASH_NAV_WIDTH,
  top: 0,
  left: 0,
  zIndex: Z_INDEX_THIRD,
})

export const AdminSection: FunctionComponent<React.PropsWithChildren<Props>> = ({
  location: { pathname },
}) => {
  const { navMenuHierarchy } = useNavHierarchyAdmin()
  const { showContactHeader } = useAdminControls()
  useNavV2Visibility()
  const { navV2Enabled: useNavV2, isNavVisible, isNavV2OptOutSurveyOpen } = useNavContext()
  const { account: currentAccount } = useCurrentAccountDetails()

  const { isOpen, onOpen, onClose, onClick, feature } = useFeatureEducation(pathname)

  React.useEffect(() => {
    if (isOpen) {
      onOpen()
    }
  }, [isOpen, onOpen, pathname])

  React.useEffect(() => {
    if (currentAccount?.id) {
      trackEvent({
        id: 'navV2.enabled',
        description: 'Tracks if the user currently has NavV2 Enabled',
        data: {
          enabled: useNavV2,
          accountID: currentAccount.id,
        },
      })
    }
  }, [useNavV2, currentAccount?.id])

  const isWide = useWindowSizeIsWide()

  const topPadding = isNavVisible && useNavV2 ? DASH_NAV_V2_HEIGHT : '0px'
  const leftPadding = useNavV2 ? '0px' : DASH_NAV_WIDTH

  return (
    <Fragment>
      <CurrentAccountProvider>
        {isNavVisible && useNavV2 && <MenuBarTop navMenuHierarchy={navMenuHierarchy} />}
        {isNavVisible && !useNavV2 && (
          <AdminNavContainer>
            <AdminNav />
          </AdminNavContainer>
        )}
        {isNavV2OptOutSurveyOpen && (
          <NavV2OptOutSurveyController
            toolName="NavV2 Opt Out"
            surveySlug="nav_v2_opt_out"
            alwaysShow={true}
          />
        )}

        <div
          css={{
            display: 'flex',
            position: 'absolute',

            left: leftPadding,
            top: topPadding,

            width: `calc(100% - ${leftPadding})`,
            minHeight: `calc(100% - ${topPadding})`,
            height: `calc(100% - ${topPadding})`,
          }}
        >
          {isNavVisible && useNavV2 && isWide && <LeftShortcutsPaneAdmin />}
          <FeatureEducationModal
            isOpen={isOpen}
            onClose={onClose}
            onClick={onClick}
            feature={feature}
          />
          <AppContentContainer>
            {showContactHeader && <ContactHeader />}
            <ErrorBoundary pathname={pathname}>
              <AdminSectionRoutes />
            </ErrorBoundary>
          </AppContentContainer>
        </div>
      </CurrentAccountProvider>
    </Fragment>
  )
}
