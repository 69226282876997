export enum Permission {
  RetailerFundedMarketingViewAccess = 'retailer_funded_marketing.view',
  RetailerFundedMarketingModifyAccess = 'retailer_funded_marketing.modify',
  RetailerFundedMarketingAdminAccess = 'retailer_funded_marketing.admin',
  RetailerFundedMarketingBillingView = 'retailer_funded_marketing_billing.view',
  RetailerFundedMarketingBillingModifyAccess = 'retailer_funded_marketing_billing.modify',
  RetailerFundedMarketingInsights = 'retailer_funded_marketing.insights',
  RetailerFundedMarketingCRMCampaignsView = 'retailer_funded_marketing_crm_campaigns.view',
  RetailerFundedMarketingCRMCampaignsModify = 'retailer_funded_marketing_crm_campaigns.modify',
  RouletteApiAccessView = 'roulette_api_access.view',
  RetailerFundedMarketingToolingPermissiveMode = 'retailer_funded_marketing.tooling_permissive_mode',
}
