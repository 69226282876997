import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  campaignManagementAdminAccessControl,
  campaignManagementAdminCreativesPageAccessControl,
  campaignManagementThemePageAccessControl,
} from '../access-control/campaignManagementAdminAccess.configuration'

const CampaignManagementCalendarPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CalendarPage" */
      '../pages/admin/calendar/CalendarPage.tsx'
    )
)

const CampaignManagementThemePageListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ThemePagesListPage" */
      '../pages/admin/theme-pages/list/ThemePagesListPage'
    )
)
const CampaignManagementThemePageEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignManagementThemePageEditPage" */
      '../pages/admin/theme-pages/edit/ThemePageEditPage'
    )
)
const CampaignManagementThemePageDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ThemePageDetailsPage" */
      '../pages/admin/theme-pages/details/ThemePageDetailsPage'
    )
)
const CampaignManagementThemePageCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignManagementThemePageCreatePage" */
      '../pages/admin/theme-pages/create/ThemePageCreatePage'
    )
)
const PlacementManagerListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PlacementManagerListPage" */
      '../pages/placement-manager/PlacementManagerListPage'
    )
)
const PlacementManagerDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PlacementManagerDetailPage" */
      '../pages/placement-manager/PlacementManagerDetailPage'
    )
)
const PlacementManagerEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PlacementManagerEditPage" */
      '../pages/placement-manager/PlacementManagerEditPage'
    )
)
const PlacementManagerCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "PlacementManagerCreatePage" */
      '../pages/placement-manager/PlacementManagerCreatePage'
    )
)

const CampaignAdminCreativesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignAdminCreativesPage" */
      '../pages/admin/creative-manager/CampaignAdminCreativesPage'
    )
)

const CampaignAdminRedemptionUrlsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignAdminRedemptionsUrlsListPage" */
      '../pages/admin/redemption-urls/list/RedemptionUrlsListPage'
    )
)

const CampaignAdminMultiRetailerPagesDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "MultiRetailerPagesDashboardPage" */
      '../pages/admin/multi-retailer-pages/dashboard/MultiRetailerPagesDashboardPage.tsx'
    )
)

const CampaignAdminMultiRetailerPagesBuilderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "MultiRetailerPagesBuilderPage" */
      '../pages/admin/multi-retailer-pages/builder/MultiRetailerPagesBuilderPage.tsx'
    )
)

// @hygen:inject page-import

export const campaignManagementAdminRouter = (
  <DomainRoute
    route="app-admin-campaign-management"
    accessControl={campaignManagementAdminAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-campaign-management-calendar"
        component={CampaignManagementCalendarPage}
        accessControl={campaignManagementThemePageAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-theme-page-list"
        component={CampaignManagementThemePageListPage}
        accessControl={campaignManagementThemePageAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-theme-page-edit"
        component={CampaignManagementThemePageEditPage}
        accessControl={campaignManagementThemePageAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-theme-page-details"
        component={CampaignManagementThemePageDetailsPage}
        accessControl={campaignManagementThemePageAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-theme-page-create"
        component={CampaignManagementThemePageCreatePage}
        accessControl={campaignManagementThemePageAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-redemption-urls-list"
        component={CampaignAdminRedemptionUrlsListPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-multi-retailer-pages-list"
        component={CampaignAdminMultiRetailerPagesDashboardPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-multi-retailer-pages-create"
        component={CampaignAdminMultiRetailerPagesBuilderPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-multi-retailer-pages-edit"
        component={CampaignAdminMultiRetailerPagesBuilderPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-surface-instance-list"
        component={PlacementManagerListPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-surface-instance-details"
        component={PlacementManagerDetailPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-surface-instance-edit"
        component={PlacementManagerEditPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-surface-instance-create"
        component={PlacementManagerCreatePage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-list"
        component={PlacementManagerListPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-details"
        component={PlacementManagerDetailPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-edit"
        component={PlacementManagerEditPage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-placement-manager-create"
        component={PlacementManagerCreatePage}
      />
      <DomainRoute
        exact
        route="app-admin-campaign-management-creatives"
        component={CampaignAdminCreativesPage}
        accessControl={campaignManagementAdminCreativesPageAccessControl}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
