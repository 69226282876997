import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ORDERS_OPERATIONAL_DASHBOARD = `${URL_RETAILER_SCOPED}/orders-operational-dashboard`
const URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search`
const URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_SEARCH_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search/search`
const URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_LMD_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search/lmd`
const URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_DELIVERY_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search/delivery`
const URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_PICKUP_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search/pickup`
const URL_ORDERS_OPERATIONAL_DASHBOARD_DASHBOARD_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search/dashboard`

const URL_ORDERS_OPERATIONAL_DASHBOARD_TRACKING_PAGE = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/tracking/:orderType/:orderId`
const URL_ORDERS_OPERATIONAL_DASHBOARD_DETAILS_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/details/:orderType/:orderId`
const URL_ORDERS_OPERATIONAL_DASHBOARD_PICKUP_DETAILS_PAGE = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/pickup/:orderId`

const URL_CUSTOMERS = `${URL_RETAILER_SCOPED}/customers`
const URL_CUSTOMERS_SITE_SCOPED = `${URL_CUSTOMERS}${URL_SITE_SCOPED}`

// Customers -> Search Customers
const URL_CUSTOMERS_SEARCH = `${URL_CUSTOMERS_SITE_SCOPED}/search`
const URL_CUSTOMERS_CUSTOMER_DETAILS_PAGE = `${URL_CUSTOMERS_SEARCH}/:customerId/details`

// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'orders-operational-dashboard'>
  | DashRouteType<'orders-operational-dashboard-tracking-page'>
  | DashRouteType<'orders-operational-dashboard-search-page'>
  | DashRouteType<'orders-operational-dashboard-search-tab'>
  | DashRouteType<'orders-operational-dashboard-delivery-tab'>
  | DashRouteType<'orders-operational-dashboard-pickup-tab'>
  | DashRouteType<'orders-operational-dashboard-lmd-tab'>
  | DashRouteType<'orders-operational-dashboard-details-tab'>
  | DashRouteType<'orders-operational-dashboard-pickup-details-page'>
  | DashRouteType<'orders-operational-dashboard-dashboard-tab'>
  | DashRouteType<'orders-operational-dashboard-guest-details-page'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'orders-operational-dashboard': URL_ORDERS_OPERATIONAL_DASHBOARD,
  'orders-operational-dashboard-tracking-page': URL_ORDERS_OPERATIONAL_DASHBOARD_TRACKING_PAGE,
  'orders-operational-dashboard-search-page': URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE,
  'orders-operational-dashboard-search-tab':
    URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_SEARCH_TAB,
  'orders-operational-dashboard-details-tab': URL_ORDERS_OPERATIONAL_DASHBOARD_DETAILS_TAB,
  'orders-operational-dashboard-delivery-tab':
    URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_DELIVERY_TAB,
  'orders-operational-dashboard-pickup-tab':
    URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_PICKUP_TAB,
  'orders-operational-dashboard-lmd-tab': URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_LMD_TAB,
  'orders-operational-dashboard-pickup-details-page':
    URL_ORDERS_OPERATIONAL_DASHBOARD_PICKUP_DETAILS_PAGE,
  'orders-operational-dashboard-dashboard-tab': URL_ORDERS_OPERATIONAL_DASHBOARD_DASHBOARD_TAB,
  'orders-operational-dashboard-guest-details-page': URL_CUSTOMERS_CUSTOMER_DETAILS_PAGE,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
// @hygen:inject scoped-urls
