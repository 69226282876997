import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ADMIN_DATA_INGESTION = `${URL_APP_ADMIN}/data-ingestion`
const URL_ADMIN_DATA_INGESTION_APPROVAL_REQUEST_SHOW = `${URL_ADMIN_DATA_INGESTION}/approval-request/:id`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-data-ingestion'>
  | DashRouteType<'app-admin-data-ingestion-approval-request/show'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-data-ingestion': URL_ADMIN_DATA_INGESTION,
  'app-admin-data-ingestion-approval-request/show': URL_ADMIN_DATA_INGESTION_APPROVAL_REQUEST_SHOW,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
