const EN_US = {
  /**
   * Config page
   */

  // `AuthWidgetSection`
  'widgetsDomain.config.authWidget': 'Auth Widget',
  'widgetsDomain.config.authWidget.avatarIcon': 'Avatar icon',
  'widgetsDomain.config.authWidget.label': 'Label',
  'widgetsDomain.config.authWidget.showAvatarIcon': 'Show avatar icon',
  'widgetsDomain.config.authWidget.showUnderlineOnHover': 'Underline on hover',
  'widgetsDomain.config.authWidget.styles.button': 'Button',
  'widgetsDomain.config.authWidget.styles.icon': 'Avatar icon',

  // `BorderStyleField`
  'widgetsDomain.config.borderStyleField.dashed': 'Dashed',
  'widgetsDomain.config.borderStyleField.dotted': 'Dotted',
  'widgetsDomain.config.borderStyleField.double': 'Double',
  'widgetsDomain.config.borderStyleField.groove': 'Groove',
  'widgetsDomain.config.borderStyleField.inset': 'Inset',
  'widgetsDomain.config.borderStyleField.outset': 'Outset',
  'widgetsDomain.config.borderStyleField.ridge': 'Ridge',
  'widgetsDomain.config.borderStyleField.solid': 'Solid',

  // `BoxField`
  'widgetsDomain.config.boxField.bottom': 'Bottom',
  'widgetsDomain.config.boxField.format': 'Format',
  'widgetsDomain.config.boxField.left': 'Left',
  'widgetsDomain.config.boxField.right': 'Right',
  'widgetsDomain.config.boxField.top': 'Top',

  // `CartWidgetSection`
  'widgetsDomain.config.cartWidget': 'Cart Widget',
  'widgetsDomain.config.cartWidget.cartDesktopIcon': 'Icon',
  'widgetsDomain.config.cartWidget.cartItemsCount': 'Cart items count',
  'widgetsDomain.config.cartWidget.cartMobileIcon': 'Icon (mobile)',
  'widgetsDomain.config.cartWidget.floatingBadge': 'Display the item count as a badge',
  'widgetsDomain.config.cartWidget.showShopCtaOnEmptyCart': 'Label',
  'widgetsDomain.config.cartWidget.showText': 'Show the cart total',
  'widgetsDomain.config.cartWidget.styles.badge': 'Badge',
  'widgetsDomain.config.cartWidget.styles.button': 'Button',
  'widgetsDomain.config.cartWidget.styles.buttonHoverColor.backgroundColor':
    'Background color (on hover)',
  'widgetsDomain.config.cartWidget.styles.buttonHoverColor.fill': 'Fill (on hover)',
  'widgetsDomain.config.cartWidget.styles.cartIcon': 'Cart icon',
  'widgetsDomain.config.cartWidget.styles.ctaButton': 'Empty cart CTA button',
  'widgetsDomain.config.cartWidget.styles.text': 'Text',

  // `Clear`
  'widgetsDomain.config.clear': 'Clear',

  // `ColorField`
  'widgetsDomain.config.colorField.currentColor': 'Current color',
  'widgetsDomain.config.colorField.custom': 'Custom',
  'widgetsDomain.config.colorField.transparent': 'Transparent',

  // `DietaryTagIconsSection`
  'widgetsDomain.config.itemCard.dietaryTagIcons': 'Dietary Tag Icons',
  'widgetsDomain.config.itemCard.dietaryTagIcons.add': 'Add icon',
  'widgetsDomain.config.itemCard.dietaryTagIcons.color': 'Color',
  'widgetsDomain.config.itemCard.dietaryTagIcons.icon': 'Icon',
  'widgetsDomain.config.itemCard.dietaryTagIcons.id': 'Id',

  // `ExpertMode`
  'widgetsDomain.config.expertMode.close': 'Close',

  // `FieldLabel`
  'widgetsDomain.config.styles.backgroundColor': 'Background color',
  'widgetsDomain.config.styles.borderColor': 'Border color',
  'widgetsDomain.config.styles.borderRadius': 'Border radius',
  'widgetsDomain.config.styles.borderStyle': 'Border style',
  'widgetsDomain.config.styles.borderWidth': 'Border width',
  'widgetsDomain.config.styles.bottom': 'Bottom offset',
  'widgetsDomain.config.styles.boxShadow': 'Shadow',
  'widgetsDomain.config.styles.color': 'Color',
  'widgetsDomain.config.styles.fill': 'Fill',
  'widgetsDomain.config.styles.focus': 'On focus',
  'widgetsDomain.config.styles.fontSize': 'Font size',
  'widgetsDomain.config.styles.fontWeight': 'Font weight',
  'widgetsDomain.config.styles.gap': 'Gap',
  'widgetsDomain.config.styles.height': 'Height',
  'widgetsDomain.config.styles.hover': 'On hover',
  'widgetsDomain.config.styles.justifyContent': 'Justify content',
  'widgetsDomain.config.styles.left': 'Left offset',
  'widgetsDomain.config.styles.lineHeight': 'Line height',
  'widgetsDomain.config.styles.margin': 'Margin',
  'widgetsDomain.config.styles.mobile': 'Mobile',
  'widgetsDomain.config.styles.padding': 'Padding',
  'widgetsDomain.config.styles.right': 'Right offset',
  'widgetsDomain.config.styles.top': 'Top offset',
  'widgetsDomain.config.styles.width': 'Width',

  // `FieldList`
  'widgetsDomain.config.fieldList.add': 'Add',
  'widgetsDomain.config.fieldList.key': 'Key',

  // `FieldListItem`
  'widgetsDomain.config.fieldListItem.delete': 'Delete',
  'widgetsDomain.config.fieldListItem.edit': 'Edit',
  'widgetsDomain.config.fieldListItem.item': 'Item',

  // `FontWeightField`
  'widgetsDomain.config.fontWeightField.100': 'Thin',
  'widgetsDomain.config.fontWeightField.200': 'Extra Light',
  'widgetsDomain.config.fontWeightField.300': 'Light',
  'widgetsDomain.config.fontWeightField.400': 'Normal',
  'widgetsDomain.config.fontWeightField.500': 'Medium',
  'widgetsDomain.config.fontWeightField.600': 'Semi Bold',
  'widgetsDomain.config.fontWeightField.700': 'Bold',
  'widgetsDomain.config.fontWeightField.800': 'Extra Bold',
  'widgetsDomain.config.fontWeightField.900': 'Black',
  'widgetsDomain.config.fontWeightField.bold': 'Bold',
  'widgetsDomain.config.fontWeightField.bolder': 'Bolder',
  'widgetsDomain.config.fontWeightField.lighter': 'Lighter',

  // `FulfillmentSelectorSection`
  'widgetsDomain.config.fulfillmentSelector': 'Fulfillment Selector',
  'widgetsDomain.config.fulfillmentSelector.displayOnly': 'Hide the fulfillment dropdown',
  'widgetsDomain.config.fulfillmentSelector.showIcon': 'Show icon',
  'widgetsDomain.config.fulfillmentSelector.showOnlyStreetAddress': 'Show only street address',
  'widgetsDomain.config.fulfillmentSelector.styles.icon': 'Icon',
  'widgetsDomain.config.fulfillmentSelector.styles.subtitle': 'Subtitle',
  'widgetsDomain.config.fulfillmentSelector.styles.title': 'Title',

  // `ImageField`
  'widgetsDomain.config.imageField.chooseIcon': 'Choose Icon',
  'widgetsDomain.config.imageField.filter': 'Filter...',
  'widgetsDomain.config.imageField.preview': 'Preview',

  // `Index`
  'widgetsDomain.config.index.all': 'All widgets (for internal use)',
  'widgetsDomain.config.index.name': 'Name',

  // `ItemCardSection`
  'widgetsDomain.config.itemCard': 'Item Card',
  'widgetsDomain.config.itemCard.alwaysCollapseAddToCart': 'Show only the add icon',
  'widgetsDomain.config.itemCard.basePriceStyle': 'Base price style',
  'widgetsDomain.config.itemCard.basePriceStyle.lineThrough': 'Strikethrough base price',
  'widgetsDomain.config.itemCard.basePriceStyle.prefixReg': 'Prefix base price with "reg."',
  'widgetsDomain.config.itemCard.openProductDetailModalOnItemClick':
    'Open product details page when clicked',
  'widgetsDomain.config.itemCard.showSnapEligibility': 'Show the SNAP eligibility label',
  'widgetsDomain.config.itemCard.useLightSalePriceBoxColor':
    'Use a light font color for the sale price and badge labels',

  // `MiscellaneousSection`
  'widgetsDomain.config.miscellaneous': 'Miscellaneous',
  'widgetsDomain.config.mobileBreakpoint': 'Mobile breakpoint',
  'widgetsDomain.config.mobileBreakpoint.description':
    'The width of the page in pixels when the site adjusts to a mobile layout.',

  // `Page`
  'widgetsDomain.config.page.exitExpertMode': 'Exit Expert Mode',
  'widgetsDomain.config.page.expertMode': 'Expert Mode',
  'widgetsDomain.config.page.getError': 'Failed to fetch data.',
  'widgetsDomain.config.page.home': 'Home',
  'widgetsDomain.config.page.update': 'Update',
  'widgetsDomain.config.page.updateDelayNotice':
    'The changes on the website will be reflected in a few minutes.',
  'widgetsDomain.config.page.upsertError': 'Update failed',
  'widgetsDomain.config.page.upsertSuccess': 'Updated successfully',
  'widgetsDomain.config.page.validate': 'Validate',
  'widgetsDomain.config.page.validateError': 'Validation failed',
  'widgetsDomain.config.page.validateSuccess': 'Validation passed successfully',

  // `Previews`
  'widgetsDomain.config.previews.desktop': 'Desktop layout',
  'widgetsDomain.config.previews.mobile': 'Mobile layout',
  'widgetsDomain.config.previews.reload': 'Force reload preview',

  // `ProductCollectionsSection`
  'widgetsDomain.config.productCollections': 'Product Collections',
  'widgetsDomain.config.productCollections.styles.chevronIcon': 'Chevron icon',

  // `ProfileMenuOptionsSection`
  'widgetsDomain.config.profileMenu.profileOptions': 'Profile Menu Items',
  'widgetsDomain.config.profileMenu.profileOptions.add': 'Add item',
  'widgetsDomain.config.profileMenu.profileOptions.addDefaultItems': 'Add default items',
  'widgetsDomain.config.profileMenu.profileOptions.default.accountSettings': 'Account settings',
  'widgetsDomain.config.profileMenu.profileOptions.default.addresses': 'Addresses',
  'widgetsDomain.config.profileMenu.profileOptions.default.creditsPromosGiftCards':
    'Credits, promos and gift cards',
  'widgetsDomain.config.profileMenu.profileOptions.default.logOut': 'Log out',
  'widgetsDomain.config.profileMenu.profileOptions.default.notificationsSettings':
    'Notifications settings',
  'widgetsDomain.config.profileMenu.profileOptions.default.paymentMethods': 'Payment methods',
  'widgetsDomain.config.profileMenu.profileOptions.default.yourOrders': 'Your orders',
  'widgetsDomain.config.profileMenu.profileOptions.icon': 'Icon',
  'widgetsDomain.config.profileMenu.profileOptions.label': 'Label',
  'widgetsDomain.config.profileMenu.profileOptions.link': 'Link',
  'widgetsDomain.config.profileMenu.profileOptions.type': 'Type',
  'widgetsDomain.config.profileMenu.profileOptions.type.external': 'External',
  'widgetsDomain.config.profileMenu.profileOptions.type.logout': 'Logout',
  'widgetsDomain.config.profileMenu.profileOptions.type.separator': 'Separator',

  // `ProfileMenuSection`
  'widgetsDomain.config.profileMenu': 'Profile Menu',
  'widgetsDomain.config.profileMenu.clickUrlOnMobile':
    'Hide the profile menu and direct customers to the URL (mobile)',
  'widgetsDomain.config.profileMenu.displayVariant': 'Display order',
  'widgetsDomain.config.profileMenu.displayVariant.iconFirst': 'Show the avatar icon first',
  'widgetsDomain.config.profileMenu.displayVariant.nameFirst': 'Show the name first',
  'widgetsDomain.config.profileMenu.profileIcon': 'Avatar icon',
  'widgetsDomain.config.profileMenu.showChevronIcon': 'Show chevron icon (mobile)',
  'widgetsDomain.config.profileMenu.showProfileIcon': 'Show icons',
  'widgetsDomain.config.profileMenu.showUnderlineOnHover': 'Underline on hover',
  'widgetsDomain.config.profileMenu.showUserName': 'Show name',
  'widgetsDomain.config.profileMenu.showFirstNameOnly': 'Show only the first name',
  'widgetsDomain.config.profileMenu.styles.avatar': 'Avatar',
  'widgetsDomain.config.profileMenu.styles.chevronIcon': 'Chevron icon',
  'widgetsDomain.config.profileMenu.styles.container': 'Container',
  'widgetsDomain.config.profileMenu.styles.name': 'Name',
  'widgetsDomain.config.profileMenu.styles.sheet': 'Menu',
  'widgetsDomain.config.profileMenu.styles.sheetItem': 'Menu item',
  'widgetsDomain.config.profileMenu.styles.sheetItemIcon': 'Menu item icon',
  'widgetsDomain.config.profileMenu.styles.sheetItemLabel': 'Menu item label',

  // `RatingsSection`
  'widgetsDomain.config.ratings': 'Ratings',
  'widgetsDomain.config.ratings.colors': 'Colors',
  'widgetsDomain.config.ratings.enableRatingsAndReviews': 'Enable ratings and reviews',
  'widgetsDomain.config.ratings.styles.filled.fill': 'Filled color',
  'widgetsDomain.config.ratings.styles.icon': 'Icon',
  'widgetsDomain.config.ratings.styles.unfilled.fill': 'Unfilled color',

  // `SearchBarSection`
  'widgetsDomain.config.searchBar': 'Search Bar',
  'widgetsDomain.config.searchBar.enableDropdownOnMobile': 'Enable dropdown (mobile)',
  'widgetsDomain.config.searchBar.placeholder': 'Placeholder',
  'widgetsDomain.config.searchBar.searchIconPosition': 'Search icon position',
  'widgetsDomain.config.searchBar.searchIconPosition.left': 'Left',
  'widgetsDomain.config.searchBar.searchIconPosition.right': 'Right',
  'widgetsDomain.config.searchBar.styles.dropdownPanel': 'Dropdown',
  'widgetsDomain.config.searchBar.styles.form': 'Input container',
  'widgetsDomain.config.searchBar.styles.inputField': 'Input field',
  'widgetsDomain.config.searchBar.styles.overlay': 'Overlay (mobile)',
  'widgetsDomain.config.searchBar.styles.text': 'Text',

  // `ShadowField`
  'widgetsDomain.config.shadowField.blurRadius': 'Blur radius',
  'widgetsDomain.config.shadowField.format': 'Format',
  'widgetsDomain.config.shadowField.offsetX': 'Horizontal offset',
  'widgetsDomain.config.shadowField.offsetY': 'Vertical offset',
  'widgetsDomain.config.shadowField.transparency': 'Transparency (%)',

  // `SideNavSection`
  'widgetsDomain.config.sideNav': 'Side Navigation',
  'widgetsDomain.config.sideNav.sections.add': 'Add section',
  'widgetsDomain.config.sideNav.sections.addSubsection': 'Add subsection',
  'widgetsDomain.config.sideNav.sections.dropdown': 'Dropdown',
  'widgetsDomain.config.sideNav.sections.dropdown.add': 'Add item',
  'widgetsDomain.config.sideNav.sections.dropdown.link': 'Item link',
  'widgetsDomain.config.sideNav.sections.dropdown.name': 'Item name',
  'widgetsDomain.config.sideNav.sections.icon': 'Icon',
  'widgetsDomain.config.sideNav.sections.link': 'Link',
  'widgetsDomain.config.sideNav.sections.name': 'Name',
  'widgetsDomain.config.sideNav.sections.section': 'Section',

  // `SizeField`
  'widgetsDomain.config.sizeField.auto': 'Auto',

  // `URLField`
  'widgetsDomain.config.urlField.invalid': 'Invalid URL',

  /**
   * Title
   */

  'widgetsDomain.title': 'Widgets',
} as const

export default EN_US
