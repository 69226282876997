import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const storeInterventionsAdminAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsView],
}

export const storeInterventionsMilestone3AccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsView],
  featureToggles: [FeatureToggle.StoreInterventionsMilestone3],
}
