const EN_US = {
  'siteManagementDomain.title': 'Site Management',
  'siteManagementDomain.common.accessability.close': 'Close',
  'siteManagementDomain.common.accessability.toggleAccordion': 'Toggle accordion',
  'siteManagementDomain.common.accessability.seeMore': 'See more dropdown',
  'siteManagementDomain.common.components.colorPicker.accessability': 'color select',
  'siteManagementDomain.common.components.colorPicker.placeholder': 'Search for a color',
  'siteManagementDomain.navigation.page.title': 'Navigation',
  'siteManagementDomain.navigationStyling.page.title': 'Header',
  'siteManagementDomain.navigationStyling.page.subtitle': 'Styling',
  'siteManagementDomain.navigation.common.configurableGroupHeader.placeholder':
    'Add a title (optional)',
  'siteManagementDomain.navigation.sections.headerColor.title': 'Header color',
  'siteManagementDomain.navigation.sections.headerColor.helpText':
    'This will be your header background color.',
  'siteManagementDomain.navigation.sections.headerColor.education.title': 'Mobile web header',
  'siteManagementDomain.navigation.sections.headerColor.education.body':
    'Mobile web header is equipped with logo, cart button,  menu icon, and search bar. You may configure the color of your header. {cta}.',
  'siteManagementDomain.navigation.sections.headerColor.education.bodyCTA': 'Learn more',
  'siteManagementDomain.navigation.sections.headerColor.education.image': 'preview',
  'siteManagementDomain.navigation.forms.controls.displayText.label': '{verb} display name',
  'siteManagementDomain.navigation.forms.controls.displayText.placeholder': 'Enter display name',
  'siteManagementDomain.navigation.forms.controls.counter': '{count}/{max}',
  'siteManagementDomain.navigation.forms.controls.internalPage.label':
    'Search for an internal page',
  'siteManagementDomain.navigation.forms.controls.internalPage.scopePlaceholder': 'Select type',
  'siteManagementDomain.navigation.forms.controls.internalPage.omniPlaceholder': 'Search by name',
  'siteManagementDomain.navigation.forms.controls.icon.label': 'Choose an icon',
  'siteManagementDomain.navigation.forms.controls.icon.placeholder': 'Search for an icon',
  'siteManagementDomain.navigation.links.newTabCTA': 'Add new tab',
  'siteManagementDomain.navigation.links.newLinkCTA': 'Add new link',
  'siteManagementDomain.navigation.links.hereCTA': 'here',
  'siteManagementDomain.navigation.links.addSublinkCTA': 'Add sublink',
  'siteManagementDomain.navigation.links.addNestedMenuCTA': 'Add nested menu',
  'siteManagementDomain.navigation.links.editStyling': 'Edit Styling',
  'siteManagementDomain.navigation.links.edit': 'Edit',
  'siteManagementDomain.navigation.links.delete': 'Delete',
  'siteManagementDomain.navigation.links.modal.forms.verbs.add': 'Add',
  'siteManagementDomain.navigation.links.modal.forms.verbs.edit': 'Edit',
  'siteManagementDomain.navigation.links.modal.forms.tabLink.title': '{verb} new tab link',
  'siteManagementDomain.navigation.links.modal.forms.topLevel.title': '{verb} new link',
  'siteManagementDomain.navigation.links.modal.forms.nestedMenu.title': '{verb} new nested menu',
  'siteManagementDomain.navigation.links.modal.forms.sublink.title': '{verb} new sublink',
  'siteManagementDomain.navigation.links.modal.submitCTA': 'Done',
  'siteManagementDomain.navigation.links.modal.cancelCTA': 'Cancel',
  'siteManagementDomain.navigation.links.attributes.separator': ' - ',
  'siteManagementDomain.navigation.links.attributes.container': 'Container link',
  'siteManagementDomain.navigation.links.attributes.group':
    '{number} {number, plural, =1 {sublink} other {sublinks}}',
  'siteManagementDomain.navigation.links.attributes.standard.collection': 'Collection',
  'siteManagementDomain.navigation.layout.desktop.tabLabel': 'Desktop',
  'siteManagementDomain.navigation.layout.desktop.title': 'Desktop menus',
  'siteManagementDomain.navigation.layout.desktop.description':
    'Manage the navigation for your desktop web experience. Learn more {cta}.',
  'siteManagementDomain.navigation.layout.mweb.tabLabel': 'Mobile Web',
  'siteManagementDomain.navigation.layout.mweb.title': 'Mobile web menus',
  'siteManagementDomain.navigation.layout.mweb.description':
    'Manage the navigation for your mobile web experience. Learn more {cta}.',
  'siteManagementDomain.navigation.layout.native.tabLabel': 'Native app',
  'siteManagementDomain.navigation.layout.native.title': 'Native app menus',
  'siteManagementDomain.navigation.layout.native.description':
    'Manage the navigation for your native app experience. Learn more {cta}.',
  'siteManagementDomain.navigation.layout.native.header.title': 'Native app header',
  'siteManagementDomain.navigation.layout.native.header.description':
    'Configure your mobile web styling',
  'siteManagementDomain.navigation.layout.native.tabBar.title': 'Native app tab bar',
  'siteManagementDomain.navigation.layout.native.tabBar.description':
    'The native app tab bar is the primary navigation for your app. It should not include any external links. The first and last tabs are mandatory. You may choose up to three configurable tabs.',
  'siteManagementDomain.navigation.layout.native.moreMenu.title': 'More menu',
  'siteManagementDomain.navigation.layout.native.moreMenu.description':
    'The “more menu” is an additional section in your account page where you can add secondary links.',
} as const

export default EN_US
