import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

const URL_SCAN_AND_PAY = `${URL_RETAILER_SCOPED}/scan-and-pay`
// @hygen:inject scoped-urls

const URL_ADMIN_SCAN_AND_PAY = `${URL_APP_ADMIN}/scan-and-pay`
// @hygen:inject admin-urls

const URL_PUBLIC_SCAN_AND_PAY = `${URL_PUBLIC}/scan-and-pay`
// @hygen:inject public-urls

export type DashRouteSet =
  | DashRouteType<'scan-and-pay'>
  | DashRouteType<'app-admin-scan-and-pay'>
  | DashRouteType<'app-public-scan-and-pay'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'scan-and-pay': URL_SCAN_AND_PAY,
  'app-admin-scan-and-pay': URL_ADMIN_SCAN_AND_PAY,
  'app-public-scan-and-pay': URL_PUBLIC_SCAN_AND_PAY,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
