import { SFXVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const customersAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.CustomersView],
}

export const customersPrivacyRequestsAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.CustomersPrivacyRequestsModifyAccess],
}

export const guestManagementEnhancements: DomainAccessControlConfig = [
  {
    warehouseFeatureToggles: [FeatureToggle.GuestManagementEnhancements],
  },
  {
    minSfxVersion: SFXVersion.r2024_06_0,
  },
]
