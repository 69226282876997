import gql from 'graphql-tag'
import { useDashMutation } from '../../../apollo/clientHooks'
import { storeGroupListDefaultParams } from '../../../common/graphql/store-groups/list.hooks'
import { useRetailerId } from '../../../../utils/routing/params.hooks'
import { type ListStoreGroupsQuery, ListStoreGroupsDocument } from '../../../../__codegen__/api'

export const DELETE_STORE_GROUP_MUTATION = gql`
  mutation deleteStoreGroup($id: ID!) {
    retailerLocationGroupDelete(id: $id) {
      success
    }
  }
`

export interface DeleteStoreGroupMutationData {
  retailerLocationGroupDelete: {
    success: true
    __typename?: string
  }
}

export interface DeleteStoreGroupsMutationVariables {
  id: string
}

interface DeleteProps {
  storeGroupId: string
}

export const useDeleteStoreGroup = () => {
  const retailerId = useRetailerId()
  const [triggerDeleteStoreGroup, { data, error, loading }] = useDashMutation<
    DeleteStoreGroupMutationData,
    DeleteStoreGroupsMutationVariables
  >(DELETE_STORE_GROUP_MUTATION)

  const success = data && data.retailerLocationGroupDelete.success

  const deleteStoreGroup = ({ storeGroupId }: DeleteProps) =>
    triggerDeleteStoreGroup({
      variables: {
        id: storeGroupId,
      },
      optimisticResponse: {
        retailerLocationGroupDelete: {
          __typename: 'RetailerLocationGroupDeletePayload',
          success: true,
        },
      },
      update: (store, { data: updateData }) => {
        if (!updateData) return
        const {
          retailerLocationGroupDelete: { success: successResponse },
        } = updateData
        if (!successResponse) return

        const query = {
          query: ListStoreGroupsDocument,
          variables: {
            ...storeGroupListDefaultParams,
            retailerId,
          },
        }
        const currentData: ListStoreGroupsQuery | null = store.readQuery({ ...query })
        if (!currentData) return
        const filtered = currentData.retailerLocationGroupsByRetailer.edges.filter(
          edge => edge.node.id !== storeGroupId
        )
        store.writeQuery({
          ...query,
          data: {
            retailerLocationGroupsByRetailer: {
              edges: filtered,
              __typename: 'RetailersRetailerLocationGroupConnection',
            },
          },
        })
      },
    })

  return {
    deleteStoreGroup,
    success,
    loading,
    error,
  }
}
