import React, { useMemo } from 'react'
import {
  Breadcrumb,
  Breadcrumbs,
  colors,
  getTargetedPortal,
  spacing,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { useStoreConfigurations } from '../../../api/store-configurations/useStoreConfigurations.hooks'
import { StoreConfigurationRelationshipEnum } from '../../../__codegen__/api'
import RetailerAndSitePicker from '../retailer-and-site-picker/RetailerAndSitePicker'
import { useRetailerId } from '../../../utils/routing/params.hooks'
import { CircleLogo } from '../../common/select-partner/CircleLogo'
import { type SiteOperationIntent } from '../../common/site-select/SiteSelect'
import { type FilterSitesFn } from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { useWarehouseContext } from '../../../utils/contexts/warehouse/WarehouseContext.hooks'
import { useDashMessage } from '../../../intl/intl.hooks'
import { type StoreConfigurationScopedRouteParams } from '../../../legacy/common/types'
import { MARKETPLACE_SITE_ID } from '../retailer-and-site-picker/CommonSiteFilters'
import { usePartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import { StoreConfigScopeWrapper } from './StoreConfigScopeWrapper'
import { HeaderContext } from './HeaderContext'
import { BannerSelectRequired } from './BannerSelectRequired'

export interface RetailerScopeWrapperProps {
  children?: React.ReactNode
  selectionType?: ViewType
  retailerSelectionOptional?: boolean
  storeConfigSelectionOptional?: boolean
  storeConfigOperationIntent?: SiteOperationIntent // 'view' or 'edit'
  storeConfigFilter?: FilterSitesFn // store config filters
  titles?: string[]
  formattedMessageTitle?: string
  retailerInsufficientPermission?: boolean
  hiddenScopePicker?: boolean
}

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: `${spacing.X16} ${spacing.X16}`,
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})

const Toolbar = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
})

export enum ViewType {
  RETAILER,
  RETAILER_AND_STORE_CONFIG,
}

export const [HeaderTitlePortalTarget, HeaderTitlePortal] = getTargetedPortal()
export const [HeaderToolsPortalTarget, HeaderToolsPortal] = getTargetedPortal()
export const [HeaderExtraContentPortalTarget, HeaderExtraContentPortal] = getTargetedPortal()

export const RetailerScopeWrapper = ({
  children,
  retailerSelectionOptional,
  storeConfigSelectionOptional,
  selectionType,
  storeConfigOperationIntent,
  storeConfigFilter,
  titles,
  formattedMessageTitle,
  retailerInsufficientPermission,
  hiddenScopePicker,
}: RetailerScopeWrapperProps) => {
  const { warehouses, partnerName } = usePartnerContext()
  const retailerId = Number(useRetailerId())
  const { warehouse } = useWarehouseContext()
  const params = useParams<StoreConfigurationScopedRouteParams>()
  const storeConfigId = Number(params.storeConfigId)

  selectionType = selectionType || ViewType.RETAILER

  // if scope picker is optional, then override retailerSelectionOptional, storeConfigSelectionOptional
  retailerSelectionOptional ||= hiddenScopePicker
  storeConfigSelectionOptional ||= hiddenScopePicker

  const intl = useIntl()
  const headerComponent = useMemo(() => {
    if (titles) {
      const breadcrumbs = titles?.map(title => (
        <Breadcrumb key={title}>{intl.formatMessage({ id: title }) || title}</Breadcrumb>
      ))
      return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
    } else if (formattedMessageTitle) {
      return (
        <Breadcrumbs>
          <Breadcrumb>
            <FormattedMessage id={formattedMessageTitle} />
          </Breadcrumb>
        </Breadcrumbs>
      )
    }
  }, [formattedMessageTitle, intl, titles])

  const storeConfigurations = useStoreConfigurations({
    retailerIds: warehouses?.map(e => e.id.toString()),
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
  })

  const options = useMemo(() => {
    const storeConfigs = storeConfigurations?.data?.storeConfigurationsByRetailerIds
    if (!storeConfigs || !warehouses) {
      return []
    }

    warehouses.forEach(e => {
      e.storeConfigurations = storeConfigurations.data.storeConfigurationsByRetailerIds.filter(
        storeConfig =>
          storeConfig.whitelistedWarehouseIds.includes(e.id.toString()) ||
          storeConfig.id === MARKETPLACE_SITE_ID
      )
    })

    return warehouses?.map(e => ({
      context: e,
      label: e.name,
      value: String(e.id),
      storeConfigurations: e.storeConfigurations,
      logo: e.logo,
    }))
  }, [storeConfigurations?.data?.storeConfigurationsByRetailerIds, warehouses])

  children = retailerSelectionOptional ? children : retailerId > 0 && children
  if (selectionType == ViewType.RETAILER_AND_STORE_CONFIG) {
    children = <StoreConfigScopeWrapper options={options}>{children}</StoreConfigScopeWrapper>
  }

  const toolContentVisible =
    (selectionType == ViewType.RETAILER && (retailerSelectionOptional || warehouse)) ||
    (selectionType == ViewType.RETAILER_AND_STORE_CONFIG &&
      (storeConfigSelectionOptional || storeConfigId > 0))

  return (
    <>
      <Header>
        {toolContentVisible ? <HeaderTitlePortalTarget /> : headerComponent}
        {!hiddenScopePicker && (
          <>
            <Toolbar>
              <RetailerAndSitePicker
                options={options}
                selectionType={selectionType}
                retailerSelectionOptional={retailerSelectionOptional}
                storeConfigOperationIntent={storeConfigOperationIntent}
                storeConfigFilter={storeConfigFilter}
                retailerInsufficientPermission={retailerInsufficientPermission}
              />
              <HeaderToolsPortalTarget />
            </Toolbar>
            <CurrentScopeInfoBar
              retailerId={retailerId}
              partnerName={partnerName}
              retailerSelectionOptional={retailerSelectionOptional}
            />
          </>
        )}
        {toolContentVisible && <HeaderExtraContentPortalTarget />}
      </Header>
      {toolContentVisible ? children : <BannerSelectRequired selectionType={selectionType} />}
    </>
  )
}

const BlueInfoBar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 32px 12px 12px',
  gap: '12px',
  background: '#F2F8FF',
})

const CurrentScopeInfoBar = ({ retailerId, partnerName, retailerSelectionOptional }) => {
  const infoMessage = useDashMessage('retailerPicker.noBannerSelectedCurrentScopeInfo', {
    partnerName,
  })

  if (retailerId == 0 && retailerSelectionOptional) {
    return (
      <BlueInfoBar>
        <CircleLogo size={19} color={'#2B78C6'}>
          i
        </CircleLogo>
        {infoMessage}
      </BlueInfoBar>
    )
  }
  return null
}
