import React, { type FunctionComponent } from 'react'
import { Center } from '@retailer-platform/shared-components'
import { Text } from '@instacart/ids-core'
import styled from '@emotion/styled'
import { Img } from 'react-image'
import { spacing } from '../../../../gin-and-tonic/foundation/spacing'
import { useDashMessages } from '../../../../intl/intl.hooks'
import CARROT_LOGO from '../../../../assets/half-carrot.svg'

const Heading = styled.div({
  fontWeight: 600,
  fontSize: '1.5rem',
})

const PageContainer = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const PageContent = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing.X16,
})

const PageFooter = styled.div({
  padding: spacing.X8,
})

export const AdminRoot: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const i18n = useDashMessages({
    title: 'app.admin.home.title',
    subtitle: 'app.admin.home.subtitle',
    footer: 'app.admin.controlPanel.footer',
  })

  return (
    <PageContainer>
      <PageContent>
        <Img src={CARROT_LOGO} />
        <Heading>{i18n.title}</Heading>
        <Text typography="bodyLarge2">{i18n.subtitle}</Text>
      </PageContent>
      <PageFooter>
        <Center>
          <Text typography="bodyMedium1">{i18n.footer}</Text>
        </Center>
      </PageFooter>
    </PageContainer>
  )
}
