/* eslint-disable no-console */
import { setContext } from 'apollo-link-context'
import { fetchSessionData } from '../session/sessionToken.utils'

interface ApolloAuthLinkHandlerPrevContext {
  headers: { [key: string]: string }
}

export const apolloAuthLinkHandler = async (
  _: unknown,
  { headers }: ApolloAuthLinkHandlerPrevContext
) =>
  fetchSessionData()
    .then(sessionData => ({
      headers: {
        ...headers,
        authorization: sessionData ? `Bearer ${sessionData.token}` : '',
      },
    }))
    .catch(err => console.warn(err))

export const apolloAuthLink = setContext(apolloAuthLinkHandler)
