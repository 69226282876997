import {
  type DashRouteType,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ONBOARDING = `${URL_RETAILER_SCOPED}/onboarding`
const URL_ONBOARDING_LAUNCH_CHECKLIST = `${URL_ONBOARDING}/launch-checklist`
const URL_ONBOARDING_RETAILER_SETTINGS = `${URL_ONBOARDING}/retailer-settings`
const URL_ONBOARDING_STORE_MANAGEMENT = `${URL_ONBOARDING}/store-management/:mode?`
export const URL_ONBOARDING_INITIAL_SETUP = `${URL_ONBOARDING}/initial-setup`
export const URL_ONBOARDING_STORE_DETAIL = `${URL_ONBOARDING}/store-detail/:warehouseLocationId`
// @hygen:inject scoped-urls

const URL_PUBLIC_ONBOARDING = `${URL_PUBLIC}/onboarding`
const URL_PUBLIC_ONBOARDING_COMPLETE_ACCOUNT_SETUP = `${URL_PUBLIC_ONBOARDING}/complete-account-setup/:token`
export const URL_PUBLIC_ONBOARDING_REQUEST_ACCOUNT_SETUP = `${URL_PUBLIC_ONBOARDING}/request-account-setup`
// @hygen:inject public-urls

export type DashRouteSet =
  | DashRouteType<'onboarding'>
  | DashRouteType<'app-public-onboarding'>
  | DashRouteType<'onboarding-launch-checklist'>
  | DashRouteType<'onboarding-retailer-settings'>
  | DashRouteType<'onboarding-store-management', { mode: string }>
  | DashRouteType<'onboarding-initial-setup'>
  | DashRouteType<'app-public-onboarding-complete-account-setup', { token: string }>
  | DashRouteType<'onboarding-store-detail', { warehouseLocationId: string }>
  | DashRouteType<'app-public-onboarding-request-account-setup'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  onboarding: URL_ONBOARDING,
  'app-public-onboarding': URL_PUBLIC_ONBOARDING,
  'onboarding-launch-checklist': URL_ONBOARDING_LAUNCH_CHECKLIST,
  'onboarding-retailer-settings': URL_ONBOARDING_RETAILER_SETTINGS,
  'onboarding-store-management': URL_ONBOARDING_STORE_MANAGEMENT,
  'onboarding-initial-setup': URL_ONBOARDING_INITIAL_SETUP,
  'app-public-onboarding-complete-account-setup': URL_PUBLIC_ONBOARDING_COMPLETE_ACCOUNT_SETUP,
  'onboarding-store-detail': URL_ONBOARDING_STORE_DETAIL,
  'app-public-onboarding-request-account-setup': URL_PUBLIC_ONBOARDING_REQUEST_ACCOUNT_SETUP,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
