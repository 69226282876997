import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { campaignManagementRouter } from './routing/campaignManagementRouter'
import { campaignManagementAdminRouter } from './routing/campaignManagementAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  campaignManagementAdminAccessControl,
  campaignManagementAdminCalendarPageAccessControl,
  campaignManagementAdminCreativesPageAccessControl,
  campaignManagementAdminMultiRetailerPages,
  campaignManagementAdminRedemptionUrls,
} from './access-control/campaignManagementAdminAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'campaign-management',
  totem: {
    entity: 'campaign-management-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [campaignManagementRouter],
    admin: [campaignManagementAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.CampaignManagement,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-campaign-management-theme-page-list',
          labelId: 'campaignManagement.themePages.listPage.title',
          accessControl: campaignManagementAdminAccessControl,
          positionNavV2: 5,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.CampaignManagement,
      subNavItems: [
        {
          route: 'app-admin-campaign-management-calendar',
          labelId: 'campaignManagement.calendar.title',
          accessControl: campaignManagementAdminCalendarPageAccessControl,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.Merchandising,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-campaign-management-creatives',
          labelId: 'campaignManagement.admin.creativeManager.nav.title',
          accessControl: campaignManagementAdminCreativesPageAccessControl,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.CampaignManagement,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-campaign-management-redemption-urls-list',
          labelId: 'campaignManagement.admin.redemptionUrls.title',
          accessControl: campaignManagementAdminRedemptionUrls,
          positionNavV2: 6,
        },
      ],
    },
    {
      attachTo: AdminNavEntryPoint.LandingPageSystem,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-campaign-management-multi-retailer-pages-list',
          labelId: 'campaignManagement.admin.multiRetailerPages.title',
          accessControl: campaignManagementAdminMultiRetailerPages,
          positionNavV2: 3,
        },
      ],
    },
  ],
}

export default definition
