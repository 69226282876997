import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { apolloAuthLink } from './apolloAuthLink'
import { makeApolloErrorLink } from './apolloErrorLink'
import { apolloHttpLink } from './apolloHttpLink'
import { type CustomApolloClient } from './apolloClient.types'

export const makeApolloClient = ({ name }: { name: string }) => {
  // We are lazily assigning this
  // eslint-disable-next-line prefer-const
  let newClient: CustomApolloClient

  const getCurrentClient = () => newClient
  const errorLink = makeApolloErrorLink(getCurrentClient)

  newClient = new ApolloClient({
    name,
    connectToDevTools: true,
    link: apolloAuthLink.concat(errorLink).concat(apolloHttpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  })

  return newClient
}
