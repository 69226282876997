export enum Metrics {
  AVG_ITEM_FOUND = 'AVG_ITEM_FOUND',
  AVG_ITEM_FILLED = 'AVG_ITEM_FILLED',
  LOST_GMV = 'LOST_GMV',
  AVG_LOST_GMV_PER_STORE = 'AVG_LOST_GMV_PER_STORE',
  AVG_ITEM_REPLACED = 'AVG_ITEM_REPLACED',
  AVG_BASKET = 'AVG_BASKET',
  AVG_ORDERED_ITEM = 'AVG_ORDERED_ITEM',
  AVG_ITEM_FILLED_PRICE = 'AVG_ITEM_FILLED_PRICE',
  SUM_GMV = 'SUM_GMV',
  COUNT_ORDER = 'COUNT_ORDER',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WAREHOUSE = 'WAREHOUSE',
  FULFILLMENT_TYPE = 'FULFILLMENT_TYPE',
  WAREHOUSE_LOCATION_CODE = 'WAREHOUSE_LOCATION_CODE',
  PRODUCT_UPC_CODE = 'PRODUCT_UPC_CODE',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  ORDER_SOURCE = 'ORDER_SOURCE',
  PICKUP = 'PICKUP',
  ALCOHOL = 'ALCOHOL',
  EXPRESS = 'EXPRESS',
  LOYALTY = 'LOYALTY',
  EBT_ORDER = 'EBT_ORDER',
  EBT_ITEM = 'EBT_ITEM',
  FIRST_ORDER = 'FIRST_ORDER',
  VIRTUAL_WAREHOUSE = 'VIRTUAL_WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  PARTNER_GEO_DIVISION = 'PARTNER_GEO_DIVISION',
  ORDER_CURRENCY = 'ORDER_CURRENCY',
  REGION = 'REGION',
  L1_CATEGORY = 'L1_CATEGORY',
  L2_CATEGORY = 'L2_CATEGORY',
  L3_CATEGORY = 'L3_CATEGORY',
  L4_CATEGORY = 'L4_CATEGORY',
  L5_CATEGORY = 'L5_CATEGORY',
  L6_CATEGORY = 'L6_CATEGORY',
  PRODUCT_CATEGORY_ID = 'PRODUCT_CATEGORY_ID',
  RETAILER_PRODUCT = 'RETAILER_PRODUCT',
  RX_ORDER = 'RX_ORDER',
  WAREHOUSE_LOCATION_GROUP = 'WAREHOUSE_LOCATION_GROUP',
}

export enum GroupBys {
  DATE_PT = 'DATE_PT',
  WAREHOUSE = 'WAREHOUSE',
  PRODUCT_UPC_CODE = 'PRODUCT_UPC_CODE',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  ORDER_SOURCE = 'ORDER_SOURCE',
  PICKUP = 'PICKUP',
  ALCOHOL = 'ALCOHOL',
  EXPRESS = 'EXPRESS',
  LOYALTY = 'LOYALTY',
  EBT = 'EBT',
  FIRST_ORDER = 'FIRST_ORDER',
  VIRTUAL_WAREHOUSE = 'VIRTUAL_WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  PARTNER_GEO_DIVISION = 'PARTNER_GEO_DIVISION',
  ORDER_CURRENCY = 'ORDER_CURRENCY',
  REGION = 'REGION',
  L1_CATEGORY = 'L1_CATEGORY',
  L2_CATEGORY = 'L2_CATEGORY',
  L3_CATEGORY = 'L3_CATEGORY',
  L4_CATEGORY = 'L4_CATEGORY',
  L5_CATEGORY = 'L5_CATEGORY',
  L6_CATEGORY = 'L6_CATEGORY',
  PRODUCT_CATEGORY_ID = 'PRODUCT_CATEGORY_ID',
  RETAILER_PRODUCT = 'RETAILER_PRODUCT',
  RX_ORDER = 'RX_ORDER',
}
