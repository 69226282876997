import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { catalogAdminAdminAccessControl } from '../access-control/catalogAdminAdminAccess.configuration'
import { catalogAdminImageManagementAccessConfiguration } from '../access-control/catalogAdminImageManagementAccess.configuration'
import { catalogAdminQualityAuditAccessConfiguration } from '../access-control/catalogAdminQualityAuditAccess.configuration'
import {
  catalogAdminMDPlatformAccessConfiguration,
  catalogAdminMDPlatformEditAccessConfiguration,
} from '../access-control/catalogMDPlatformAccess.configuration'

// --------------------
// QAS Page Components
// --------------------

const CatalogAdminQualityAuditWorkflowListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowListPage" */
      '../pages/quality-audit-workflow-list/CatalogAdminQualityAuditWorkflowListPage'
    )
)

const CatalogAdminQualityAuditWorkflowBatchListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowBatchListPage" */
      '../pages/quality-audit-workflow-batch-list/CatalogAdminQualityAuditWorkflowBatchListPage'
    )
)

const CatalogAdminQualityAuditWorkflowTaskListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowTaskListPage" */
      '../pages/quality-audit-workflow-task-list/CatalogAdminQualityAuditWorkflowTaskListPage'
    )
)

const CatalogAdminQualityAuditWorkflowTaskListInputDataPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminQualityAuditWorkflowTaskListInputDataPage" */
      '../pages/quality-audit-workflow-task-list-input-data/CatalogAdminQualityAuditWorkflowTaskListInputDataPage'
    )
)

// --------------------
// Image Management Page Components
// --------------------

const CatalogAdminImageManagementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminImageManagementPage" */
      '../pages/image-management/CatalogAdminImageManagementPage'
    )
)

// --------------------
// Product Edit Page Components
// --------------------

const CatalogAdminProductEditShowPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminProductEditShowPage" */
      '../pages/product-edit-show/CatalogAdminProductEditShowPage'
    )
)
const CatalogAdminMdPlatformPage = lazy(
  () => import('../pages/md-platform/CatalogAdminMdPlatformPage')
)
const CatalogAdminMdPlatformDetailsPage = lazy(
  () => import('../pages/md-platform-details/CatalogAdminMdPlatformDetailsPage')
)
const CatalogAdminMdPlatformCreatePage = lazy(
  () => import('../pages/md-platform-create/CatalogAdminMdPlatformCreatePage')
)
const CatalogAdminQualityAuditSubmitTasksPage = lazy(
  () => import('../pages/quality-audit-submit-tasks/CatalogAdminQualityAuditSubmitTasksPage')
)
// @hygen:inject page-import

export const catalogAdminAdminRouter = (
  <DomainRoute route="app-admin-catalog-admin" accessControl={catalogAdminAdminAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-catalog-admin-product-edit-show"
        component={CatalogAdminProductEditShowPage}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-image-management"
        component={CatalogAdminImageManagementPage}
        accessControl={catalogAdminImageManagementAccessConfiguration}
      />
      {/* Quality Audit Routes */}
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-list"
        component={CatalogAdminQualityAuditWorkflowListPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-batch-list"
        component={CatalogAdminQualityAuditWorkflowBatchListPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-task-list"
        component={CatalogAdminQualityAuditWorkflowTaskListPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-workflow-task-list-input-data"
        component={CatalogAdminQualityAuditWorkflowTaskListInputDataPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-md-platform"
        component={CatalogAdminMdPlatformPage}
        accessControl={catalogAdminMDPlatformAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-md-platform-details"
        component={CatalogAdminMdPlatformDetailsPage}
        accessControl={catalogAdminMDPlatformAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-md-platform-create"
        component={CatalogAdminMdPlatformCreatePage}
        accessControl={catalogAdminMDPlatformEditAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-quality-audit-submit-tasks"
        component={CatalogAdminQualityAuditSubmitTasksPage}
        accessControl={catalogAdminQualityAuditAccessConfiguration}
      />
    </DashSwitch>
  </DomainRoute>
)
