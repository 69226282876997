/**
 * @description
 * Extension code enum, needs to align with values from API
 */
export enum ExtensionsCode {
  // Our custom codes
  StandardError = 'standardError',
  NotFound = 'notFound',
  CreateError = 'createError',
  UpdateError = 'updateError',

  // Codes from graphlql-ruby
  MissingRequiredArguments = 'missingRequiredArguments',
  ArgumentLiteralsIncompatible = 'argumentLiteralsIncompatible',
  UndefinedField = 'undefinedField',
  ArgumentNotUnique = 'argumentNotUnique',
}

type ExtensionsBase<TCode extends ExtensionsCode, TData extends object = {}> = {
  code: TCode
} & { [Key in keyof TData]: TData[Key] }

// our custom extensions
export type StandardErrorExtensions = ExtensionsBase<ExtensionsCode.StandardError>
export type NotFoundExtensions = ExtensionsBase<ExtensionsCode.NotFound>
export type CreateErrorExtensions = ExtensionsBase<ExtensionsCode.CreateError>
export type UpdateErrorExtensions = ExtensionsBase<ExtensionsCode.UpdateError>

// graphql-ruby extensions
export type MissingRequiredArgumentsExtensions = ExtensionsBase<
  ExtensionsCode.MissingRequiredArguments,
  {
    className: string
    name: string
    arguments: string
  }
>
export type ArgumentLiteralsIncompatibleExtensions = ExtensionsBase<
  ExtensionsCode.ArgumentLiteralsIncompatible,
  {
    typeName: string
    argumentName: string
  }
>
export type UndefinedFieldExtensions = ExtensionsBase<
  ExtensionsCode.UndefinedField,
  {
    typeName: string
    fieldName: string
  }
>
export type ArgumentNotUniqueExtensions = ExtensionsBase<
  ExtensionsCode.ArgumentNotUnique,
  {
    name: string
  }
>

export type Extensions =
  | StandardErrorExtensions
  | NotFoundExtensions
  | CreateErrorExtensions
  | UpdateErrorExtensions
  | MissingRequiredArgumentsExtensions
  | ArgumentLiteralsIncompatibleExtensions
  | UndefinedFieldExtensions
  | ArgumentNotUniqueExtensions
