import React, { type FunctionComponent } from 'react'
import { useRetailerId, useStoreConfigId } from '../../../utils/routing/params.hooks'
import { SelectedStoreConfigurationProvider } from '../../common/selected-site-provider/SelectedSiteProvider'
import { type WarehousesForSelection } from '../retailer-and-site-picker/RetailerAndSitePicker.types'

interface Props {
  options: WarehousesForSelection[]
}

export const StoreConfigScopeWrapper: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  options,
}) => {
  const retailerId = useRetailerId()
  const storeConfigId = useStoreConfigId({ throwOnMissing: false })

  const retailer = options?.find(retailer => retailer.value == retailerId)
  const selectedSc = retailer?.storeConfigurations?.find(sc => sc.id === storeConfigId)

  return (
    <>
      {selectedSc && (
        <SelectedStoreConfigurationProvider selectedStoreConfiguration={selectedSc}>
          {children}
        </SelectedStoreConfigurationProvider>
      )}
    </>
  )
}
