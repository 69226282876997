import gql from 'graphql-tag'
import { useDashMutation } from '../../../apollo/clientHooks'
import { useRetailerId } from '../../../../utils/routing/params.hooks'
import { storeGroupListDefaultParams } from '../../../common/graphql/store-groups/list.hooks'
import { type StoreGroup } from '../../../common/graphql-types/retailerLocationGroup.types'
import { ListStoreGroupsDocument } from '../../../../__codegen__/api'

export const CREATE_STORE_GROUP_MUTATION = gql`
  mutation createStoreGroup($name: String!, $retailerId: ID!, $retailerLocationIds: [ID!]) {
    retailerLocationGroupCreate(
      name: $name
      retailerId: $retailerId
      retailerLocationIds: $retailerLocationIds
    ) {
      retailerLocationGroup {
        id
        name
        updatedAt
        retailerLocationIds
      }
    }
  }
`

export interface CreateStoreGroupMutationData {
  retailerLocationGroupCreate: {
    retailerLocationGroup: StoreGroup
  }
}

export interface CreateStoreGroupsMutationVariables {
  name: string
  retailerId: string
  retailerLocationIds: string[]
}

interface CreateProps {
  name: string
  retailerLocationIds: string[]
}

export const useCreateStoreGroup = () => {
  const retailerId = useRetailerId()

  const [triggerCreateStoreGroup, { data, error, loading }] = useDashMutation<
    CreateStoreGroupMutationData,
    CreateStoreGroupsMutationVariables
  >(CREATE_STORE_GROUP_MUTATION)

  const createStoreGroup = ({ name, retailerLocationIds }: CreateProps) =>
    triggerCreateStoreGroup({
      variables: {
        retailerId,
        name,
        retailerLocationIds,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ListStoreGroupsDocument,
          variables: {
            ...storeGroupListDefaultParams,
            retailerId,
          },
        },
      ],
    })

  const createdStoreGroup = data && data.retailerLocationGroupCreate.retailerLocationGroup

  return {
    createStoreGroup,
    createdStoreGroup,
    loading,
    error,
  }
}
