import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalDimensionsGrouping,
} from '../helpers/schema.helpers'
import { InsightsFormatterType, InsightsGroupByTypes } from '../types/schema.types'
import { orderItemsSchemaFilterParamsConfig } from './insightsPortalOrderItemsSchema.helpers'
import { GroupBys, Metrics, Dimensions } from './insightsPortalOrderItems.types'

export { GroupBys, Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.COUNT_ORDER]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_ORDER',
      tooltip: 'insightsPortalDomain.basketDashboard.tooltips.COUNT_ORDER',
    },
    [Metrics.SUM_GMV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.performanceDashboard.metrics.SUM_GMV',
    },
    [Metrics.AVG_ITEM_FOUND]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.AVG_ITEM_FOUND',
      tooltip: 'insightsPortalDomain.outOfStockDashboard.tooltips.AVG_ITEM_FOUND',
    },
    [Metrics.LOST_GMV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.LOST_GMV',
      tooltip: 'insightsPortalDomain.outOfStockDashboard.tooltips.LOST_GMV',
    },
    [Metrics.AVG_ITEM_FILLED]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.AVG_ITEM_FILLED',
      tooltip: 'insightsPortalDomain.outOfStockDashboard.tooltips.AVG_ITEM_FILLED',
    },
    [Metrics.AVG_LOST_GMV_PER_STORE]: {
      formatter: InsightsFormatterType.Currency,
      label:
        'insightsPortalDomain.outOfStockDashboard.geographyPerformance.table.header.avgLostGmv',
    },
    [Metrics.AVG_ITEM_REPLACED]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.outOfStockTable.replacementRate',
    },
    [Metrics.AVG_BASKET]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.orderItems.metrics.AVG_BASKET',
      tooltip: 'insightsPortalDomain.basketDashboard.tooltips.AVG_BASKET',
    },
    [Metrics.AVG_ORDERED_ITEM]: {
      formatter: InsightsFormatterType.Decimal,
      label: 'insightsPortalDomain.orderItems.metrics.AVG_ORDERED_ITEM',
      tooltip: 'insightsPortalDomain.basketDashboard.tooltips.AVG_ORDERED_ITEM',
    },
    [Metrics.AVG_ITEM_FILLED_PRICE]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.orderItems.metrics.AVG_ITEM_FILLED_PRICE',
      tooltip: 'insightsPortalDomain.basketDashboard.tooltips.AVG_ITEM_FILLED_PRICE',
    },
  },
  dimensionsConfig: {
    [Dimensions.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [Dimensions.PRODUCT_CATEGORY_ID]: {
      type: InsightsGroupByTypes.Info,
      private: true,
    },
    [Dimensions.PICKUP]: {
      type: InsightsGroupByTypes.Program,
      private: true,
      label: 'insightsPortalDomain.filters.fulfillmentType.label',
    },
    [Dimensions.FULFILLMENT_TYPE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.fulfillmentType.label',
    },
    [Dimensions.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.ALCOHOL]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.alcohol.orders.label',
    },
    [Dimensions.EXPRESS]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.express.orders.label',
    },
    [Dimensions.LOYALTY]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.loyalty.label',
    },
    [Dimensions.RETAILER_PRODUCT]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collectionProducts.groupBys.productName',
    },
    [Dimensions.EBT_ITEM]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      private: true,
      label: 'insightsPortalDomain.filters.ebt.label',
    },
    [Dimensions.FIRST_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
    },
    [Dimensions.VIRTUAL_WAREHOUSE]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.convenience.label',
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION',
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 5,
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_CODE',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [Dimensions.PARTNER_GEO_DIVISION]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.partnerDivision.label',
    },
    [Dimensions.ORDER_CURRENCY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.orderCurrency.label',
    },
    [Dimensions.PRODUCT_UPC_CODE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.collections.groupBys.productUpcCode',
    },
    [Dimensions.REGION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [Dimensions.EBT_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.ebt.label',
    },
    [Dimensions.L1_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel1',
    },
    [Dimensions.L2_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel2',
    },
    [Dimensions.L3_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel3',
    },
    [Dimensions.L4_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel4',
    },
    [Dimensions.L5_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel5',
    },
    [Dimensions.L6_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel6',
    },
    [Dimensions.RX_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.rx.orders.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_GROUP]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.warehouseLocationGroup.label',
    },
    ...buildTimeIntervalDimensionsConfig,
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [
        Dimensions.WAREHOUSE,
        Dimensions.WAREHOUSE_LOCATION_STATE,
        Dimensions.REGION,
        Dimensions.WAREHOUSE_LOCATION_GROUP,
        Dimensions.WAREHOUSE_LOCATION,
        Dimensions.WAREHOUSE_LOCATION_CODE,
        Dimensions.PARTNER_GEO_DIVISION,
      ],
    },
    {
      label: 'insightsPortalDomain.filters.orders',
      dimensions: [
        Dimensions.ORDER_SOURCE,
        Dimensions.FULFILLMENT_TYPE,
        Dimensions.ALCOHOL,
        Dimensions.VIRTUAL_WAREHOUSE,
        Dimensions.EXPRESS,
        Dimensions.LOYALTY,
        Dimensions.EBT_ORDER,
        Dimensions.RX_ORDER,
        Dimensions.FIRST_ORDER,
        Dimensions.ORDER_CURRENCY,
      ],
    },
    {
      label: 'insightsPortalDomain.filters.groups.categories',
      dimensions: [
        Dimensions.L1_CATEGORY,
        Dimensions.L2_CATEGORY,
        Dimensions.L3_CATEGORY,
        Dimensions.L4_CATEGORY,
        Dimensions.L5_CATEGORY,
        Dimensions.L6_CATEGORY,
      ],
    },
    {
      label: 'insightsPortalDomain.inventoryIntelligence.oosTable.displayName',
      dimensions: [Dimensions.RETAILER_PRODUCT, Dimensions.PRODUCT_UPC_CODE],
    },
  ],
  // TODO: deprecate
  groupBys: GroupBys,
  filterConfigs: orderItemsSchemaFilterParamsConfig,
  groupBysConfig: {
    ...buildTimeIntervalGroupBysConfig,
    [GroupBys.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.PRODUCT_CATEGORY_ID]: {
      type: InsightsGroupByTypes.Info,
      private: true,
    },
    [GroupBys.PICKUP]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.fulfillmentType.label',
    },
    [GroupBys.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [GroupBys.ALCOHOL]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.alcohol.orders.label',
    },
    [GroupBys.EXPRESS]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.express.orders.label',
    },
    [GroupBys.LOYALTY]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.loyalty.label',
    },
    [GroupBys.RETAILER_PRODUCT]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collectionProducts.groupBys.productName',
    },
    [GroupBys.EBT]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.ebt.label',
    },
    [GroupBys.FIRST_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
    },
    [GroupBys.VIRTUAL_WAREHOUSE]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.convenience.label',
    },
    [GroupBys.WAREHOUSE_LOCATION]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION',
    },
    [GroupBys.WAREHOUSE_LOCATION_STATE]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [GroupBys.PARTNER_GEO_DIVISION]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.partnerDivision.label',
    },
    [GroupBys.ORDER_CURRENCY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.orderCurrency.label',
    },
    [GroupBys.PRODUCT_UPC_CODE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.collections.groupBys.productUpcCode',
    },
    [GroupBys.REGION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [GroupBys.L1_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel1',
    },
    [GroupBys.L2_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel2',
    },
    [GroupBys.L3_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel3',
    },
    [GroupBys.L4_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel4',
    },
    [GroupBys.L5_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel5',
    },
    [GroupBys.L6_CATEGORY]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.productCategories.CategoryLevel6',
    },
    [GroupBys.RX_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.rx.label',
    },
  },
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    orderItemsSchemaFilterParamsConfig
  ),
})
