import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, AdminNavEntryPoint } from '@retailer-platform/dashboard/routing'
import { scanAndPayRouter } from './routing/scanAndPayRouter'
import { scanAndPayAdminRouter } from './routing/scanAndPayAdminRouter'
import { scanAndPayPublicRouter } from './routing/scanAndPayPublicRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'scan-and-pay',
  totem: {
    entity: 'scan-and-pay-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [scanAndPayRouter],
    admin: [scanAndPayAdminRouter],
    public: [scanAndPayPublicRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [],
}

export default definition
