export const ACCOUNT_MANAGEMENT_EN_US_TRANSLATIONS = {
  'accountManagementDomain.nav.title': 'Users',
  'accountManagementDomain.nav.security.title': 'Security',
  'accountManagementDomain.list.pageTitle': 'Search users - User Management',
  'accountManagementDomain.update.pageTitle': 'Edit user - User Management',
  'accountManagementDomain.create.pageTitle': 'Create user - User Management',
  'accountManagementDomain.roleType.custom': 'Custom',
  'accountManagementDomain.roleType.default': 'Default',
  'accountManagementDomain.roleType.instacartInternal': 'Instacart Internal',
  'accountManagementDomain.roles.all': 'All available roles',
  'accountManagementDomain.roles.loading': 'Loading roles...',
  'accountManagementDomain.roles.placeholder': 'Select role',
  'accountManagementDomain.roles.status.placeholder': 'Select status',
  'accountManagementDomain.roles.insights_portal.name': 'Insights Portal',
  'accountManagementDomain.roles.insights_portal.description': 'Access to Insights Portal',
  'accountManagementDomain.roles.retailer_tools_oncall.name':
    'Retailer Tools On-Call (Internal User Access)',
  'accountManagementDomain.roles.retailer_tools_oncall.description':
    'Gives additional access required for on-call tasks (e.g. modifying internal users)',
  'accountManagementDomain.roles.ads_reports.name': 'Ads Reports',
  'accountManagementDomain.roles.ads_reports.description':
    'Provides access to ads related reports, including financial reports.',
  'accountManagementDomain.roles.developer.name': 'Developer',
  'accountManagementDomain.roles.developer.description':
    'Users with the Developer role and access the Developer Portal.',
  'accountManagementDomain.roles.partner_docsite_manager.name': 'Partner Docsite Manager',
  'accountManagementDomain.roles.partner_docsite_manager.description':
    'Can assign roles related to partner docsites.',
  'accountManagementDomain.roles.qas_manager.name': 'Quality Audit Workflow Manager',
  'accountManagementDomain.roles.qas_manager.description':
    'The elevated role to do manager/user actions in the Quality Audit Workflow Tool (Catalog Admin).',
  'accountManagementDomain.roles.store_reports.name': 'Store Reports',
  'accountManagementDomain.roles.store_reports.description':
    'Provides access to store related reports and analytics.',
  // Start "Legacy" API roles
  'accountManagementDomain.roles.legacy_admin.name': 'Instacart Admin',
  'accountManagementDomain.roles.legacy_admin.description':
    'Instacart Admins have admin level access to the entire platform, with the addition of internal only tools.',
  'accountManagementDomain.roles.legacy_hacker.name': 'Hacker',
  'accountManagementDomain.roles.legacy_hacker.description': 'Full access, including dev tools',
  'accountManagementDomain.roles.legacy_partner.name': 'Manager',
  'accountManagementDomain.roles.legacy_partner.description':
    'Managers can access the homepage, catalog management, and reporting, but cannot manage users.',
  'accountManagementDomain.roles.legacy_partner_staff.name': 'Staff',
  'accountManagementDomain.roles.legacy_partner_staff.description':
    'Staff can access the homepage and catalog management, but cannot access reporting or manage users.',
  'accountManagementDomain.roles.legacy_partner_admin.name': 'Admin',
  'accountManagementDomain.roles.legacy_partner_admin.description':
    'Admins have access to the entire platform, including user management and Stripe tools.',
  'accountManagementDomain.roles.legacy_pharmacist.name': 'Pharmacist',
  'accountManagementDomain.roles.legacy_pharmacist.description':
    'Pharmacists can access the homepage and catalog management and rx reports, but cannot access reporting or manage users.',
  'accountManagementDomain.roles.legacy_pharmacist_admin.name': 'Pharmacist Admin',
  'accountManagementDomain.roles.legacy_pharmacist_admin.description':
    'Pharmacist Admins can access the homepage, catalog management and rx reports; as well as manage and add pharmacist users.',
  'accountManagementDomain.roles.legacy_shift_manager.name': 'Shift Manager',
  'accountManagementDomain.roles.legacy_shift_manager.description':
    'Shift Managers can access shopper and shift management',
  'accountManagementDomain.roles.legacy_read_only.name': 'Read-only',
  'accountManagementDomain.roles.legacy_read_only.description':
    'Users with the read-only role cannot modify data on the application or download any reports',
  // End "Legacy" API roles

  // Internal terms (partner => partner, retailer => retailer)
  'accountManagementDomain.internalTerms.partners.loading': 'Loading partners...',
  'accountManagementDomain.internalTerms.partner.placeholder': 'Select partner(s)',
  'accountManagementDomain.internalTerms.partners.maxOptionsReached':
    'Maximum amount of options reached. Consider adding all partners access instead.',
  'accountManagementDomain.internalTerms.partners.noOptions': 'No options available.',
  'accountManagementDomain.internalTerms.retailers.loading': 'Loading retailer(s)...',
  'accountManagementDomain.internalTerms.retailer.placeholder': 'Select retailer',
  'accountManagementDomain.internalTerms.retailers.placeholder': 'Select retailer(s)',
  'accountManagementDomain.internalTerms.retailerLocations.loading': 'Loading location(s)...',
  'accountManagementDomain.internalTerms.retailerLocations.placeholder': 'Select location(s)',

  // Retailer-facing terms (partner => retailer, retailer => banner)
  'accountManagementDomain.retailerTerms.partners.loading': 'Loading retailers...',
  'accountManagementDomain.retailerTerms.partner.placeholder': 'Select retailer(s)',
  'accountManagementDomain.retailerTerms.retailers.loading': 'Loading banner(s)...',
  'accountManagementDomain.retailerTerms.retailer.placeholder': 'Select banner',
  'accountManagementDomain.retailerTerms.retailers.placeholder': 'Select banner(s)',
  'accountManagementDomain.retailerTerms.retailerLocations.loading': 'Loading location(s)...',
  'accountManagementDomain.retailerTerms.retailerLocations.placeholder': 'Select location(s)',

  'accountManagementDomain.list.noResults.header': 'Sorry, there are no results for these filters.',
  'accountManagementDomain.list.noResults.prompt': 'Try changing or clearing them.',
  'accountManagementDomain.list.filter.emailPlaceholder': 'Search by email',
  'accountManagementDomain.list.headers.name': 'Name',
  'accountManagementDomain.list.headers.email': 'Email',
  'accountManagementDomain.list.headers.phone': 'Phone',
  'accountManagementDomain.list.headers.title': 'Title',
  'accountManagementDomain.list.headers.roles': 'Roles',
  'accountManagementDomain.list.headers.status': 'Status',
  'accountManagementDomain.list.headers.2faStatus': '2FA Status',
  'accountManagementDomain.list.statuses.pendingSetup': 'Pending Setup',
  'accountManagementDomain.list.statuses.pendingSetup.details':
    'User has received account setup email with a link to set a password.',
  'accountManagementDomain.list.statuses.deactivated': 'Deactivated',
  'accountManagementDomain.list.statuses.activated': 'Active',
  'accountManagementDomain.list.statuses.mfaActive': 'Active',
  'accountManagementDomain.list.statuses.mfaInactive': 'Inactive',
  'accountManagementDomain.list.title': 'All users in {partnerName}',
  'accountManagementDomain.list.subtitle':
    'Users are people or teams in your organization with access based on their assigned roles and permissions. Here you can edit, notify, deactivate, or reactivate existing users. You can also add a new user. {readDocumentationURL}',
  'accountManagementDomain.list.subtitle.readDocumentation': 'Read documentation',
  'accountManagementDomain.list.allPartners': 'all partners',
  'accountManagementDomain.addAccount': 'Add new user',
  'accountManagementDomain.list.edit': 'Edit',
  'accountManagementDomain.list.passwordResetTooltip': 'Reset password link ',
  'accountManagementDomain.list.contextMenu.addNewDocumentationUser': 'Add new documentation user',
  'accountManagementDomain.breadcrumbs.root': 'User Management',
  'accountManagementDomain.breadcrumbs.create': 'Create New User',
  'accountManagementDomain.breadcrumbs.update': 'Update User',
  'accountManagementDomain.form.fields.givenName': 'Name',
  'accountManagementDomain.form.fields.surname': 'Last name',
  'accountManagementDomain.form.fields.email': 'Email',
  'accountManagementDomain.form.fields.phone': 'Phone',
  'accountManagementDomain.form.fields.title': 'Title',
  'accountManagementDomain.form.fields.role': 'Role',
  'accountManagementDomain.form.fields.roles': 'Roles',
  'accountManagementDomain.form.fields.partnerLabel': 'Partner',
  'accountManagementDomain.form.fields.retailerPartnerLabel': 'Retailer',
  'accountManagementDomain.form.fields.partners.disabledReason':
    'Users has all partner access. Please visit the admin page to change this.',
  'accountManagementDomain.form.fields.bannerLabel': 'Banner',
  'accountManagementDomain.form.fields.hasAllPartnersFullAccess':
    'Grant full access to all partners and retailers',
  'accountManagementDomain.retailerTerms.form.fields.fullPartnerAccess':
    'Grant user full access to all banners',
  'accountManagementDomain.internalTerms.form.fields.fullPartnerAccess':
    'Grant user full access to all retailers',
  'accountManagementDomain.form.fields.fullRetailerAccess':
    'Grant user full access to all locations',
  'accountManagementDomain.form.fields.createAnotherLabel':
    'Save and create another user with the same stores',
  'accountManagementDomain.form.submitButton': 'Submit',
  'accountManagementDomain.form.cancelButton': 'Cancel',
  'accountManagementDomain.form.info.hasFullPartnerAccess':
    'Account will be granted full access for the retailer(s) selected above. If you wish to reduce the scope of access, select some banners.',
  'accountManagementDomain.form.info.hasFullRetailerAccess':
    'Account will be granted full access for the banner(s) selected above. If you wish to reduce the scope of access, select some locations.',
  'accountManagementDomain.form.info.authServiceRequired':
    'This user is required to login via Single Sign-On (SSO)',
  'accountManagementDomain.form.section.store': 'Store selection',
  'accountManagementDomain.form.section.partner': 'Partner selection',
  'accountManagementDomain.form.section.account': 'User details',
  'accountManagementDomain.form.section.2fa.title': 'Two-factor authentication',
  'accountManagementDomain.form.section.2fa.description': 'Manage 2FA for individual users.',
  'accountManagementDomain.form.section.2fa.details':
    '<p>You can deactivate, activate, or reset 2FA for individual users.</p>' +
    '<ul>' +
    '<li><b>Reset 2FA</b> will lock the user out of their account until a new 2FA device is authenticated.</li>' +
    '<li><b>Deactivate</b> will turn off 2FA for this user.</li>' +
    '<li><b>Activate</b> will turn on 2FA for this user</li>' +
    '</ul>',
  'accountManagementDomain.form.section.2fa.status': 'User 2FA status',
  'accountManagementDomain.form.section.2fa.status.failedToFetch': 'Failed to fetch 2FA status',
  'accountManagementDomain.form.section.2fa.enableAfterCreated':
    'You can enable 2FA after the user is created.',
  'accountManagementDomain.form.section.2fa.activate': 'Activate 2FA',
  'accountManagementDomain.form.section.2fa.deactivate': 'Deactivate 2FA',
  'accountManagementDomain.form.section.2fa.reset2fa': 'Reset 2FA',
  'accountManagementDomain.form.section.2fa.active': 'Active',
  'accountManagementDomain.form.section.2fa.inactive': 'Inactive',
  'accountManagementDomain.form.section.2fa.activate.confirmQuestion': 'Activate 2FA?',
  'accountManagementDomain.form.section.2fa.activate.confirmText':
    'Are you sure you want to activate 2FA for this account? They will be able to set up 2FA immediately. If they do not set up 2FA on their account after 7 days from today they will be required to upon their next login.',
  'accountManagementDomain.form.section.2fa.deactivate.confirmQuestion': 'Deactivate 2FA?',
  'accountManagementDomain.form.section.2fa.deactivate.confirmText':
    'Do you want to deactivate 2FA for this account? They will no longer need 2FA to log in.',
  'accountManagementDomain.form.section.2fa.reset2fa.confirmQuestion': 'Reset 2FA?',
  'accountManagementDomain.form.section.2fa.reset2fa.confirmText':
    'Are you sure you want to reset 2FA for this account? They will be required to set up 2FA on their account again and will not be able to log in until they do.',
  'accountManagementDomain.form.addStore.addStoreButton': 'Add store locations',
  'accountManagementDomain.form.addStore.storesSelected': '{numStores} stores selected',
  'accountManagementDomain.form.addStore.clearAll': 'Clear all',
  'accountManagementDomain.form.addStore.storeGroupsLink': 'Create or edit store groups',
  'accountManagementDomain.form.addStore.allStoreGroups': 'All store groups ({number})',
  'accountManagementDomain.form.addStore.allRetailerLocations': 'All individual stores ({number})',
  'accountManagementDomain.form.addStore.modalTitle': 'Search and select locations',
  'accountManagementDomain.form.addStore.search': 'Search...',
  'accountManagementDomain.form.addStore.clearAllSelections': 'Clear all selections',
  'accountManagementDomain.form.addStore.save': 'Save',
  'accountManagementDomain.form.addStore.close': 'Close',
  'accountManagementDomain.form.addStore.remove': 'Remove',
  'accountManagementDomain.form.addStore.allStores': 'All stores',
  'accountManagementDomain.form.addStore.storeGroupDisclaimer':
    'Store group retailer locations will be applied to the user at time of creation/update only',
  'accountManagementDomain.validation.required': "{fieldName} can't be blank.",
  'accountManagementDomain.validation.email': '{fieldName} is not a valid email address.',
  'accountManagementDomain.validation.phoneNumber': 'Invalid Phone Number',
  'accountManagementDomain.validation.partners': 'Please select a partner',
  'accountManagementDomain.validation.retailerPartners': 'Please select a retailer',
  'accountManagementDomain.validation.retailers': 'Please select a banner',
  'accountManagementDomain.validation.retailerLocations': 'Please select a location',
  'accountManagementDomain.validation.roles': 'Please select a role',
  'accountManagementDomain.create.notification.success.header': 'User created successfully!',
  'accountManagementDomain.create.notification.success.content':
    'An email has been sent to the user with further instructions on confirming and activating their new account.',
  'accountManagementDomain.create.notification.error.header': 'Problem creating user',
  'accountManagementDomain.create.notification.error.content':
    'An account with this email may already exist. Please try again, and contact support if the problem persists.',
  'accountManagementDomain.error.unknown': 'Unknown',
  'accountManagementDomain.error.conflict': 'A user with this email address already exists.',
  'accountManagementDomain.error.insufficientAccessLevel':
    'You do not have a sufficient access level to make this change.',
  'accountManagementDomain.error.missingRequiredPermission':
    'You are missing a required permission to create or edit this account.',
  'accountManagementDomain.error.roleNotAssignableToAccount':
    'You are not allowed to assign the following roles: {UnassignableRoles}',
  'accountManagementDomain.error.roleNotAttainableForAccount':
    'This account cannot receive the following roles: {UnattainableRoles}',
  'accountManagementDomain.update.notification.success.header': 'User updated successfully!',
  'accountManagementDomain.update.notification.success.content':
    'Changes should have been reflected immediately.',
  'accountManagementDomain.update.notification.error.header': 'Problem updating user',
  'accountManagementDomain.update.notification.error.content':
    "We couldn't update this user. Please try again, if that doesn't work, please contact support.",
  'accountManagementDomain.update.loading.header': 'Loading',
  'accountManagementDomain.update.loading.content':
    "Hold on tight, we're retrieving the requested user information.",
  'accountManagementDomain.update.getAccount.error.header': 'Uh oh.',
  'accountManagementDomain.update.getAccount.error.content':
    'Something happened while attempting to get the user information. Please try again, and if the problem persists, contact support.',
  'accountManagementDomain.update.getAccount.error.retry': 'Retry',
  'accountManagementDomain.disableAccount.success.header': 'User deactivated successfully!',
  'accountManagementDomain.disableAccount.error.header': 'Something went wrong',
  'accountManagementDomain.disableAccount.error.content':
    'Please try again, if this persists, contact support.',
  'accountManagementDomain.disableAccount.confirm.content':
    'Are you sure you want to deactivate the user for {target}?',
  'accountManagementDomain.disableAccount.confirm.confirmLabel': 'Deactivate',
  'accountManagementDomain.disableAccount.confirm.cancelLabel': 'Cancel',
  'accountManagementDomain.enableAccount.success.header': 'User reactivated successfully!',
  'accountManagementDomain.enableAccount.error.header': 'Something went wrong',
  'accountManagementDomain.enableAccount.error.content':
    'Please try again, if this persists, contact support.',
  'accountManagementDomain.enableAccount.confirm.content':
    'Are you sure you want to reactivate the user {target}?',
  'accountManagementDomain.enableAccount.confirm.confirmLabel': 'Reactivate',
  'accountManagementDomain.enableAccount.confirm.cancelLabel': 'Cancel',
  'accountManagementDomain.enableAccount.reactivateTitle': 'Reactivate user',
  'accountManagementDomain.enableAccount.deactivateTitle': 'Deactivate user',
  'accountManagementDomain.form.readOnlyAccountInfo':
    'Your account is read-only, so you cannot edit any users. If you want to edit a user, contact your administrator to remove the read-only user setting from your account.',

  'accountManagementDomain.accountRequest.errorLoadingAccountRequest':
    'Error loading account request',
  'accountManagementDomain.accountRequest.tryAgainLater':
    'There was an error loading this account request. Please try again later.',
  'accountManagementDomain.accountRequest.loadingAccountRequest': 'Loading account request...',
  'accountManagementDomain.accountRequest.accountRequest': 'Account request',
  'accountManagementDomain.accountRequest.email': 'Email',
  'accountManagementDomain.accountRequest.status': 'Status',
  'accountManagementDomain.accountRequest.name': 'Name',
  'accountManagementDomain.accountRequest.submittedAt': 'Submitted at',
  'accountManagementDomain.accountRequest.requestReason': 'Request reason',
  'accountManagementDomain.accountRequest.roles': 'Requested roles',
  'accountManagementDomain.accountRequest.warning': 'Warning:',
  'accountManagementDomain.accountRequest.emailDomainWarning':
    '{email} does not match your email domain ({emailDomain}). Be sure to double check if this is correct before proceeding with creating the account.',
  'accountManagementDomain.accountRequest.existingAccountInfo':
    'This account request has already been approved and an account has been created.',
  'accountManagementDomain.accountRequest.goToAccount': 'Go to account',
  'accountManagementDomain.accountRequest.continue': 'Continue',
  'accountManagementDomain.accountRequest.viewAccountRequest': 'View account request',
  'accountManagementDomain.accountRequest.state.pending_email_verification':
    'Pending email verification',
  'accountManagementDomain.accountRequest.state.pending_admin_action': 'Pending',
  'accountManagementDomain.accountRequest.state.completed': 'Completed',
  'accountManagementDomain.accountRequest.state.rejected': 'Rejected',

  'accountManagementDomain.createDocumentationUser.info1':
    '<b>Note:</b> this will create a user that can only view the documentation and otherwise has <b>no access to IPP</b>. This is usually only needed when the partner has yet to be onboarded.',
  'accountManagementDomain.createDocumentationUser.info2':
    'This user may be upgraded later to have access to IPP by assigning the account partners/retailers and the appropriate roles.',
  'accountManagementDomain.createDocumentationUser.header': 'Add new documentation-only user',
  'accountManagementDomain.createDocumentationUser.fields.email': 'Email',
  'accountManagementDomain.createDocumentationUser.fields.email.error': 'Invalid email address',
  'accountManagementDomain.createDocumentationUser.fields.docsiteRoles': 'Docsite roles',
  'accountManagementDomain.createDocumentationUser.actions.create': 'Create',
  'accountManagementDomain.createDocumentationUser.actions.cancel': 'Cancel',

  'accountManagementDomain.security.title': 'Security',
  'accountManagementDomain.security.subtitle': 'Manage the security settings for your company',
  'accountManagementDomain.security.2fa': 'Two-factor authentication',
  'accountManagementDomain.security.2fa.description': 'Manage 2FA for your entire company.',
  'accountManagementDomain.security.2fa.learnMore': 'Learn more',
  'accountManagementDomain.security.2fa.details.toEnable':
    'To require 2FA for all your users, tap <b>Activate</b>. Users will receive an email notifying them of the security change, along with a link to activate 2FA for their account.',
  'accountManagementDomain.security.2fa.details.usersHave7DaysToActivate':
    'Once required, each user will have 7 days to activate a 2FA device, after which they will be locked out and must set up 2FA to log in.',
  'accountManagementDomain.security.2fa.failedToFetch':
    'Failed to fetch 2FA status. Please try again later, or contact support if the problem persists.',
  'accountManagementDomain.security.2fa.missingPermission':
    'You do not have access to do this. Please contact support.',
  'accountManagementDomain.security.2fa.activate': 'Activate 2FA',
  'accountManagementDomain.security.2fa.activate.confirmQuestion': 'Activate 2FA?',
  'accountManagementDomain.security.2fa.activate.confirmText':
    'Are you sure you want to activate 2FA for your banner?',
  'accountManagementDomain.security.2fa.active': '2FA Active',
  'accountManagementDomain.security.2fa.inactive': '2FA Inactive',
  'accountManagementDomain.security.2fa.notification.enabledSuccess': '2FA enabled successfully!',
  'accountManagementDomain.security.2fa.notification.enabledSuccessContent':
    'The change has been reflected immediately.',
  'accountManagementDomain.security.2fa.notification.enabledError':
    'There was an error enabling 2FA.',
  'accountManagementDomain.security.2fa.notification.enabledErrorContent':
    'Please contact support.',

  'accountManagementDomain.myAccount.title': 'My Account',
  'accountManagementDomain.myAccount.form.name': 'Full name',
  'accountManagementDomain.myAccount.form.email': 'Email address',
  'accountManagementDomain.myAccount.form.submit': 'Submit',
  'accountManagementDomain.myAccount.form.currentPasswordLabel': 'Current password',
  'accountManagementDomain.myAccount.form.currentPasswordPlaceholder':
    'Enter your current password',
  'accountManagementDomain.myAccount.form.currentPassword.error.default':
    'Current password is required',
  'accountManagementDomain.myAccount.form.currentPassword.error.matches':
    'New password cannot match current password',
  'accountManagementDomain.myAccount.form.password': 'New password',
  'accountManagementDomain.myAccount.success.title': 'Password changed',
  'accountManagementDomain.myAccount.success.body': 'Password changed successfully',
  'accountManagementDomain.myAccount.error.title': 'Error updating password',
  'accountManagementDomain.myAccount.error.body':
    'There was an error updating your password. Please try again',
} as const

export default ACCOUNT_MANAGEMENT_EN_US_TRANSLATIONS
