import React, { type FunctionComponent } from 'react'
import { useMemo } from 'react'
import { useCurrentAccountDetails } from '../../api/current-account/currentAccount.hooks'
import { ErrorPage } from '../../gin-and-tonic/containers/error-page/ErrorPage'
import { SFXProvider } from '../sfx/sfx.context'
import { CurrentAccountContext } from './CurrentAccountContext'

export const CurrentAccountProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { error, loading, account } = useCurrentAccountDetails()

  const contextValue = useMemo(
    () => ({
      account,
    }),
    [account]
  )

  if (loading) return null

  if (error) return <ErrorPage />

  return (
    <CurrentAccountContext.Provider value={contextValue}>
      <SFXProvider>{children}</SFXProvider>
    </CurrentAccountContext.Provider>
  )
}
