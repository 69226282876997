import {
  type PickupAnalyticsApiData,
  type PickupAnalyticsApiMetric,
  PickupAnalyticsChartTitles,
  PickupAnalyticsMetricTitles,
  type PickupAnalyticsMetric,
  type PickupAnalyticsResponseChart,
  type PickupAnalyticsResponseMetric,
  type PickupAnalyticsChartData,
  type PickupAnalyticsOverallPerformanceChartData,
  type PickupAnalyticsViewData,
} from './pickupAnalytics.types'

function isChart(
  dataViz: PickupAnalyticsResponseChart | PickupAnalyticsResponseMetric
): dataViz is PickupAnalyticsResponseChart {
  return (dataViz as PickupAnalyticsResponseChart).type === 'charts'
}

const getChartDataByTitle = (
  apiData: PickupAnalyticsApiData,
  chartTitle: PickupAnalyticsChartTitles
): PickupAnalyticsChartData => {
  const charts = apiData.filter(isChart)

  const selectedChart = charts.find(chart => chart.data.title_key === chartTitle)

  if (!selectedChart) {
    return []
  }

  return selectedChart.data.charts[0].data[0]
}

const getOverallPerformanceChartData = (
  apiData: PickupAnalyticsApiData
): PickupAnalyticsOverallPerformanceChartData => {
  const charts = apiData.filter(isChart)

  const overallPerformanceChart = charts.find(
    chart => chart.data.title_key === PickupAnalyticsChartTitles.PickupVolumeTrend
  )

  if (!overallPerformanceChart) {
    return {
      [PickupAnalyticsChartTitles.PickupVolumeTrend]: [],
    }
  }

  const overallPerformanceChartData = overallPerformanceChart.data.charts.reduce((acc, chart) => {
    acc[chart.title_key] = chart.data[0]
    return acc
  }, {})

  // add empty arrays if data for the other charts are missing
  if (!overallPerformanceChartData[PickupAnalyticsChartTitles.AllVolumeTrend]) {
    overallPerformanceChartData[PickupAnalyticsChartTitles.AllVolumeTrend] = []
  }

  if (!overallPerformanceChartData[PickupAnalyticsChartTitles.DeliveryVolumeTrend]) {
    overallPerformanceChartData[PickupAnalyticsChartTitles.DeliveryVolumeTrend] = []
  }

  return overallPerformanceChartData
}

const defaultPeriodData = {
  currentPeriodData: 0,
  lastPeriodData: 0,
}

const ensureMetricData = (metric: PickupAnalyticsMetric = defaultPeriodData) => metric

export const pickupAnalyticsConvertApiDataToViewData = (
  apiData: PickupAnalyticsApiData
): PickupAnalyticsViewData => {
  const apiDataMapping = {}

  apiData[0].data.metrics.forEach(metric => {
    apiDataMapping[metric.data.title_key] = {
      currentPeriodData: metric.data?.this_period || 0,
      lastPeriodData: metric.data?.last_period || 0,
    }
  })

  return {
    pickup: ensureMetricData(apiDataMapping[PickupAnalyticsMetricTitles.PickupVolume]),
    batchAcceptance: ensureMetricData(
      apiDataMapping[PickupAnalyticsMetricTitles.WeightedAverageBatchAcceptance]
    ),
    p75WaitTime: ensureMetricData(apiDataMapping[PickupAnalyticsMetricTitles.P75WaitTime]),
    experienceRating: ensureMetricData(
      apiDataMapping[PickupAnalyticsMetricTitles.AverageExperienceRating]
    ),
    minutesPerItem: ensureMetricData(
      apiDataMapping[PickupAnalyticsMetricTitles.WeightedAverageTimePerPickedItem]
    ),
    latePercentage: ensureMetricData(apiDataMapping[PickupAnalyticsMetricTitles.LatePercentage]),
    utilizationPercentage: ensureMetricData(
      apiDataMapping[PickupAnalyticsMetricTitles.UtilizationPercentage]
    ),
    shopperRating: ensureMetricData(
      apiDataMapping[PickupAnalyticsMetricTitles.AverageShopperRating]
    ),
    overallPerformanceSeries: getOverallPerformanceChartData(apiData),
    p75WaitTimeSeries: getChartDataByTitle(apiData, PickupAnalyticsChartTitles.P75WaitTimeTrend),
  }
}

export const pickupAnalyticsConvertApiMetricToDisplayMetric = (
  apiMetric: PickupAnalyticsApiMetric
): PickupAnalyticsMetric => {
  const convertedData: PickupAnalyticsMetric = {
    currentPeriodData: apiMetric.this_period,
    lastPeriodData: apiMetric.last_period,
  }

  if (apiMetric?.delivery) {
    convertedData.delivery = {
      currentPeriodData: apiMetric.delivery.this_period,
      lastPeriodData: apiMetric.delivery.last_period,
    }
  }

  if (apiMetric?.total_pickup_delivery) {
    convertedData.total_pickup_delivery = {
      currentPeriodData: apiMetric.total_pickup_delivery.this_period,
      lastPeriodData: apiMetric.total_pickup_delivery.last_period,
    }
  }

  return convertedData
}
