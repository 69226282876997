/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type FunctionComponent, useMemo } from 'react'
import { CheckboxWithLabel, colors, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import {
  type RetailerProductManagedAttributeValue,
  type ProductWithDetails,
} from '@retailer-platform/domain-products'
import { camelCase } from 'lodash'

interface Props {
  product: ProductWithDetails
}

const StyledCheckboxWithLabel = styled(CheckboxWithLabel)({
  '& > label': {
    color: colors.GRAYSCALE.X50,
    marginLeft: spacing.X8,
  },
})

function parseAttribute(
  label: string,
  product: ProductWithDetails,
  managedAttributesMap: { [p: string]: RetailerProductManagedAttributeValue[] }
) {
  // Value might be held at the top level field otherwise we'll need to dig in
  const topLevelValue = product?.[camelCase(label)]
  if (topLevelValue) return topLevelValue

  const retailerLabels: RetailerProductManagedAttributeValue[] =
    managedAttributesMap?.['retailer_labels'] ?? []

  if (managedAttributesMap[label]) {
    // This value might be held in a managed attribute
    return managedAttributesMap?.[label]?.[0]?.value === 'true'
  } else if (managedAttributesMap['retailer_labels']) {
    // Value might have been assigned by a retailer label
    return !!retailerLabels.find(managedAttribute => {
      return managedAttribute?.value === label
    })
  } else {
    return false
  }
}

export const ProductDietaryAttributes: FunctionComponent<React.PropsWithChildren<Props>> = ({
  product,
}) => {
  const managedAttributesMap: {
    [name: string]: RetailerProductManagedAttributeValue[]
  } = useMemo(() => {
    const managedAttributeList = product?.managedAttributeValues ?? []
    return managedAttributeList.reduce((currentMap: {
      [name: string]: RetailerProductManagedAttributeValue[]
    }, value) => {
      if (!currentMap[value?.name]) {
        currentMap[value.name] = []
      }
      currentMap[value.name].push(value)
      return currentMap
    }, {})
  }, [product])

  const dietaryMap = useMemo(() => {
    const dietaryMap = {
      alcoholic: {
        label: 'Alcoholic',
        value: parseAttribute('alcoholic', product, managedAttributesMap),
      },
      lowFat: {
        label: 'Low-Fat',
        value: parseAttribute('lowFat', product, managedAttributesMap),
      },
      fatFree: {
        label: 'Fat-Free',
        value: parseAttribute('fatFree', product, managedAttributesMap),
      },
      kosher: {
        label: 'Kosher',
        value: parseAttribute('kosher', product, managedAttributesMap),
      },
      sugarFree: {
        label: 'Sugar-Free',
        value: parseAttribute('sugarFree', product, managedAttributesMap),
      },
      vegan: {
        label: 'Vegan',
        value: parseAttribute('vegan', product, managedAttributesMap),
      },
      vegetarian: {
        label: 'Vegetarian',
        value: parseAttribute('vegetarian', product, managedAttributesMap),
      },
      glutenFree: {
        label: 'Gluten-Free',
        value: parseAttribute('gluten_free', product, managedAttributesMap),
      },
      organic: {
        label: 'Organic',
        value: parseAttribute('organic', product, managedAttributesMap),
      },
      nonGmo: {
        label: 'Non-GMO',
        value: parseAttribute('non_gmo', product, managedAttributesMap),
      },
    }

    return dietaryMap
  }, [product])

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gridAutoRows: '39px',
        gridAutoFlow: 'dense',
      }}
    >
      {Object.keys(dietaryMap).map(name => {
        return (
          <StyledCheckboxWithLabel
            key={name}
            id={name}
            label={dietaryMap[name].label}
            checked={dietaryMap[name].value}
            disabled={true}
          />
        )
      })}
    </div>
  )
}
