import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import {
  Alert,
  Button,
  CheckboxWithLabel,
  ModalBody,
  ModalHeader,
  FormFieldError,
  TextArea,
} from '@retailer-platform/shared-components'
import { useSurvey } from '../RetailerSatisfactionSurveyContext'
import { ButtonsSection } from '../RetailerSatisfactionSurveyComponents'
import { useNavContext } from '../../../containers/nav/utils/NavContext'
import { trackEvent } from '../../../../utils/events/trackEvent'
import {
  type NavV2OptOutSurveyPages,
  type NavV2OptOutSurveyState,
  type NavV2OptOutSurveyControllerProps,
} from './NavV2OptOutSurveyController'
import { NAV_V2_OPT_OUT_SURVEY_INPUT_TYPE, navV2OptOutSurveyData } from './NavV2OptOutSurveyData'

const SurveyInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  marginBottom: '18px',
})

const SurveySubheading = styled.div({
  fontSize: '15px',
  fontVariationSettings: '"wght" 600,"opsz" 8',
  lineHeight: '22px',
})

const SurveySubheadingLabel = styled.span({
  fontSize: '15px',
  fontVariationSettings: 'normal',
  paddingLeft: '2px',
})

const NavV2OptOutSurveyContent = () => {
  const { surveyInputs, infoBannerText, primaryButtonText } = navV2OptOutSurveyData
  const [errorMessage, setErrorMessage] = useState(null)

  const intl = useIntl()
  const { setIsNavV2OptOutSurveyOpen } = useNavContext()
  const {
    surveyState,
    updateSurveyState,
    submitSurvey,
    isSurveySubmitted,
    resetSurveySubmission,
    error,
    loading,
  } = useSurvey<NavV2OptOutSurveyPages, NavV2OptOutSurveyState, NavV2OptOutSurveyControllerProps>()

  const onSelectCheckBox = (id: string) => {
    let updatedFeedbackReasons = []
    if (surveyState.navV2OptOutFeedbackReasons.includes(id)) {
      updatedFeedbackReasons = surveyState.navV2OptOutFeedbackReasons.filter(
        response => response !== id
      )
    } else {
      updatedFeedbackReasons = [...surveyState.navV2OptOutFeedbackReasons, id]
    }
    updateSurveyState('navV2OptOutFeedbackReasons', updatedFeedbackReasons)
  }

  const isButtonDisabled = surveyState.navV2OptOutFeedbackReasons.length === 0

  useEffect(() => {
    if (isSurveySubmitted) {
      setIsNavV2OptOutSurveyOpen(false)
    }
  }, [isSurveySubmitted, setIsNavV2OptOutSurveyOpen])

  useEffect(() => {
    /**
     * Incase the survey submission fails, we:
     * 1. Show the error message on the form
     * 2. Clear the survey submission status, so the user can try again
     * 3. Log the error for internal tracking
     */
    if (error?.message && !errorMessage) {
      const formattedErrorMessage = `${intl.formatMessage({
        id: 'navV2.optOutSurvey.errorMessage',
      })} ${error.message}`

      setErrorMessage(formattedErrorMessage)
      resetSurveySubmission()
      trackEvent({
        id: 'navV2.survey.errors',
        description: 'Tracks errors that occur during NavV2 submission',
        data: {
          error: error.message,
        },
      })
    }
  }, [error?.message, errorMessage, intl, resetSurveySubmission])

  useEffect(() => {
    /**
     * The errorMessage value is only set when the form failed to submit previously
     * Whenever loading is true, we can clear this value
     */
    if (loading && errorMessage && !error?.message) {
      setErrorMessage(null)
    }
  }, [loading, error?.message, errorMessage])

  return (
    <>
      <ModalHeader>{intl.formatMessage({ id: 'navV2.optOutSurvey.title' })}</ModalHeader>
      <ModalBody>
        <>
          {surveyInputs.map(({ subheading, subheadingLabel, inputType, checkboxLabels }) => (
            <SurveyInputContainer key={subheading}>
              <SurveySubheading>
                {intl.formatMessage({ id: subheading })}
                <SurveySubheadingLabel>
                  {subheadingLabel && intl.formatMessage({ id: subheadingLabel })}
                </SurveySubheadingLabel>
              </SurveySubheading>
              {checkboxLabels?.map(label => (
                <CheckboxWithLabel
                  id={label}
                  key={label}
                  label={intl.formatMessage({ id: label })}
                  checked={Boolean(surveyState.navV2OptOutFeedbackReasons.includes(label))}
                  onChange={() => onSelectCheckBox(label)}
                />
              ))}
              {inputType === NAV_V2_OPT_OUT_SURVEY_INPUT_TYPE.TEXT_INPUT && (
                <TextArea
                  data-testid="nav-v2-opt-out-text-input"
                  onChange={evt =>
                    updateSurveyState('navV2OptOutOptionalFeedback', evt.currentTarget.value)
                  }
                  css={{ minHeight: 125, fontSize: 15 }}
                />
              )}
            </SurveyInputContainer>
          ))}
          {errorMessage && <FormFieldError>{errorMessage}</FormFieldError>}
          <Alert variant="info">
            <SurveySubheading>{intl.formatMessage({ id: infoBannerText })}</SurveySubheading>
          </Alert>
          <ButtonsSection>
            <Button
              disabled={isButtonDisabled}
              variant="primary"
              style={{ marginRight: '0px' }}
              onClick={submitSurvey}
              data-testid="nav-v2-opt-out-primary-btn"
            >
              {intl.formatMessage({ id: primaryButtonText })}
            </Button>
          </ButtonsSection>
        </>
      </ModalBody>
    </>
  )
}

export default NavV2OptOutSurveyContent
