import React, { type FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
  borderRadius,
  Button,
  Checkbox,
  colors,
  fontSize,
  fontWeight,
  spacing,
} from '@retailer-platform/shared-components'
import { ChevronDownIcon, FilterIcon } from '@instacart/ids-core'
// eslint-disable-next-line workspaces/no-relative-imports
import { useDomainMessages } from '../../../../../../../../../domains/products/src/utils/domain/intl'

const FilterDropdownButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.X8,
  color: colors.GRAYSCALE.X50,
  paddingLeft: spacing.X8,
  paddingRight: spacing.X8,
})

const FilterContainer = styled.div({
  position: 'relative',

  '&.filter-active button': {
    border: `1px solid ${colors.GRAYSCALE.X30}`,
    outline: `2px solid ${colors.GRAYSCALE.X70}`,
    boxShadow: 'none',
    outlineOffset: '1px',
  },

  '.filter-down-icon': {
    transition: 'transform 0.1s ease',
  },

  '&.filter-active .filter-down-icon': {
    transform: 'rotate(180deg)',
  },
})

const FilterContent = styled.div({
  position: 'absolute',
  backgroundColor: '#fff',
  zIndex: 9999,
  boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.16)`,
  borderRadius: borderRadius.X8,
  padding: spacing.X16,
  margin: spacing.X8,
  gap: spacing.X8,
  display: 'flex',
  flexDirection: 'column',
})

const FilterContentRow = styled.div({
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  gap: spacing.X16,
  color: colors.GRAYSCALE.X50,
  fontWeight: fontWeight.REGULAR,
  fontSize: fontSize.X15,
})

export interface ProductLocationFilters {
  isAvailable: boolean
  hasSalePricing: boolean
}

interface Props {
  filters: ProductLocationFilters
  onChange: (filter: ProductLocationFilters) => void
}

export const ProductLocationsFilters: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onChange,
  filters,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef(null)

  const i18n = useDomainMessages({
    filters: 'productsDomain.view.storeLocations.filters.title',
    isAvailable: 'productsDomain.view.storeLocations.filters.isAvailable',
    hasSalePricing: 'productsDomain.view.storeLocations.filters.hasSalePricing',
  })

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleIsAvailableChange = () => {
    const newState = {
      ...filters,
      isAvailable: !filters.isAvailable,
    }
    if (onChange) onChange(newState)
  }

  const handleHasSalePricingChange = () => {
    const newState = {
      ...filters,
      hasSalePricing: !filters.hasSalePricing,
    }
    if (onChange) onChange(newState)
  }

  return (
    <FilterContainer className={isOpen ? 'filter-active' : ''} ref={dropdownRef}>
      <FilterDropdownButton variant={'tertiary'} onClick={toggleMenu}>
        <FilterIcon size={14} />
        {i18n.filters}
        <ChevronDownIcon className={'filter-down-icon'} size={24} color={'systemGrayscale50'} />
      </FilterDropdownButton>
      {isOpen && (
        <FilterContent>
          <FilterContentRow>
            <Checkbox onChange={handleIsAvailableChange} checked={filters.isAvailable} />
            {i18n.isAvailable}
          </FilterContentRow>
          <FilterContentRow>
            <Checkbox onChange={handleHasSalePricingChange} checked={filters.hasSalePricing} />
            {i18n.hasSalePricing}
          </FilterContentRow>
        </FilterContent>
      )}
    </FilterContainer>
  )
}
