const EN_US = {
  'foodstormDomain.title': 'FoodStorm',
  'foodstormDomain.educationalBanner.heading':
    'Do you offer holiday catering? FoodStorm can help you have the smoothest peak season yet!',
  'foodstormDomain.educationalBanner.information':
    'FoodStorm is Instacart’s order management system built for grocery catering and food service. Give your in-store teams powerful tools and your customers an effortless ordering experience with FoodStorm.',
  'foodstormDomain.educationalBanner.buttonText': 'Get FoodStorm Today',
} as const

export default EN_US
