import React, { useCallback, useMemo } from 'react'
import { useLocalForageKey } from '../../local-storage/useLocalForageKey.hooks'
import { useSessionStorage } from '../../local-storage/useSessionStorage.hooks'
import { type SFXVersion } from '../../sfx/versioning'

export type AdminControlsTemporarySettings = {
  forcedCurrentSFXVersion?: SFXVersion
}
export type AdminControlsSettings = {
  showNavAccessControlSettings: boolean
  showContactHeader: boolean
}

type MergedAdminControlSettings = AdminControlsSettings & AdminControlsTemporarySettings

export type AdminControlsContext = MergedAdminControlSettings & {
  setAdminControl: <K extends keyof AdminControlsSettings>(
    k: K,
    v: AdminControlsSettings[K]
  ) => void
  setTemporaryAdminControl: <K extends keyof AdminControlsTemporarySettings>(
    k: K,
    v: AdminControlsTemporarySettings[K]
  ) => void
}

export const AdminControlsContext = React.createContext<AdminControlsContext | null>(null)

export const AdminControlsProvider = ({ children }) => {
  const { data: temporarySettings, setData: setTemporaryAdminControls } =
    useSessionStorage<AdminControlsTemporarySettings>('adminControls.temporarySettings')
  const { data: settings, setData: setAdminControls } =
    useLocalForageKey<AdminControlsSettings>('adminControls.settings')

  const mergedSettings = useMemo(() => {
    const defaults = {
      // Defaults go here
      showNavAccessControlSettings: false,
      showContactHeader: true,
    }

    const result: MergedAdminControlSettings = {
      ...defaults,
      ...settings,
      ...temporarySettings,
    }

    return result
  }, [settings, temporarySettings])

  const setAdminControl: AdminControlsContext['setAdminControl'] = useCallback(
    (key, value) => {
      setAdminControls({ ...settings, [key]: value })
    },
    [setAdminControls, settings]
  )

  const setTemporaryAdminControl: AdminControlsContext['setTemporaryAdminControl'] = useCallback(
    (key, value) => {
      setTemporaryAdminControls({ ...temporarySettings, [key]: value })
    },
    [setTemporaryAdminControls, temporarySettings]
  )

  return (
    <AdminControlsContext.Provider
      value={{ ...mergedSettings, setAdminControl, setTemporaryAdminControl }}
    >
      {children}
    </AdminControlsContext.Provider>
  )
}

export const useAdminControls = () => {
  const adminControlsContext = React.useContext(AdminControlsContext)
  if (!adminControlsContext) {
    throw new Error('useAdminControls must be used within an AdminControlsProvider')
  }
  return adminControlsContext
}
