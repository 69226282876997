/* eslint react-hooks/rules-of-hooks: "warn" */
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import isObjectLike from 'lodash/isObjectLike'
import clientEnv from '../../utils/global/clientEnv'
import introspectionResult from '../../__codegen__/introspection-result'
import { authLink } from './clientUtilities'
import { errorLink } from './clientUtilities'
import errorsTransformLink from './errorsTransformLink'
import { defaultClientOptions } from './clientOptions'

const retailersHttpLink = new BatchHttpLink({
  uri: `${clientEnv.PUBLIC_CLIENT_RPP_URL}/graphql`,
  batchMax: 1,
})

const retailersClient = new ApolloClient({
  name: 'RetailersApolloClient',
  link: authLink.concat(errorLink).concat(errorsTransformLink).concat(retailersHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultClientOptions,
})

// https://graphql-code-generator.com/docs/generated-config/fragment-matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
})

const dashboardApiHttpLink = new BatchHttpLink({
  uri: `${clientEnv.PUBLIC_CLIENT_ENTERPRISE_DASHBOARD_API_URL}/graphql`,
})

const dataIdFromObject = object => {
  if (isObjectLike(object.id)) {
    // Apollo MemoryCache assumes id is a simple primitive,
    // so types with id property being objects breaks the cache system.
    // We just stringify the whole object to generate a unique id from the body.
    // Initially added to support the instacart_ads_platform_taas_v1_SegmentState type.
    return JSON.stringify(object.id)
  }
  return defaultDataIdFromObject(object)
}

const dashboardClient = new ApolloClient({
  name: 'DashboardApolloClient',
  link: authLink.concat(errorLink).concat(errorsTransformLink).concat(dashboardApiHttpLink),
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject,
  }),
  defaultOptions: defaultClientOptions,
})

const retailerPlatformMeshHttpLink = new BatchHttpLink({
  uri: `${clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL}/graphql`,
  batchMax: 1,
})

const retailerPlatformMeshClient = new ApolloClient({
  name: 'RetailerPlatformMeshApolloClient',
  link: authLink.concat(errorLink).concat(errorsTransformLink).concat(retailerPlatformMeshHttpLink),
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject,
  }),
  defaultOptions: defaultClientOptions,
})

localStorage.removeItem('useApolloClient')

const useRetailerPlatformMeshClient = () => {
  if (!localStorage.getItem('useApolloClient')) {
    if (Math.random() >= clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_USAGE_PERCENT) {
      localStorage.setItem('useApolloClient', 'DashboardApolloClient')
    } else {
      localStorage.setItem('useApolloClient', 'RetailerPlatformMeshApolloClient')
    }
  }

  return localStorage.getItem('useApolloClient') == 'RetailerPlatformMeshApolloClient'
}

// Use this if we need a reference to the dashboard client (e.g., clearing cache)
export const fetchDashboardClientReference = () => dashboardClient

// Use this for feature specific to graphql-mesh
export const fetchRetailerPlatformMeshClient = () => retailerPlatformMeshClient

/*
 * Use this for exiting dashboard-client use-case to transparently switch between
 * old dashboard-client and the new graphql-mesh client
 */
export const fetchDashboardClient = () => {
  if (useRetailerPlatformMeshClient()) {
    return retailerPlatformMeshClient
  } else {
    return dashboardClient
  }
}

export const fetchRetailersClient = () => retailersClient
