import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { storeInterventionsRouter } from './routing/storeInterventionsRouter'
import { storeInterventionsAdminRouter } from './routing/storeInterventionsAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { storeInterventionsAdminAccessControl } from './access-control/storeInterventionsAdminAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'store-interventions',
  totem: {
    entity: 'store-interventions-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [storeInterventionsRouter],
    admin: [storeInterventionsAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.MarketplaceOps,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-store-interventions-request-list',
          labelId: 'storeInterventionsDomain.navbar.requestList.label',
        },
        {
          route: 'app-admin-store-interventions-create-request',
          labelId: 'storeInterventionsDomain.navbar.createRequest.label',
          accessControl: storeInterventionsAdminAccessControl,
        },
      ],
    },
  ],
}

export default definition
