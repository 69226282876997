import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const productsSearchAccessControl: DomainAccessControlConfig = {
  permissions: Permission.Catalog,
}

export const instacartInternalAccessControl: DomainAccessControlConfig = {
  permissions: Permission.InstacartInternal,
}

export const productSearchCPTButton: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.ProductSearchCPTButton],
}
