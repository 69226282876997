export enum FeatureToggle {
  retailerFundedMarketing = 'rt_retailer_funded_marketing',
  retailerFundedMarketingCampaign = 'rt_retailer_funded_marketing_campaign',
  retailerFundedMarketingPhase4 = 'rt_retailer_funded_marketing_phase4',
  retailerFundedMarketingPercentageOff = 'rt_retailer_funded_marketing_percentage_off',
  growBasketSizeTemplate = 'rt_rfm_grow_basket_sizes_template',
  icmsNewAndChurnedCustomersTemplate = 'rt_rfm_icms_new_and_churned_customers_template',
  alcoholBasedOfferOption = 'rt_rfm_alcohol_based_offer_option',
  alcoholBasedOfferOptionTemp = 'rt_rfm_alcohol_based_offer_option_temp',
  InsightsCampaignAnalytics = 'rt_insights_campaign_insights',
  InsightsCampaignInsightsDisabledRetailers = 'rt_insights_campaign_insights_disabled_retailers',
  campaignsV2Enabled = 'rt_campaigns_v2_enabled',
  campaignsV2TemplateAwarenessEnabled = 'rt_campaigns_v2_template_awareness_enabled',
  campaignsV2TemplateEmailEnabled = 'rt_campaigns_v2_template_email_enabled',
  campaignsV2TemplateSearchEnabled = 'rt_campaigns_v2_template_search_enabled',
  campaignsV2TemplateGrowBasketSizes = 'rt_campaigns_v2_template_grow_basket_sizes',
  campaignsV2TemplateOfferEnabled = 'rt_campaigns_v2_template_offer_enabled',
  crmCampaignManualOverrides = 'rt_crm_campaign_manual_overrides',
  campaignV2TemplateMomentEnabled = 'rt_campaigns_v2_template_moment_enabled',
  retailerFundedMarketingUserDenyList = 'rt_rfm_user_deny_list',
  campaignsV2TemplateAcquisition = 'rt_campaigns_v2_template_acquisition',
  campaignsV2TemplateAudienceTargeting = 'rt_campaigns_v2_template_audience_targeting',
  campaignsCrmPushEnabled = 'rt_campaigns_crm_push_enabled',
  segmentationTargetingEmailCSVEnabled = 'rt_campaign_targeting_email_csv',
  useCrmsForCrmCampaign = 'rt_use_crms_for_crm_campaign',
  offersProductDiscountsEnabled = 'rt_offers_product_discounts',
  offersFreeDeliveryPickupEnabled = 'rt_offers_free_delivery_pickup',
  campaignsDxGyEnabled = 'rt_campaigns_dxgy_integration',
  updateTargetingRules = 'campaign_management_allow_update_targeting_rules',
  enableGlobalSegmentSelectionForRetailerSpecific = 'rt_enable_global_segment_selection_for_retailer_specific_campaigns',
  enableDefineCustomSegment = 'rt_enable_campaign_targeting_rule_builder',
  draftCampaigns = 'rt_draft_campaigns',
  showMarketplaceCampaignsForCostco = 'rt_show_marketplace_campaigns_for_costco',
  rouletteModuleEnabled = 'rt_campaigns_roulette_module_enabled',
  hidePredefinedSegmentsTargetingOption = 'rt_campaign_targeting_tooling_hide_predefined_segments',
  ruleBuilderEnableUserEmailTargeting = 'rt_targeting_rule_builder_enable_user_email',
  ruleBuilderEnableLoyaltyTargeting = 'rt_targeting_rule_builder_enable_loyalty',
  ruleBuilderDisableLoyaltyTargeting = 'rt_targeting_rule_builder_disable_loyalty',
  ruleBuilderEnableInstacartRules = 'rt_targeting_rule_builder_enable_instacart_rules',
  useDynamicCsvUploadBucket = 'rt_use_dynamic_csv_upload_bucket',
  useDynamicCsvUploadBucketRetailer = 'rt_use_dynamic_csv_upload_bucket_retailer',
  enableEditStoreGroup = 'rt_enable_segment_edit_store_group',
  ruleBuilderFeatureRegistryEnabled = 'rt_rule_builder_feature_registry_enabled',
  checkoutBenefitCodeEnabled = 'rt_checkout_benefit_code',
  searchAllSlotTypesEnabled = 'rt_search_all_slot_types_enabled',
  // Scalable Targeting Tooling rollout. Will be short lived
  useFeatureRegistryStoreGroups = 'rt_rule_builder_feature_registry_store_groups_enabled',
  useFeatureRegistryLoyaltyStatus = 'rt_rule_builder_feature_registry_loyalty_enabled',
  useFeatureRegstryEmailCSV = 'rt_rule_builder_feature_registry_email_csv_enabled',
  useFeatureRegistryRetailerOrders = 'rt_rule_builder_feature_registry_retailer_orders_enabled',
  useFeatureRegistryInstacartOrders = 'rt_rule_builder_feature_registry_instacart_orders_enabled',
}
