import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ADMIN_CATALOG_ADMIN = `${URL_APP_ADMIN}/catalog-admin`

// Quality Audit Routes
const URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_LIST = `${URL_ADMIN_CATALOG_ADMIN}/quality-audit-workflow-list`
const URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_BATCH_LIST = `${URL_ADMIN_CATALOG_ADMIN}/quality-audit-workflow-batch-list`
const URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_TASK_LIST = `${URL_ADMIN_CATALOG_ADMIN}/quality-audit-workflows-task-list`
const URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_TASK_LIST_INPUT_DATA = `${URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_TASK_LIST}/input-data/:taskUuid`
const URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_SUBMIT_TASKS = `${URL_ADMIN_CATALOG_ADMIN}/quality-audit-submit-tasks`

// Image Management Routes
const URL_ADMIN_CATALOG_ADMIN_IMAGE_MANAGEMENT = `${URL_ADMIN_CATALOG_ADMIN}/image-management`

// Image Uploads Routes
const URL_ADMIN_CATALOG_ADMIN_CATALOG = `${URL_RETAILER_SCOPED}/catalog-tools`
const URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS = `${URL_ADMIN_CATALOG_ADMIN_CATALOG}/image-uploads`
const URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS_UPLOAD = `${URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS}/upload`
const URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS_BATCH_DETAILS = `${URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS}/:batchId`

// Product Edit Routes
const URL_ADMIN_CATALOG_ADMIN_PRODUCT_EDIT_SHOW = `${URL_ADMIN_CATALOG_ADMIN}/product-edit-show`

const URL_ADMIN_CATALOG_ADMIN_MD_PLATFORM = `${URL_ADMIN_CATALOG_ADMIN}/md-platform`
const URL_ADMIN_CATALOG_ADMIN_MD_PLATFORM_CREATE = `${URL_ADMIN_CATALOG_ADMIN}/md-platform-create`
const URL_ADMIN_CATALOG_ADMIN_MD_PLATFORM_DETAILS = `${URL_ADMIN_CATALOG_ADMIN}/md-platform-details`
// @hygen:inject admin-urls
export type DashRouteSet =
  | DashRouteType<'app-admin-catalog-admin'>
  | DashRouteType<'app-admin-catalog-admin-image-management'>
  | DashRouteType<'app-admin-catalog-admin-catalog'>
  | DashRouteType<'app-admin-catalog-admin-image-uploads'>
  | DashRouteType<'app-admin-catalog-admin-image-uploads-upload'>
  | DashRouteType<'app-admin-catalog-admin-image-uploads-batch-details', { batchId: string }>
  | DashRouteType<'app-admin-catalog-admin-quality-audit-workflow-list'>
  | DashRouteType<'app-admin-catalog-admin-quality-audit-workflow-batch-list'>
  | DashRouteType<'app-admin-catalog-admin-quality-audit-workflow-task-list', { batchId: string }>
  | DashRouteType<'app-admin-catalog-admin-product-edit-show', { productId: string }>
  | DashRouteType<
      'app-admin-catalog-admin-quality-audit-workflow-task-list-input-data',
      { taskUuid: string }
    >
  | DashRouteType<'app-admin-catalog-admin-md-platform'>
  | DashRouteType<'app-admin-catalog-admin-md-platform-create'>
  | DashRouteType<'app-admin-catalog-admin-md-platform-details', { detectionId: string }>
  | DashRouteType<'app-admin-catalog-admin-quality-audit-submit-tasks'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-catalog-admin': URL_ADMIN_CATALOG_ADMIN,
  'app-admin-catalog-admin-image-management': URL_ADMIN_CATALOG_ADMIN_IMAGE_MANAGEMENT,
  'app-admin-catalog-admin-catalog': URL_ADMIN_CATALOG_ADMIN_CATALOG,
  'app-admin-catalog-admin-image-uploads': URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS,
  'app-admin-catalog-admin-image-uploads-upload': URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS_UPLOAD,
  'app-admin-catalog-admin-image-uploads-batch-details':
    URL_ADMIN_CATALOG_ADMIN_IMAGE_UPLOADS_BATCH_DETAILS,
  'app-admin-catalog-admin-quality-audit-workflow-list':
    URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_LIST,
  'app-admin-catalog-admin-quality-audit-workflow-batch-list':
    URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_BATCH_LIST,
  'app-admin-catalog-admin-quality-audit-workflow-task-list':
    URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_TASK_LIST,
  'app-admin-catalog-admin-product-edit-show': URL_ADMIN_CATALOG_ADMIN_PRODUCT_EDIT_SHOW,
  'app-admin-catalog-admin-quality-audit-workflow-task-list-input-data':
    URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_WORKFLOW_TASK_LIST_INPUT_DATA,
  'app-admin-catalog-admin-md-platform': URL_ADMIN_CATALOG_ADMIN_MD_PLATFORM,
  'app-admin-catalog-admin-md-platform-create': URL_ADMIN_CATALOG_ADMIN_MD_PLATFORM_CREATE,
  'app-admin-catalog-admin-md-platform-details': URL_ADMIN_CATALOG_ADMIN_MD_PLATFORM_DETAILS,
  'app-admin-catalog-admin-quality-audit-submit-tasks':
    URL_ADMIN_CATALOG_ADMIN_QUALITY_AUDIT_SUBMIT_TASKS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
