export enum FeatureToggle {
  campaignManagement = 'campaignManagement',
  campaignManagementContentPageSlots = 'rt_campaign_management_content_page_slots',
  campaignManagementContentPageIppDrafts = 'rt_campaign_management_content_ipp_drafts',
  campaignManagementPrimarySplitBanner = 'rt_campaign_management_primary_split_banner',
  campaignManagementRedemptionUrls = 'rt_redemption_urls',
  campaignManagementMultiRetailerPages = 'rt_multi_retailer_pages',
  navigateToContentPage = 'rt_navigate_to_content_page',
  navigateToAuthentication = 'rt_navigate_to_authentication',
  navigateToFlyers = 'rt_navigate_to_flyers',
  slotCreativeCreation = 'rt_slot_creative_creation',
  flyoutsOnCreativeManager = 'rt_flyout_on_creative_manager',
  shoppableItemListCreativeManager = 'rt_shoppable_item_list_on_creative_manager',
  adminCalendarPage = 'rt_campaigns_admin_calendar_enabled',
  adminCreativesPage = 'rt_campaigns_admin_creatives_enabled',
  imageTileCarousel = 'rt_image_tile_carousel',
  quickLinkCarousel = 'rt_quick_link_carousel',
  universalValueCarousel = 'rt_universal_value_carousel',
  collectionSlots = 'rt_collection_slots',
  destinationRowV2 = 'rt_destination_row_v2',
}
