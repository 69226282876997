import React, { createContext, useContext, useMemo } from 'react'
import { type SFXVersion, useGetSFXPlatformVersion, fallbackSFXVersion } from './versioning'

const SFXContext = createContext<{
  /**
   * The enum member value of the resolved version, use along with the `SFXVersion` enum.
   * If you're using this context directly, this is the version that has been resolved for the current user,
   * and it's likely what you need!
   *
   * If you want the returned values from origin, use `currentVersion` instead.
   */
  version?: SFXVersion
  /**
   * The enum member value of the current version that was returned from the origin.
   * Keep in mind this does not consider the current user's group.
   */
  currentVersion?: SFXVersion
  isVersionOverridden: boolean
}>({ version: fallbackSFXVersion, isVersionOverridden: false })

/**
 * Consumes the SFX context data.
 */
export const useSFXContext = () => useContext(SFXContext)

/**
 * This provider is meant to be used to provide SFX data throughout the app.
 * If there's any other needs that may require adding global data, this is the place for it.
 */
export const SFXProvider: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { resolvedVersion, currentVersion, isVersionOverridden } = useGetSFXPlatformVersion()

  const providerValue = useMemo(
    () => ({
      version: resolvedVersion,
      currentVersion,
      isVersionOverridden,
    }),
    [currentVersion, isVersionOverridden, resolvedVersion]
  )

  return <SFXContext.Provider value={providerValue}>{children}</SFXContext.Provider>
}
