import { InputBase, LoadingGenericBase } from '@instacart/ids-core'
import {
  Button,
  FormField,
  FormFieldError,
  FormFieldLabel,
  FormPasswordConfirm,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotificationLevel,
  PasswordInput,
  notify,
  spacing,
  useFormErrorMessages,
} from '@retailer-platform/shared-components'
import React, { useEffect, type FunctionComponent } from 'react'
import { FormProvider, useForm } from 'react-hook-form-7'
import { useCurrentAccountContext } from '@retailer-platform/dashboard/utils'
import { useDomainMessages, type DomainMessages } from '../../utils/domain/intl'
import { useGetCurrentAccountUsesPasswordQuery, useUpdatePasswordMutation } from '../../api'

type AccountManagementMyAccountFormProps = {}

type FormData = {
  currentPassword: string
  password: string
  confirmPassword: string
}

export const AccountManagementMyAccountForm: FunctionComponent<
  React.PropsWithChildren<AccountManagementMyAccountFormProps>
> = () => {
  const [updatePassword, updateResult] = useUpdatePasswordMutation()
  const authUserDataResult = useGetCurrentAccountUsesPasswordQuery()
  const showPasswordFields =
    authUserDataResult.data?.currentEnterpriseAccount.authUserData?.usesPassword

  const messages = useDomainMessages({
    title: 'accountManagementDomain.myAccount.title',
    name: 'accountManagementDomain.myAccount.form.name',
    email: 'accountManagementDomain.myAccount.form.email',
    submit: 'accountManagementDomain.myAccount.form.submit',
    currentPasswordLabel: 'accountManagementDomain.myAccount.form.currentPasswordLabel',
    currentPasswordPlaceholder: 'accountManagementDomain.myAccount.form.currentPasswordPlaceholder',
    password: 'accountManagementDomain.myAccount.form.password',
    successTitle: 'accountManagementDomain.myAccount.success.title',
    successBody: 'accountManagementDomain.myAccount.success.body',
    errorTitle: 'accountManagementDomain.myAccount.error.title',
    errorBody: 'accountManagementDomain.myAccount.error.body',
  })

  const form = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
  })

  const errorMessages = useFormErrorMessages<
    Omit<FormData, 'password' | 'confirmPassword'>,
    DomainMessages
  >(form.formState.errors, {
    currentPassword: {
      default: 'accountManagementDomain.myAccount.form.currentPassword.error.default',
      matches: 'accountManagementDomain.myAccount.form.currentPassword.error.matches',
    },
  })

  const { account } = useCurrentAccountContext()

  useEffect(() => {
    if (updateResult.data?.enterpriseAccountUpdatePassword?.success) {
      notify({
        title: messages.successTitle,
        body: messages.successBody,
        level: NotificationLevel.Success,
      })
    }
  }, [updateResult.data, messages])

  useEffect(() => {
    if (updateResult.error) {
      notify({
        title: messages.errorTitle,
        body: messages.errorBody,
        level: NotificationLevel.Error,
      })
    }
  }, [updateResult.error, messages])

  if (authUserDataResult.loading)
    return (
      <div css={{ position: 'relative', height: 500 }}>
        <LoadingGenericBase />
      </div>
    )

  const isDisabled =
    !showPasswordFields ||
    !form.formState.isValid ||
    updateResult.loading ||
    authUserDataResult.loading

  return (
    <React.Fragment>
      <form
        data-testid="myAccount-form"
        onSubmit={form.handleSubmit(data => {
          updatePassword({ variables: data })
        })}
      >
        <ModalHeader>{messages.title}</ModalHeader>

        <ModalBody>
          <FormProvider {...form}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: spacing.X24,
              }}
            >
              <FormField noMargin>
                <FormFieldLabel>{messages.name}</FormFieldLabel>
                <InputBase id="name" disabled value={account.givenName} />
              </FormField>

              <FormField noMargin>
                <FormFieldLabel>{messages.email}</FormFieldLabel>
                <InputBase id="email" disabled value={account.email} />
              </FormField>

              {showPasswordFields && (
                <React.Fragment>
                  <FormField noMargin>
                    <FormFieldLabel>{messages.currentPasswordLabel}</FormFieldLabel>
                    <PasswordInput
                      data-testid="myAccount-field-currentPassword"
                      id="currentPassword"
                      placeholder={messages.currentPasswordPlaceholder}
                      {...form.register('currentPassword', {
                        required: true,
                        validate: {
                          matches: (value: string) => value !== form.getValues('password'),
                        },
                      })}
                    />

                    {errorMessages.currentPassword && (
                      <FormFieldError>{errorMessages.currentPassword}</FormFieldError>
                    )}
                  </FormField>

                  <FormPasswordConfirm
                    deps={['currentPassword']}
                    passwordLabel={messages.password}
                    testidPrefix="myAccount"
                  />
                </React.Fragment>
              )}
            </div>
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Button
            data-testid="myAccount-submit"
            css={{ margin: 0 }}
            disabled={isDisabled}
            type="submit"
          >
            {messages.submit}
          </Button>
        </ModalFooter>
      </form>
    </React.Fragment>
  )
}
