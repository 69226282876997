import { SFXVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const creativeManagerAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerView],
    minSfxVersion: SFXVersion.r2023_12_0,
  },
]

export const creativeManagerModifyAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.CreativeManagerModify],
    minSfxVersion: SFXVersion.r2023_12_0,
  },
]
