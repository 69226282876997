import React, { useState, useEffect } from 'react'
import {
  navV2EducationPrimaryModalData,
  navV2EducationSecondaryModalData,
} from './NavV2EducationData'
import NavV2EducationContent from './NavV2EducationContent'
import { useNavContext } from '../../../../gin-and-tonic/containers/nav/utils/NavContext'
import { trackEvent } from '../../../../utils/events/trackEvent'
import { useCurrentAccountContext } from '../../../../exports/utils'

export type NavV2EducationModalProps = {
  onClick: () => void
  onClose: () => void
}

const PAGE_TO_PAGE_NUMBER = {
  PRIMARY: 0,
  SECONDARY: 1,
} as const

type PageNumber = (typeof PAGE_TO_PAGE_NUMBER)[keyof typeof PAGE_TO_PAGE_NUMBER]

const NavV2EducationModal = ({ onClose }: NavV2EducationModalProps) => {
  const accountContext = useCurrentAccountContext()

  useEffect(() => {
    if (accountContext?.account?.id) {
      trackEvent({
        id: 'navV2.education_modal.viewed',
        description: 'Tracks whenever the NavV2Education Modal is viewed',
        data: {
          accountID: accountContext?.account?.id,
        },
      })
    }
  }, [accountContext?.account?.id])

  const { navV2Enabled, setNavV2Enabled, navV2AnnouncementData, setNavV2AnnouncementData } =
    useNavContext()

  // If the modal has ever been seen before, show the secondary page
  const modalData = navV2AnnouncementData?.navV2ModalSeenCount
    ? navV2EducationSecondaryModalData
    : navV2EducationPrimaryModalData

  const [pageNumber, setPageNumber] = useState<PageNumber>(PAGE_TO_PAGE_NUMBER.PRIMARY)
  const pageData = modalData[pageNumber]

  const onClickSecondary = () => {
    setNavV2Enabled(false)
    onClose()
    trackEvent({
      id: 'navV2.education_modal.declined',
      description: 'Tracks whenever a user rejects the new experience',
      data: {
        accountID: accountContext?.account?.id,
      },
    })
  }

  const onClickPrimary = () => {
    if (pageNumber === PAGE_TO_PAGE_NUMBER.PRIMARY) {
      if (!navV2Enabled) {
        setNavV2AnnouncementData({
          ...navV2AnnouncementData,
          navV2ManuallyAccepted: true,
        })
      }
      setNavV2Enabled(true)
      trackEvent({
        id: 'navV2.education_modal.accepted',
        description: 'Tracks whenever a user agrees to the new experience',
        data: {
          accountID: accountContext?.account?.id,
        },
      })
      setPageNumber(PAGE_TO_PAGE_NUMBER.SECONDARY)
    } else {
      onClose()
    }
  }

  return (
    <NavV2EducationContent
      content={pageData}
      onClickSecondary={onClickSecondary}
      onClickPrimary={onClickPrimary}
    />
  )
}

export default NavV2EducationModal
