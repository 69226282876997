import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { SparkleIcon, LogOutIcon, TechnicalIcon, CORE_COLORS } from '@instacart/ids-core'
import { FulfillmentTestingCenterIcon, OpenInIcon, UserIcon } from '@instacart/ids-core'
import { spacing, useBooleanState } from '@retailer-platform/shared-components'
import { AccountManagementMyAccountModal } from '@retailer-platform/domain-account-management'
import { NavItem } from '../NavItem'
import clientEnv from '../../../../../utils/global/clientEnv'
import useAccessControl from '../../../../../legacy/components/AccessControl/useAccessControl'
import { Permission } from '../../../../../legacy/common/types'
import { AdminControlModal } from '../../../admin-controls-modal/AdminControlsModal'
import { useNavContext } from '../../utils/NavContext'
import { useOptionalPartnerContext } from '../../../../../utils/contexts/partner/PartnerContext.hooks'
import { useCurrentAccountDetails } from '../../../../../api/current-account/currentAccount.hooks'
import { trackEvent } from '../../../../../utils/events/trackEvent'

const iconStyle = css({
  marginRight: spacing.X16,
  marginLeft: 'auto',
  width: '18px',
  height: '18px',
})

export const RetailerNavFooterContent: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const hasAccess = useAccessControl()
  const partnerContext = useOptionalPartnerContext()
  const isInternalAdmin = hasAccess({
    permissions: Permission.AdminPanel,
  })
  const [showMyAccountModal, openMyAccountModal, closeMyAccountModal] = useBooleanState(false)
  const [isAdminControlsOpen, openAdminControls, closeAdminControls] = useBooleanState(false)
  const { setNavV2Enabled, closeNav } = useNavContext()

  const { account: currentAccount } = useCurrentAccountDetails()
  const accountHasNavV2 = !!currentAccount?.featureToggles?.find(
    feature => feature.name === 'rt_ia_nav_v2'
  )

  return (
    <>
      {(isInternalAdmin || partnerContext?.hasIaNavV2 || accountHasNavV2) && (
        <NavItem
          isMainItem
          Icon={SparkleIcon}
          title="navV2.userSettings.switchToNavV2"
          onClick={() => {
            setNavV2Enabled(true)
            closeNav()
            trackEvent({
              id: 'navV2.navigation.switchToNavV2',
              description: 'Tracks whenever the user switches to NavV2 from the side navigation',
              data: {
                accountID: currentAccount?.id,
              },
            })
          }}
          linkBaseWrapperStyles={{
            backgroundColor: CORE_COLORS.systemSuccessRegular,
          }}
        />
      )}
      <NavItem
        isMainItem
        Icon={UserIcon}
        title="app.admin.nav.links.myAccount"
        onClick={openMyAccountModal}
      />
      <AccountManagementMyAccountModal isOpen={showMyAccountModal} onClose={closeMyAccountModal} />
      {isInternalAdmin && (
        <>
          <NavItem
            isMainItem
            Icon={TechnicalIcon}
            title="app.admin.nav.links.adminControls"
            onClick={openAdminControls}
          />
          <AdminControlModal isOpen={isAdminControlsOpen} onClose={closeAdminControls} />
        </>
      )}
      <NavItem isMainItem Icon={LogOutIcon} route="logout" title="logout" />
    </>
  )
}
