import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const storeInterventionsAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsView],
}

export const storeInterventionsCreateNewRequestAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsCreateNewRequest],
}
