import { useCallback, useMemo } from 'react'
import uniqBy from 'lodash/uniqBy'
import { mapPartnerData, mapWarehouseData } from '../../warehouse/queries/dataUtils'
import { LocationFeatureEnum, useGetRetailersForPartnerQuery } from '../../../../__codegen__/api'
import { usePartnerId } from '../../../routing/params.hooks'
import { WarehouseLocationFeature } from '../../../../legacy/common/warehouseLocations'
import { type PartnerType } from '../../../../exports/utils'

export const useFetchPartner = id => {
  const {
    data: partnerData,
    error: partnerError,
    loading: partnerLoading,
    refetch: partnerRefetch,
  } = useGetRetailersForPartnerQuery({
    variables: { partnerId: id?.toString() },
    skip: !id,
  })

  const partnerId = usePartnerId()

  const featureToggles = useMemo(() => {
    // For the purposes of the nav bar which no longer knows about individual banners, we need to combine
    // the feature toggles across all banners into a single array. Deciding whether a particular retailer
    // actually has access to a particular tool will then be handled by the retailer selection tool
    const featureTogglesAgg = partnerData?.partner?.retailers?.flatMap(e => e.featureToggles) || []

    return uniqBy(featureTogglesAgg, e => e.name)
  }, [partnerData?.partner?.retailers])

  const featureEntitlements = useMemo(() => {
    const featureEntitlementsAgg =
      partnerData?.partner?.retailers?.flatMap(e => e.featureEntitlements) || []

    return uniqBy(featureEntitlementsAgg, e => e.id)
  }, [partnerData])

  const locationFeatures = useMemo(() => {
    const locationFeaturesAgg =
      partnerData?.partner?.retailers?.flatMap(e => e.locationFeatures) || []

    return [...new Set(locationFeaturesAgg)].map(e => {
      if (e == LocationFeatureEnum.RetailerAlcoholDelivery) {
        return WarehouseLocationFeature.RetailerAlcoholDelivery
      } else {
        return WarehouseLocationFeature.RetailerPickup
      }
    })
  }, [partnerData])

  const partnerName = partnerData?.partner?.name
  const partnerType = partnerData?.partner?.partnerType

  /**
   * Combine the partner into a single unit with its locales + featureToggles + entitlements
   */
  const partner = useMemo(() => {
    if (!partnerData?.partner) {
      return null
    }

    const mappedPartner = mapPartnerData({
      partnerId: partnerId,
      partnerData: partnerData?.partner,
      localesData: null,
      featureEntitlements,
      featureToggles,
    })

    return mappedPartner
  }, [partnerData?.partner, partnerId, featureEntitlements, featureToggles])

  const combinedRefetch = useCallback(() => {
    partnerRefetch()
  }, [partnerRefetch])

  const error = partnerError
  const errorWhileRevalidating = false

  const warehouses = useMemo(
    () =>
      partnerData?.partner?.retailers.map(retailer => {
        const mappedRetailer = mapWarehouseData({
          retailerData: retailer,
          localesData: [],
          locationsData: [],
        })

        const locationFeatures = retailer.locationFeatures?.map(e => {
          if (e == LocationFeatureEnum.RetailerAlcoholDelivery) {
            return WarehouseLocationFeature.RetailerAlcoholDelivery
          } else {
            return WarehouseLocationFeature.RetailerPickup
          }
        })

        return {
          ...mappedRetailer,
          locationFeatures,
          storeConfigurations: [],
        }
      }),
    [partnerData]
  )

  // TODO make "warehouses" look like warehouseContexts

  return {
    partner,
    partnerName,
    partnerType: partnerType as PartnerType,
    warehouses,
    featureToggles,
    featureEntitlements,
    locationFeatures,
    error,
    errorWhileRevalidating,
    loading: partnerLoading,
    refetch: combinedRefetch,
  }
}
