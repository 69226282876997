import React, { type FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import {
  type NotificationsContextValue,
  withNotificationsContext,
} from '../../../../../contexts/notifications/NotificationsContext.ts'
import type { RetailerRouteComponentProps } from '../../../../../components/RetailerRoute.tsx'
import type { ProductFormValues } from '../../../types/product.types.ts'
import type { Item } from '../../../types/items.types.ts'
import FilterBar from '../../../../../components/FilterBar.tsx'
import { colors, Text } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import RegionLocationDropdown from '../../../../../components/RegionLocationDropdown.tsx'
import { type ProductLocationFilters, ProductLocationsFilters } from './ProductLocationsFilters.tsx'
import { Button, LoadingDots, spacing } from '@retailer-platform/shared-components'
import { WarehouseContext } from '../../../../../../utils/contexts/warehouse/WarehouseContext.ts'
import AddProductToLocations from './AddProductToLocations.tsx'
import BulkEditProductLocations from './BulkEditProductLocationsModal.tsx'
// eslint-disable-next-line workspaces/no-relative-imports
import { Container } from '../../../../../../../../../domains/catalog-admin/src/utils/common.styles.tsx'
import instacart from '../../../../../common/instacart.ts'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics.tsx'
import ApiResponseError from '../../../../../common/errors/ApiResponseError.ts'
import { each, eq } from 'lodash'
import styled from '@emotion/styled'
import { CountryId } from '../../../../../common/utils/countries/countries.types.ts'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs.tsx'
import withRetailerRouter from '../../../../../common/withRouter.tsx'
import { withProductLocationsHelper } from './ProductLocationsHelper.tsx'
import {
  ProductLocationsTableColumns,
  ProductLocationsTableColumnSortEnum,
  type ProductLocationsTableColumnSortState,
} from './ProductLocationsTableColumns.tsx'
import { ProductLocationsTableRow } from './ProductLocationsTableRow.tsx'
import { ErrorPage } from '../../../../../../gin-and-tonic/containers/error-page/ErrorPage.tsx'
import { RegionLocationContext } from '../../../../../contexts/regionLocation/RegionLocationContext.ts'

const ProductLocationFilterBar = styled(FilterBar)({
  justifyContent: 'left',
  gap: spacing.X8,
  paddingTop: spacing.X16,
  minHeight: 'auto',
  borderBottom: 'none',
})

const ProductLocationFilterBarItem = styled.div()

const Spacing = styled.div({
  flex: 1,
})

export const countryIdToDomain: { [key in string]: string } = {
  [CountryId.CANADA]: 'https://instacart.ca',
  [CountryId.USA]: 'https://instacart.com',
}

export interface Query {
  item_ids: string
  inventory_area_ids: string
  region_ids: string
}

interface Props extends NotificationsContextValue, RetailerRouteComponentProps<{}, Query> {
  partnerId: string
  warehouseId: string
  productId: string
  product: ProductFormValues
  className?: string
  // All following props come from withProductLocationsHelper
  data?: Item[]
  isLoading?: boolean
  isError?: boolean
  refetch?: () => void
  itemIds?: number[]
}

interface State {
  items: Item[]
  selected: number[]
  currentRowEditIndex: number
  itemDrafts: {
    [index: string]: string
  }
  activeModal: 'addLocations' | 'bulkEditLocations' | null
  filters: ProductLocationFilters
  sortState: ProductLocationsTableColumnSortState
  availabilityByInventoryAreaId: { [index: string]: boolean }
}

const ProductLocations: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  data,
  warehouseId,
  partnerId,
  productId,
  isLoading,
  isError,
  itemIds,
  refetch,
  notify,
  notifyError,
  query,
}) => {
  const { inventoryAreas } = useContext(RegionLocationContext)
  const [state, setState] = useState<State>({
    items: data,
    selected: [],
    currentRowEditIndex: -1,
    itemDrafts: {},
    activeModal: null,
    filters: {
      hasSalePricing: false,
      isAvailable: false,
    },
    availabilityByInventoryAreaId: {},
    sortState: {
      column: 'inventory_area',
      order: ProductLocationsTableColumnSortEnum.ASC,
    },
  })
  const { availabilityByInventoryAreaId, sortState, activeModal, items, selected, filters } = state

  useEffect(() => {
    const availabilityByInventoryAreaId = (data ?? []).reduce((previousValue, currentValue) => {
      const storeName = currentValue?.inventory_area_id
      return {
        ...previousValue,
        [storeName]: true,
      }
    }, {})

    setState(state => ({
      ...state,
      availabilityByInventoryAreaId: availabilityByInventoryAreaId,
    }))
  }, [data])

  useEffect(() => {
    const doFiltering = filters.isAvailable || filters.hasSalePricing

    let newItems = data ?? []
    if (doFiltering) {
      newItems = (data ?? []).filter(data => {
        let isValid = false
        if (filters.isAvailable) isValid = data.available
        if (filters.hasSalePricing) isValid = data.sale_price_active
        return isValid
      })
    }

    if (sortState) {
      newItems = newItems.sort((itemA, itemB) => {
        const attributeA = JSON.stringify(itemA?.[sortState.column] ?? '')
        const attributeB = JSON.stringify(itemB?.[sortState.column] ?? '')

        return sortState.order === ProductLocationsTableColumnSortEnum.ASC
          ? attributeA.localeCompare(attributeB, undefined, { numeric: true })
          : attributeB.localeCompare(attributeA, undefined, { numeric: true })
      })
    }

    setState(state => ({
      ...state,
      items: newItems,
    }))
  }, [data, filters.hasSalePricing, filters.isAvailable, sortState])

  const handleEditRowClick = (currentRowEditIndex: number) => {
    setState(state => ({
      ...state,
      currentRowEditIndex:
        currentRowEditIndex === state.currentRowEditIndex ? -1 : currentRowEditIndex,
      itemDrafts: {},
    }))
  }

  const inventoryAreaMap = useMemo(() => {
    return (inventoryAreas ?? []).reduce((currentMap, inventoryArea) => {
      return {
        [inventoryArea.id]: inventoryArea,
        ...currentMap,
      }
    }, {})
  }, [inventoryAreas])

  const handleEdit = (attr: string, value: string) => {
    setState(state => ({
      ...state,
      itemDrafts: {
        ...state.itemDrafts,
        [attr]: value,
      },
    }))
  }

  const handleModalClose = () => {
    setState(state => ({
      ...state,
      activeModal: null,
    }))
  }

  const handleConfirmEditClick = async () => {
    const editedItem = state.items[state.currentRowEditIndex]
    try {
      const item: Item = await instacart.go.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/items/${editedItem.id}`,
        { data: state.itemDrafts }
      )
      each(state.itemDrafts, (attribute, value) => {
        deprecatedAnalytics.track('product.warehouse_location_change', {
          source_type: attribute,
          source_value: value,
          item_id: item.id,
        })
      })

      notify(<FormattedMessage id="catalog.products.locations.requestSubmitted" />)

      refetch()
    } catch (e) {
      if (e instanceof ApiResponseError && e.errors && e.errors.length) {
        notifyError(<>e.errors[0]</>)
      }
    }
  }

  const onSelectItem = (item_id: number) => {
    setState(state => {
      const selected = [...state.selected]

      if (selected.includes(item_id)) {
        const i = selected.indexOf(item_id)
        selected.splice(i, 1)
      } else {
        selected.push(item_id)
      }

      return { ...state, selected }
    })
  }

  const handleSelectAllClick = () => {
    setState(state => ({
      ...state,
      itemDrafts: {},
      currentRowEditIndex: -1,
      selected: areAllItemsSelected() ? [] : [...itemIds],
    }))
  }

  const areAllItemsSelected = () => {
    if (!itemIds.length) return false

    return eq(itemIds.sort().toString(), selected.sort().toString())
  }

  const handleBulkEditClick = () => {
    setState(state => ({
      ...state,
      activeModal: 'bulkEditLocations',
    }))
  }

  const handleAddLocationClick = () => {
    setState({
      ...state,
      activeModal: 'addLocations',
    })
  }

  // If we have too many locations selected, then the request might time out
  // In this case, we want to prompt them to select a specific location.
  // If they have a specific location selected and they still get an error, we just
  // want to show them the default error message.
  const customErrorMessage =
    isError && !query.region_ids && !query.inventory_area_ids
      ? 'Too many locations selected. Please select a specific region or location and try again.'
      : undefined

  return (
    <Container className={className}>
      <ProductLocationFilterBar>
        <ProductLocationFilterBarItem>
          <RegionLocationDropdown
            isDisabled={selected.length > 0}
            useInventoryAreas
            availabilityByInventoryAreaId={availabilityByInventoryAreaId}
          />
        </ProductLocationFilterBarItem>
        <ProductLocationFilterBarItem>
          <ProductLocationsFilters
            filters={state.filters}
            onChange={filters => setState({ ...state, filters })}
          />
        </ProductLocationFilterBarItem>
        <ProductLocationFilterBarItem>
          {selected.length > 0 && (
            <Text variant="T.16" style={{ color: colors.GREEN_500 }}>
              <FormattedMessage
                id="catalog.products.locations.items.selected"
                values={{ count: selected.length }}
              />
            </Text>
          )}
        </ProductLocationFilterBarItem>
        <Spacing />
        <ProductLocationFilterBarItem>
          {!!selected.length && (
            <Button onClick={handleBulkEditClick}>
              <FormattedMessage
                id="catalog.products.locations.bulk-edit"
                values={{ count: selected.length }}
              />
            </Button>
          )}
          {!selected.length && (
            <Button onClick={handleAddLocationClick}>
              <FormattedMessage id="catalog.products.locations.add-locations" />
            </Button>
          )}
        </ProductLocationFilterBarItem>
      </ProductLocationFilterBar>

      <WarehouseContext.Consumer>
        {warehouseContext => {
          if (!warehouseContext) {
            throw new Error('WarehouseContext not provided')
          }
          if (isLoading) return <LoadingDots height={24} centered />
          if (isError) return <ErrorPage>{customErrorMessage}</ErrorPage>

          return (
            <div style={{ overflow: 'auto', width: '100%' }}>
              <table style={{ width: '100%', border: `none` }}>
                <thead>
                  <tr>
                    <ProductLocationsTableColumns
                      areAllItemsSelected={areAllItemsSelected}
                      handleSelectAllClick={handleSelectAllClick}
                      sortState={sortState}
                      setSortState={sortState => setState({ ...state, sortState: sortState })}
                    />
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <ProductLocationsTableRow
                      key={item.id}
                      inventoryAreaMap={inventoryAreaMap}
                      item={item}
                      index={index}
                      country={warehouseContext?.warehouse?.country}
                      handleConfirmEditClick={handleConfirmEditClick}
                      handleEditRowClick={handleEditRowClick}
                      handleEdit={handleEdit}
                      onSelectItem={onSelectItem}
                      selected={state.selected}
                      currentRowEditIndex={state.currentRowEditIndex}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )
        }}
      </WarehouseContext.Consumer>
      {activeModal === 'addLocations' ? (
        <AddProductToLocations
          partnerId={partnerId}
          warehouseId={warehouseId}
          productId={productId}
          onClose={handleModalClose}
        />
      ) : activeModal === 'bulkEditLocations' ? (
        <BulkEditProductLocations
          partnerId={partnerId}
          warehouseId={warehouseId}
          productId={productId}
          itemIds={selected}
          onClose={handleModalClose}
        />
      ) : null}
    </Container>
  )
}

export default withTrackEventOnMount({
  id: 'catalog.products.store_locations.viewed',
  description: 'Viewed the store locations page for a specific product',
})(withRetailerRouter(withNotificationsContext(withProductLocationsHelper(ProductLocations))))
