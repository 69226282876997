import { type Styles } from 'react-modal'
import React, { useMemo, useState } from 'react'
import { Modal, ModalContainer, type ModalProps } from '@retailer-platform/shared-components'
import { MemoryRouter } from 'react-router'
import { useLocalForageKey } from '../../../utils/local-storage/useLocalForageKey.hooks'
import { useRetailerId, usePartnerId } from '../../../utils/routing/params.hooks'
import { type Pages as ToolEffectivnessPages } from './tool-effectiveness/ToolEffectivenessController'
import { type NavV2OptOutSurveyPages } from './nav-v2/NavV2OptOutSurveyController'
import { SurveyContextProvider } from './RetailerSatisfactionSurveyContext'

type Pages = ToolEffectivnessPages | NavV2OptOutSurveyPages

type RetailerSatisfactionSurveyProps = {
  children: JSX.Element[] | JSX.Element
  initialPage: Pages
  milestoneSlug: string
  onSurveyCompleted: () => {}
  toolProps: {}
  surveyData: [{}, (x: {}) => void]
  alwaysShow: boolean
  /** Overrides default wrapper ReactModal styles */
  modalStylesOverride?: Styles
  hideCloseButton?: boolean
  onClose?: () => void
  /** Props directly passed to the ReactModal */
  modalProps?: Omit<ModalProps, 'isOpen'>
}

export interface SurveyState {
  currentPage: Pages
  shownAt: string
}

export type RetailerSatisfactionSurveyLocalStorage = {
  lastShown: number
}

const oneMonthSeconds = 86400 * 30

export const RetailerSatisfactionSurveyModal = (props: RetailerSatisfactionSurveyProps) => {
  const [showModal, setShowModal] = useState(true)

  /**
   * Retailer and/or partner Id may not be available
   * in all places survey's are triggered from. In both cases
   * default both of these to 0
   */
  const retailerId = useRetailerId({ throwOnMissing: false }) ?? '0'
  const partnerId = usePartnerId({ throwOnMissing: false }) ?? '0'

  const {
    data: rssLocaleStorage,
    setData: setLastShown,
    loading: rssLocaleStorageLoading,
  } = useLocalForageKey<RetailerSatisfactionSurveyLocalStorage>(
    'rssLocalStorage:' + props.milestoneSlug
  )

  const atLeast30DaysSinceShown = useMemo(
    () =>
      props.alwaysShow ||
      (!rssLocaleStorageLoading &&
        Date.now() / 1000 - (rssLocaleStorage?.lastShown || 0) > oneMonthSeconds),
    [props.alwaysShow, rssLocaleStorage?.lastShown, rssLocaleStorageLoading]
  )

  return (
    atLeast30DaysSinceShown && (
      <Modal
        onRequestClose={() => {
          setLastShown({ lastShown: Date.now() / 1000 })
          setShowModal(false)
          props.onClose?.()
        }}
        isOpen={showModal}
        position={'top-center'}
        closeTimeoutMS={200}
        maxWidth={552}
        styleOverrides={props.modalStylesOverride}
        hideCloseButton={props.hideCloseButton}
        {...props.modalProps}
      >
        <ModalContainer key="container" data-testid="retailer-satisfaction-survey-modal-container">
          <MemoryRouter initialEntries={[props.initialPage]}>
            <SurveyContextProvider
              toolProps={props.toolProps}
              surveyData={props.surveyData}
              milestoneSlug={props.milestoneSlug}
              onSurveyCompleted={props.onSurveyCompleted}
              retailerId={retailerId}
              partnerId={partnerId}
            >
              {props.children}
            </SurveyContextProvider>
          </MemoryRouter>
        </ModalContainer>
      </Modal>
    )
  )
}
