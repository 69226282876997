import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  retailerFundedMarketingAdminAccessControl,
  retailerFundedMarketingAdminCustomCampaignAccessControl,
} from '../access-control/retailerFundedMarketingAdminAccess.configuration'

const CampaignsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignsListPage" */
      '../pages/admin-campaigns/CampaignsListPage'
    )
)

const CampaignsTemplatesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignsTemplatesPage" */
      '../pages/admin-campaigns/CampaignsTemplatesPage'
    )
)

const CampaignsCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignsCreatePage" */
      '../pages/admin-campaigns/CampaignsCreatePage'
    )
)

const CampaignsGetPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignsGetPage" */
      '../pages/admin-campaigns/CampaignsGetPage'
    )
)

const CampaignsEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignsEditPage" */
      '../pages/admin-campaigns/CampaignsEditPage'
    )
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const campaignsAdminRouter = (
  <DomainRoute
    route="app-admin-campaigns"
    accessControl={retailerFundedMarketingAdminCustomCampaignAccessControl}
  >
    <DashSwitch>
      <DomainRoute exact route="app-admin-campaigns-list" component={CampaignsListPage} />
      <DomainRoute exact route="app-admin-campaigns-templates" component={CampaignsTemplatesPage} />
      <DomainRoute exact route="app-admin-campaigns-create" component={CampaignsCreatePage} />
      <DomainRoute exact route="app-admin-campaigns-get" component={CampaignsGetPage} />
      <DomainRoute exact route="app-admin-campaigns-edit" component={CampaignsEditPage} />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
