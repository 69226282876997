import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const scanAndPayPublicRouter = (
  <DomainRoute route="app-public-scan-and-pay">
    <DashSwitch>
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
