import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { RetailerSatisfactionSurveyModal } from '../RetailerSatisfactionSurveyModal'
import { RetailerSatisfactionSurveyStep } from '../RetailerSatisfactionSurveyStep'
import { useNavContext } from '../../../containers/nav/utils/NavContext'
import NavV2OptOutSurveyContent from './NavV2OptOutSurveyContent'

export enum NavV2OptOutSurveyPages {
  Page1 = 'page1',
}

export interface NavV2OptOutSurveyControllerProps {
  toolName: string
  surveySlug: string
  alwaysShow: boolean
}

export interface NavV2OptOutSurveyState {
  navV2OptOutFeedbackReasons: string[]
  navV2OptOutOptionalFeedback: string
}

const defaultState = {
  navV2OptOutFeedbackReasons: [],
  navV2OptOutOptionalFeedback: '',
}

export const NavV2OptOutSurveyController = ({
  alwaysShow,
  surveySlug,
  toolName,
}: NavV2OptOutSurveyControllerProps) => {
  const intl = useIntl()
  const [surveyData, setSurveyData] = useState<NavV2OptOutSurveyState>(defaultState)
  const { isNavV2OptOutSurveyOpen, setIsNavV2OptOutSurveyOpen } = useNavContext()

  /**
   * Incase the onClose callback to RetailerSatisfactionSurveyModal is not triggered
   * we should still manually clear the modal state when component unmounts
   * This might occur if a user uses their browser back button to navigate away from the modal
   */
  useEffect(
    () => () => {
      if (isNavV2OptOutSurveyOpen) {
        setIsNavV2OptOutSurveyOpen(false)
      }
    },
    [isNavV2OptOutSurveyOpen, setIsNavV2OptOutSurveyOpen]
  )

  const toolProps = {
    toolName,
  }

  const onSubmitSurveyData = () => {
    const formattedResponses = surveyData.navV2OptOutFeedbackReasons.map(reason =>
      intl.formatMessage({ id: reason })
    )

    return {
      ...surveyData,
      navV2OptOutFeedbackReasons: formattedResponses,
    }
  }

  return (
    <RetailerSatisfactionSurveyModal
      initialPage={NavV2OptOutSurveyPages.Page1}
      milestoneSlug={surveySlug}
      onSurveyCompleted={onSubmitSurveyData}
      alwaysShow={alwaysShow}
      toolProps={toolProps}
      surveyData={[surveyData, setSurveyData]}
      modalStylesOverride={{
        content: {
          maxHeight: 'auto',
        },
      }}
      modalProps={{
        shouldCloseOnOverlayClick: false,
        shouldCloseOnEsc: false,
      }}
      hideCloseButton
      onClose={() => setIsNavV2OptOutSurveyOpen(false)}
    >
      <RetailerSatisfactionSurveyStep
        name={NavV2OptOutSurveyPages.Page1}
        component={NavV2OptOutSurveyContent}
      />
    </RetailerSatisfactionSurveyModal>
  )
}
