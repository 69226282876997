import { SFXVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const retailerFundedMarketingAccessControl: DomainAccessControlConfig = [
  {
    permissions: [
      Permission.RetailerFundedMarketingViewAccess,
      Permission.RetailerFundedMarketingModifyAccess,
    ],
  },
]

export const dynamicCsvBucketAccessControl: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.useDynamicCsvUploadBucket],
    minSfxVersion: SFXVersion.r2024_08_0,
  },
  {
    warehouseFeatureToggles: [FeatureToggle.useDynamicCsvUploadBucketRetailer],
  },
]
