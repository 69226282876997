/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { ApolloError } from 'apollo-client'
import { onError, type ErrorResponse } from 'apollo-link-error'
import { isUndeterminedAccountError } from '../errors/isError.hooks'
import { logout, LogoutReason } from '../../../../gin-and-tonic/containers/log-out/utils/logout'
import { type FetchCustomApolloClient } from './apolloClient.types'

interface ApolloErrorLinkHandlerProps extends Partial<ErrorResponse> {}

export const makeApolloErrorLinkHandler =
  (getClient: FetchCustomApolloClient) =>
  ({ graphQLErrors, networkError }: ApolloErrorLinkHandlerProps) => {
    if (networkError) {
      console.warn(`[Network error]: ${networkError.message}`, networkError)
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(err => console.warn(`[GraphQL error]: ${err.message}`, err))
    }

    const apolloError = new ApolloError({ graphQLErrors })

    if (isUndeterminedAccountError(apolloError)) {
      logout(LogoutReason.TokenExpired)
    }
  }

export const makeApolloErrorLink = (getClient: FetchCustomApolloClient) =>
  onError(makeApolloErrorLinkHandler(getClient))
