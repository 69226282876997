import { useNavContext } from '../../../../gin-and-tonic/containers/nav/utils/NavContext'

export const MAX_MODAL_FREQUENCY_COUNT = 3
export const MODAL_FREQUENCY_DISPLAY_MILLISECONDS = 24 * 60 * 60 * 1000

export const useNavV2EducationModalAccess = () => {
  const { navV2Enabled, navV2AnnouncementData, navV2RouletteEnabled } = useNavContext()

  const shouldOpenModal = () => {
    /**
     * If navV2 is enabled, we only check if the modal has been seen before
     * navV2ManuallyAccepted is only set to true when the user accept's to switch
     * from the old nav to the new nav
     */
    if (navV2Enabled) {
      return (
        !navV2AnnouncementData?.navV2ModalSeenCount || navV2AnnouncementData?.navV2ManuallyAccepted
      )
    }

    if (!navV2RouletteEnabled || navV2AnnouncementData?.navV2ManuallyOptedOut) {
      return false
    }

    if (
      navV2AnnouncementData?.navV2ModalSeenCount &&
      navV2AnnouncementData?.navV2ModalSeenCount >= MAX_MODAL_FREQUENCY_COUNT
    ) {
      return false
    }

    if (navV2AnnouncementData?.navV2ModalLastSeenTime) {
      const lastTriggeredTime = navV2AnnouncementData.navV2ModalLastSeenTime
      const currentTime = Date.now()

      return (
        isNaN(lastTriggeredTime) ||
        currentTime - lastTriggeredTime >= MODAL_FREQUENCY_DISPLAY_MILLISECONDS
      )
    }

    return true
  }

  return shouldOpenModal
}

export const useNavV2OnClose = () => {
  const { navV2AnnouncementData, setNavV2AnnouncementData } = useNavContext()

  const onClose = () => {
    const newSeenCount = (navV2AnnouncementData?.navV2ModalSeenCount ?? 0) + 1
    const newLastSeenTime = new Date().getTime()

    setNavV2AnnouncementData({
      ...navV2AnnouncementData,
      navV2ModalSeenCount: newSeenCount,
      navV2ModalLastSeenTime: newLastSeenTime,
      navV2ManuallyAccepted: null,
    })
  }

  return onClose
}
