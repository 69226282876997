import React, { type FunctionComponent } from 'react'
import {
  PublicLayout,
  PublicCard,
  Button,
  CheckboxWithLabel,
  Tooltip,
  notify,
  NotificationLevel,
} from '@retailer-platform/shared-components'
import { routes } from '@retailer-platform/domain-instacart-developer-platform'
import { url } from '../../../../utils/parsing/url'
import { DashRedirect } from '../../../../legacy/dash-components/dash-redirect/DashRedirect'
import { useAcceptPartnerAgreementMutation } from '../../../../__codegen__/api'
import { useDashMessages } from '../../../../intl/intl.hooks'
import { usePartnerContext } from '../../../../exports/routing'
import { useFetchCurrentEnterpriseAccountQuery } from '../../../../__codegen__/api'
import { Terms } from './Terms'

export const HaltTerms: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const [agreedToTerms, setAgreedToTerms] = React.useState<boolean>(false)
  const [triggerMutation] = useAcceptPartnerAgreementMutation()
  const partnerContext = usePartnerContext()
  const { data, refetch } = useFetchCurrentEnterpriseAccountQuery()

  const messages = useDashMessages({
    title: 'app.halt.terms.title',
    agree: 'app.halt.terms.agree',
    continue: 'app.halt.terms.continue',
    error: 'app.halt.terms.error',
  })

  const handleCheck = () => {
    setAgreedToTerms(!agreedToTerms)
  }

  const handleSubmit = async () => {
    if (agreedToTerms) {
      const result = await triggerMutation({ variables: { partnerId: partnerContext.partnerId } })

      if (
        result?.data?.enterpriseAccountAcceptPartnerAgreement?.acceptedPartnerAgreement?.partnerId
      ) {
        // refetch the current enterprise account details
        refetch()
      }

      if (result?.errors) {
        notify({
          level: NotificationLevel.Error,
          body: messages.error,
        })
      }
    }
  }

  if (data.currentEnterpriseAccount?.acceptedPartnerAgreements.length > 0) {
    return (
      <DashRedirect
        to={url(routes['instacart-developer-platform-get-started'], {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          partner_id: partnerContext.partnerId,
        })}
      />
    )
  }

  return (
    <PublicLayout>
      <PublicCard>
        <div>
          <PublicCard.Title>{messages.title}</PublicCard.Title>
          <Terms />
        </div>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <CheckboxWithLabel
            label={messages.agree}
            id="app.halt.terms.agree"
            onChange={() => handleCheck()}
            checked={agreedToTerms}
            data-testid="halt-terms-checkbox"
          />
          <Button
            disabled={!agreedToTerms}
            onClick={() => handleSubmit()}
            data-testid="halt-terms-button"
          >
            {messages.continue}
          </Button>
        </div>
      </PublicCard>
    </PublicLayout>
  )
}
