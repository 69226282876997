export const EN_US = {
  'partnerOnboarding.register.documentTitle': 'Join Instacart',
  'partnerOnboarding.register.submit': 'Submit',
  'partnerOnboarding.register.enterYourInfo': 'Enter Your Information',
  'partnerOnboarding.register.retailerName': 'Retailer Name',
  'partnerOnboarding.register.retailerName.infoTip': 'The legal name of your business',
  'partnerOnboarding.register.email': 'Email address',
  'partnerOnboarding.register.email.infoTip': 'Enter an email address that you can be contacted at',
  'partnerOnboarding.register.password': 'Password',
  'partnerOnboarding.register.password.infoTip': 'This password was provided to you by the partner',
  'partnerOnboarding.register.firstName': 'First Name',
  'partnerOnboarding.register.lastName': 'Last Name',
  'partnerOnboarding.register.validation.invalidEmail': 'Invalid email address',
  'partnerOnboarding.register.validation.required': '{fieldName} cannot be blank.',
  'partnerOnboarding.register.validation.invalidPattern': 'Invalid pattern for {fieldName}',
  'partnerOnboarding.register.validation.password.wrongPassword': 'Wrong password',
  'partnerOnboarding.register.validation.entityName.alreadyExists':
    'Retailer name and email already exist for this partner',
  'partnerOnboarding.register.genericErrorMessage':
    'An error has occurred. Please try again or contact Instacart support.',

  'partnerOnboarding.signUp.growYourBusiness':
    'Grow your business by reaching new customers on Instacart, the largest online grocery marketplace in North America.',
  'partnerOnboarding.signUp.trustedByRetailers':
    'Trusted by over 1,400 retail banners to help grow their business across 80,000+ retail locations.',
  'partnerOnboarding.signUp.servingNinetyFivePercent':
    'Serving 95% of households in the U.S. and Canada.',
  'partnerOnboarding.signUp.networkOfShoppers':
    'Instacart has a network of approximately 600,000 shoppers across North America.',
  'partnerOnboarding.signUp.opportunitiesToGrow': 'Opportunities to grow your business',
  'partnerOnboarding.signUp.opportunitiesToGrowSubHeader':
    'With your storefront on Instacart App, you have the opportunity to generate incremental revenue and customers by enabling:',
  'partnerOnboarding.signUp.alcohol': 'Alcohol',
  'partnerOnboarding.signUp.pickUp': 'Pick-up',
  'partnerOnboarding.signUp.snapEBT': 'SNAP/EBT payments',
  'partnerOnboarding.signUp.marketingTools': 'Marketing campaign tools',
  'partnerOnboarding.signUp.onboardingProcess': 'Onboarding Process',
  'partnerOnboarding.signUp.onboardingProcessSubtext':
    'Instacart’s Onboarding Team can have your storefront up and running in as little as 4 weeks.',
  'partnerOnboarding.signUp.formReadyHeader': 'Ready to get started with Instacart?',
  'partnerOnboarding.signUp.formReadySubheader':
    'Please provide the following information. Once submitted, you will be presented with a Mutual Non-Disclosure Agreement. Upon signing, we can share the IC App (Marketplace) preferred rates with you.',
  'partnerOnboarding.signUp.growOnlineSales': 'Incrementally grow your online sales',
  'partnerOnboarding.signUp.sameDayFullService': 'Offer <1 hour same-day full-service delivery',
  'partnerOnboarding.signUp.runningInFourWeeks': 'Get up and running in as little as 4 weeks',
  'partnerOnboarding.signUp.higherLTV':
    'more spend of an omnichannel shopper vs in-store customers',
  'partnerOnboarding.signUp.orderTwoHoursOrLess': 'of shoppers want their order in 2 hours or less',
  'partnerOnboarding.signUp.futureOnlineGrocerySales':
    'of all grocery will be online sales over time',
  'partnerOnboarding.signUp.whyInstacartHeader': 'Why Instacart?',
  'partnerOnboarding.signUp.documentTitle': 'Partner Sign Up',
  'partnerOnboarding.signUp.retailerInfo': 'Retailer Information',
  'partnerOnboarding.signUp.contactInfo': 'Contact Information',
  'partnerOnboarding.signUp.billingAddress': 'Business Address',
  'partnerOnboarding.signUp.storeLocation': 'Store Location',
  'partnerOnboarding.signUp.retailerName': 'Legal Business Entity Name',
  'partnerOnboarding.signUp.storeCount': 'Store Count',
  'partnerOnboarding.signUp.posSystemName': 'POS System Name',
  'partnerOnboarding.signUp.posSystemName.infoTip':
    'Please enter the name of the POS system used in your stores.',
  'partnerOnboarding.signUp.corporationType': 'Entity Type',
  'partnerOnboarding.signUp.corporationType.infoTip':
    'Please provide the legal form type (e.g., Corporation, LLC)',
  'partnerOnboarding.signUp.website': 'Website',
  'partnerOnboarding.signUp.duns': 'DUNS number',
  'partnerOnboarding.signUp.duns.infoTip':
    'Please provide the DUNS Number as a unique identifier for your business.',
  'partnerOnboarding.signUp.w9': 'Form W-9',
  'partnerOnboarding.signUp.w9.infoTip':
    'Please upload Form W-9 in PDF format. The maximum file size is 5MB.',
  'partnerOnboarding.signUp.w9.uploadError':
    'An error has occurred during w9 upload. Please try again or contact Instacart support.',
  'partnerOnboarding.signUp.w9.uploaded.infoTip':
    'Form W-9 has been uploaded successfully. Selecting a different file will replace the previous one.',
  'partnerOnboarding.signUp.rawW9Form': 'Form W-9',
  'partnerOnboarding.signUp.w9.uploaded': 'Form W-9 has been uploaded',
  'partnerOnboarding.signUp.w9.missing': 'Form W-9 has not been uploaded',
  'partnerOnboarding.signUp.stateOfIncorporation.label': 'State of Incorporation',
  'partnerOnboarding.signUp.stateOfIncorporation.infoTip':
    'If the entity is a corporation, enter the State in which it is incorporated. Select "Not Incorporated" if not a corporation.',
  'partnerOnboarding.signUp.stateOfIncorporation.defaultOptionLabel': 'Not Incorporated',
  'partnerOnboarding.signUp.selectstateOfIncorporation': 'Select State of Incorporation',
  'partnerOnboarding.signUp.selectExclusivity.label': 'Exclusivity',
  'partnerOnboarding.signUp.selectExclusivity.infoTip':
    'Select whether Merchant agrees to be an Exclusive or Non-Exclusive provider above. If Exclusive is selected, Merchant agrees that, during the Term, Instacart will be Merchant’s exclusive partner for pickup, deliveries, and online and mobile product sales.',
  'partnerOnboarding.signUp.selectExclusivity.placeholder': 'Exclusive/Non-Exclusive',
  'partnerOnboarding.signUp.title': 'Job title',
  'partnerOnboarding.signUp.firstName': 'First name',
  'partnerOnboarding.signUp.lastName': 'Last name',
  'partnerOnboarding.signUp.email': 'Email',
  'partnerOnboarding.signUp.phoneNumber': 'Phone number',
  'partnerOnboarding.signUp.address': 'Address',
  'partnerOnboarding.signUp.streetAddress': 'Street address',
  'partnerOnboarding.signUp.apartmentNumber': 'Apartment number',
  'partnerOnboarding.signUp.city': 'City',
  'partnerOnboarding.signUp.state': 'State',
  'partnerOnboarding.signUp.selectState': 'Select state',
  'partnerOnboarding.signUp.postalCode': 'Postal code',
  'partnerOnboarding.signUp.country': 'Country',
  'partnerOnboarding.signUp.selectCountry': 'Select country',
  'partnerOnboarding.signUp.previous': 'Previous',
  'partnerOnboarding.signUp.continue': 'Continue',
  'partnerOnboarding.signUp.agree': 'Agree',
  'partnerOnboarding.signUp.submit': 'Submit',
  'partnerOnboarding.signUp.validation.required': '{fieldName} cannot be blank.',
  'partnerOnboarding.signUp.validation.invalidEmail': 'Invalid email address',
  'partnerOnboarding.signUp.validation.invalidPhoneNumber': 'Invalid phone number',
  'partnerOnboarding.signUp.validation.invalidDunsNumber': 'DUNS Number must be 9 digits',
  'partnerOnboarding.signUp.validation.invalidPostalCode': 'Invalid postal code',
  'partnerOnboarding.signUp.validation.invalidPattern': 'Invalid pattern for {fieldName}',
  'partnerOnboarding.signUp.validation.invalidFileType': 'Invalid file type',
  'partnerOnboarding.signUp.validation.sizeExceedsLimit': 'File size exceeds maximum limit of 5MB',
  'partnerOnboarding.signUp.completion.message':
    'Thank you! Please allow Instacart up to 2-3 business days to follow up with next steps.',
  'partnerOnboarding.signUp.errorMessage':
    'An error has occurred. Please try again or contact Instacart support.',
  'partnerOnboarding.signUp.summary.fullName': 'Name',
  'partnerOnboarding.signUp.NDA': 'Non-Disclosure Agreement',
  'partnerOnboarding.signUp.ISA': 'Instacart Service Agreement',
  'partnerOnboarding.signUp.checkbox.tooltip': 'Please read through the entire agreement',
  'partnerOnboarding.signUp.NDA.confirmation':
    'I have the authority to bind my company by checking this box, and, in doing so, my company agrees to be bound by the terms of the NDA. Executed version will be sent after Instacart review process.',
  'partnerOnboarding.signUp.ISA.confirmation':
    "Merchant authorizes Instacart to conduct a credit check of Merchant. Merchant acknowledges and agrees that this ISA is conditioned upon Instacart's acceptance of this ISA based on a satisfactory credit check and other considerations, in Instacart's sole discretion. Merchant further understands that this ISA shall not be effective unless and until Merchant receives an executed copy of this agreement from Instacart. Merchant agrees that the information provided in this ISA by Merchant is accurate and Merchant shall assume sole liability in connection with information provided by Merchant in this ISA.\n" +
    '\n' +
    'I have the authority to sign this ISA on behalf of Merchant and bind Merchant to it. By checking this box, Merchant agrees to be bound by the terms of the ISA upon completion of a successful credit check and receipt of this fully executed ISA from Instacart.',
  'partnerOnboarding.signUp.ISA.confirmationNoCreditCheck':
    'I have the authority to sign this ISA on behalf of Merchant and bind Merchant to it. By checking this box, Merchant agrees to be bound by the terms of the ISA upon receipt of a fully executed ISA from Instacart.',
  'partnerOnboarding.signUp.storeLocation.info':
    'For reporting purposes, please identify one store to input if there are multiple locations. As we move forward in the onboarding process, we will input all relevant store locations.',
  'partnerOnboarding.signUp.footNotes.Omnichannel':
    'Source: McKinsey, Navigating the market headwinds: The state of grocery retail 2022',
  'partnerOnboarding.signUp.footNotes.digitalMaturityBenchmark':
    'Source: Incisiv’s 2022 Digital Maturity Benchmark',
  'partnerOnboarding.signUp.footNotes.mcKinseyGroceryStudy':
    'McKinsey "next horizon for grocery e-commerce" study published April 2022',
  'partnerOnboarding.register.getStarted': 'Get started',
  'partnerOnboarding.exclusivity.exclusive': 'Exclusive',
  'partnerOnboarding.exclusivity.non-exclusive': 'Non-Exclusive',
  'partnerOnboarding.states.alabama': 'Alabama',
  'partnerOnboarding.states.alaska': 'Alaska',
  'partnerOnboarding.states.arizona': 'Arizona',
  'partnerOnboarding.states.arkansas': 'Arkansas',
  'partnerOnboarding.states.california': 'California',
  'partnerOnboarding.states.colorado': 'Colorado',
  'partnerOnboarding.states.connecticut': 'Connecticut',
  'partnerOnboarding.states.delaware': 'Delaware',
  'partnerOnboarding.states.districtOfColumbia': 'District Of Columbia',
  'partnerOnboarding.states.florida': 'Florida',
  'partnerOnboarding.states.georgia': 'Georgia',
  'partnerOnboarding.states.guam': 'Guam',
  'partnerOnboarding.states.hawaii': 'Hawaii',
  'partnerOnboarding.states.idaho': 'Idaho',
  'partnerOnboarding.states.illinois': 'Illinois',
  'partnerOnboarding.states.indiana': 'Indiana',
  'partnerOnboarding.states.iowa': 'Iowa',
  'partnerOnboarding.states.kansas': 'Kansas',
  'partnerOnboarding.states.kentucky': 'Kentucky',
  'partnerOnboarding.states.louisiana': 'Louisiana',
  'partnerOnboarding.states.maine': 'Maine',
  'partnerOnboarding.states.maryland': 'Maryland',
  'partnerOnboarding.states.massachusetts': 'Massachusetts',
  'partnerOnboarding.states.michigan': 'Michigan',
  'partnerOnboarding.states.minnesota': 'Minnesota',
  'partnerOnboarding.states.mississippi': 'Mississippi',
  'partnerOnboarding.states.missouri': 'Missouri',
  'partnerOnboarding.states.montana': 'Montana',
  'partnerOnboarding.states.nebraska': 'Nebraska',
  'partnerOnboarding.states.nevada': 'Nevada',
  'partnerOnboarding.states.newHampshire': 'New Hampshire',
  'partnerOnboarding.states.newJersey': 'New Jersey',
  'partnerOnboarding.states.newMexico': 'New Mexico',
  'partnerOnboarding.states.newYork': 'New York',
  'partnerOnboarding.states.northCarolina': 'North Carolina',
  'partnerOnboarding.states.northDakota': 'North Dakota',
  'partnerOnboarding.states.ohio': 'Ohio',
  'partnerOnboarding.states.oklahoma': 'Oklahoma',
  'partnerOnboarding.states.oregon': 'Oregon',
  'partnerOnboarding.states.pennsylvania': 'Pennsylvania',
  'partnerOnboarding.states.rhodeIsland': 'Rhode Island',
  'partnerOnboarding.states.southCarolina': 'South Carolina',
  'partnerOnboarding.states.southDakota': 'South Dakota',
  'partnerOnboarding.states.tennessee': 'Tennessee',
  'partnerOnboarding.states.texas': 'Texas',
  'partnerOnboarding.states.utah': 'Utah',
  'partnerOnboarding.states.vermont': 'Vermont',
  'partnerOnboarding.states.virginia': 'Virginia',
  'partnerOnboarding.states.washington': 'Washington',
  'partnerOnboarding.states.westVirginia': 'West Virginia',
  'partnerOnboarding.states.wisconsin': 'Wisconsin',
  'partnerOnboarding.states.wyoming': 'Wyoming',
} as const

export default EN_US
