import React, { type FunctionComponent, useMemo } from 'react'
import { LoadingGenericBase } from '@instacart/ids-core'
import {
  Alert,
  Center,
  type GridColumns,
  spacing,
  Text,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { ExpandableGrid } from '@retailer-platform/shared-components'
import { useWarehouseContext } from '@retailer-platform/dashboard/utils'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { type HolidayStatus } from '../../../../api'
import { StoreHoursBulkHolidaysHolidayNameCell } from '../holiday-name-cell/StoreHoursBulkHolidaysHolidayNameCell'
import { StoreHoursBulkHolidaysDateCell } from '../date-cell/StoreHoursBulkHolidaysDateCell'
import { apiDateToMoment } from '../../../../utils/useApiToFormDataMapping.hooks'
import { useRetailerHolidayStatuses } from '../../../../utils/useRetailerHolidaysStoreHoursStatuses'

interface StoreHoursBulkHolidaysListProps {}

const GridContainer = styled.div({
  height: '100%',
  width: '100%',
  paddingLeft: spacing.X12,
  paddingRight: spacing.X12,
})

export const StoreHoursBulkHolidaysList: FunctionComponent<
  React.PropsWithChildren<StoreHoursBulkHolidaysListProps>
> = () => {
  const gridColumns = useGridColumns()
  const warehouseContext = useWarehouseContext()
  const { warehouse } = useWarehouseContext()
  const i18n = useDomainMessages(
    {
      errorFetching: 'storeHoursBulkDomain.holidays.list.errorFetching',
      emptyHolidayList: 'storeHoursBulkDomain.holidays.list.emptyHolidayList',
    },
    { countryName: warehouse?.country?.name, retailerName: warehouseContext.partnerName }
  )

  const { holidayStatuses, isLoading, error } = useRetailerHolidayStatuses()

  if (error) {
    return (
      <Center>
        <Alert variant="danger">{i18n.errorFetching}</Alert>
      </Center>
    )
  }

  if (isLoading) {
    return (
      <Center>
        <LoadingGenericBase />
      </Center>
    )
  }

  return (
    <>
      <GridContainer>
        <ExpandableGrid
          css={{ height: '100%' }}
          data={holidayStatuses || []} // Grid doesn't like null or undefined data
          columns={gridColumns}
          tableOptions={{
            initialState: {
              sortBy: [
                {
                  id: 'date',
                  desc: false,
                },
              ],
            },
          }}
          emptyStateMessage={i18n.emptyHolidayList}
          emptyStateShowHeader={false}
          isLoading={isLoading}
        />
      </GridContainer>
    </>
  )
}

export const useGridColumns = () => {
  const i18n = useDomainMessages({
    holiday: 'storeHoursBulkDomain.holidays.holiday',
    date: 'storeHoursBulkDomain.holidays.date',
    confirmedStores: 'storeHoursBulkDomain.holidays.list.confirmedStores',
    unconfirmedStores: 'storeHoursBulkDomain.holidays.list.unconfirmedStores',
  })

  return useMemo(() => {
    const gridColumns: GridColumns<HolidayStatus> = [
      {
        id: 'holidayName',
        Header: i18n.holiday,
        Cell: ({ row }) => <StoreHoursBulkHolidaysHolidayNameCell holiday={row.original.holiday} />,
        minWidth: 150,
        accessor: ({ holiday }) => holiday.holidaySlug || '?', // for sorting a function accessor is needed
        isSortable: true,
        sortType: 'string',
      },
      {
        id: 'date',
        Header: i18n.date,
        Cell: ({ row }) => <StoreHoursBulkHolidaysDateCell date={row.original.holiday.date} />,
        minWidth: 150,
        accessor: ({ holiday }) => apiDateToMoment(holiday.date).toDate(), // for sorting a function accessor is needed
        isSortable: true,
        sortType: 'datetime',
      },
      {
        id: 'confirmedStores',
        Header: i18n.confirmedStores,
        Cell: ({ row }) => <Text>{row.original.confirmedRetailerLocationCount || 0}</Text>,
        minWidth: 200,
        accessor: holidayStatus => holidayStatus.confirmedRetailerLocationCount,
        isSortable: true,
        sortType: 'number',
      },
      {
        id: 'unconfirmedStores',
        Header: i18n.unconfirmedStores,
        Cell: ({ row }) => <Text>{row.original.unconfirmedRetailerLocationCount || 0}</Text>,
        minWidth: 200,
        accessor: holidayStatus => holidayStatus.unconfirmedRetailerLocationCount,
        isSortable: true,
        sortType: 'number',
      },
    ]
    return gridColumns
  }, [i18n])
}
