import NavV2PrimarySplashImage from '../../../../assets/featureHighlights/nav-v2-primary-splash.png'
import NavV2SecondarySplashImage from '../../../../assets/featureHighlights/nav-v2-secondary-splash.png'
import { type DashMessages } from '../../../../intl/intl.types'

export type NavV2EducationDataType = {
  image: string
  title: DashMessages
  subheading: DashMessages
  body: NavV2EducationSection[]
  primaryButtonText?: DashMessages
  secondaryButtonText?: DashMessages
  onClickPrimary?: () => void
  onClickSecondary?: () => void
}

export enum NavV2EducationSectionTypes {
  LIST = 'list',
  BODY = 'body',
}

export enum NavV2EducationPlaceholderTypes {
  LINK = 'link',
}

type NavV2EducationPlaceholderType = {
  text: DashMessages
  placeHolderType: NavV2EducationPlaceholderTypes
  link?: string
}

export type NavV2EducationSectionEntry = {
  text: DashMessages
  placeholders?: Record<string, NavV2EducationPlaceholderType>
}

type NavV2EducationSection = {
  sectionType: NavV2EducationSectionTypes
  contents: NavV2EducationSectionEntry[]
}

export const navV2EducationPrimaryModalData: NavV2EducationDataType[] = [
  {
    image: NavV2PrimarySplashImage,
    title: 'navV2.announcement.welcomeModal.title',
    subheading: 'navV2.announcement.welcomeModal.subheading',
    body: [
      {
        sectionType: NavV2EducationSectionTypes.LIST,
        contents: [
          {
            text: 'navV2.announcement.welcomeModal.body1',
          },
          {
            text: 'navV2.announcement.welcomeModal.body2',
          },
          {
            text: 'navV2.announcement.welcomeModal.body3',
          },
          {
            text: 'navV2.announcement.welcomeModal.body4',
          },
        ],
      },
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.welcomeModal.body5',
            placeholders: {
              here: {
                placeHolderType: NavV2EducationPlaceholderTypes.LINK,
                text: 'navV2.announcement.welcomeModal.body5.link',
                link: 'https://partner-docs.instacart.com/instacart_platform_portal/changelog/projects/redesigned_navigation',
              },
            },
          },
        ],
      },
    ],
    primaryButtonText: 'navV2.announcement.welcomeModal.primaryButton',
    secondaryButtonText: 'navV2.announcement.welcomeModal.secondaryButton',
  },
  {
    image: NavV2SecondarySplashImage,
    title: 'navV2.announcement.switchNavigationModal.title',
    subheading: 'navV2.announcement.switchNavigationModal.subheading',
    body: [
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.switchNavigationModal.body1',
          },
        ],
      },
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.switchNavigationModal.body2',
          },
        ],
      },
    ],
    primaryButtonText: 'navV2.announcement.switchNavigationModal.primaryButton',
  },
]

export const navV2EducationSecondaryModalData: NavV2EducationDataType[] = [
  {
    image: NavV2PrimarySplashImage,
    title: 'navV2.announcement.secondary.welcomeModal.title',
    subheading: 'navV2.announcement.secondary.welcomeModal.subheading',
    body: [
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.secondary.welcomeModal.body1',
          },
        ],
      },
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.secondary.welcomeModal.body2',
          },
        ],
      },
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.secondary.welcomeModal.body3',
            placeholders: {
              here: {
                placeHolderType: NavV2EducationPlaceholderTypes.LINK,
                text: 'navV2.announcement.secondary.welcomeModal.body3.link',
                link: 'https://partner-docs.instacart.com/instacart_platform_portal/changelog/',
              },
            },
          },
        ],
      },
    ],
    primaryButtonText: 'navV2.announcement.secondary.welcomeModal.primaryButton',
    secondaryButtonText: 'navV2.announcement.secondary.welcomeModal.secondaryButton',
  },
  {
    image: NavV2SecondarySplashImage,
    title: 'navV2.announcement.switchNavigationModal.title',
    subheading: 'navV2.announcement.switchNavigationModal.subheading',
    body: [
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.switchNavigationModal.body1',
          },
        ],
      },
      {
        sectionType: NavV2EducationSectionTypes.BODY,
        contents: [
          {
            text: 'navV2.announcement.switchNavigationModal.body2',
          },
        ],
      },
    ],
    primaryButtonText: 'navV2.announcement.switchNavigationModal.primaryButton',
  },
]
