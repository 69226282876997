export enum Metrics {
  ADD_TO_CARTS = 'SUM_VISIT_ADD_TO_CARTS',
  CART_CONVERSIONS = 'VISIT_CART_CONVERSION',
  CLICK_THROUGH_RATE = 'SUM_VISIT_CLICK_THROUGH_RATE',
  CLICKS = 'SUM_VISIT_CLICKS',
  GMV = 'SUM_PRODUCT_GMV',
  IMPRESSIONS = 'SUM_VISIT_IMPRESSIONS',
  LAST_ETL_UPDATE = 'MAX_UPDATED_AT_DATE_TIME_UTC',
  NUM_PLACEMENTS = 'NUM_PLACEMENTS',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  DATE_END = 'END_DATE',
  DATE_START = 'START_DATE',
  DEVICES = 'PLATFORM',
  ORDER_SOURCE = 'ORDER_SOURCE',
  PLACEMENT = 'PLACEMENT',
  PLACEMENT_ID = 'PLACEMENT_ID',
  ACTIVE_PLACEMENT_ID = 'ACTIVE_PLACEMENT_ID',
  PLACEMENT_NAME = 'PLACEMENT_NAME',
  PLACEMENT_STATUS = 'STATUS',
  PLACEMENT_TYPE = 'PLACEMENT_TYPE',
  USER_SEGMENT = 'DIM_USER_SEGMENT',
  WAREHOUSE = 'WAREHOUSE',
  DIM_STORE_CONFIGURATION = 'DIM_STORE_CONFIGURATION',
}

export enum GroupBys {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  DATE_END = 'END_DATE',
  DATE_START = 'START_DATE',
  DEVICES = 'PLATFORM',
  ORDER_SOURCE = 'ORDER_SOURCE',
  PLACEMENT_ID = 'PLACEMENT_ID',
  PLACEMENT_NAME = 'PLACEMENT_NAME',
  PLACEMENT_STATUS = 'STATUS',
  PLACEMENT_TYPE = 'PLACEMENT_TYPE',
  USER_SEGMENT = 'DIM_USER_SEGMENT',
}
