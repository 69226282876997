export const REPORTS_EN_US_TRANSLATIONS = {
  'reportsDomain.nav.label': 'Reports',
  'reportsDomain.navV2.label': 'Instacart managed reports',
  'reportsDomain.breadcrumbs.root': 'Reports',

  'reportsDomain.normalizedReports.dateRangePreset.today': 'Today',
  'reportsDomain.normalizedReports.dateRangePreset.yesterday': 'Yesterday',
  'reportsDomain.normalizedReports.dateRangePreset.last7Days': 'Last 7 days',
  'reportsDomain.normalizedReports.dateRangePreset.last30Days': 'Last 30 days',
  'reportsDomain.normalizedReports.dateRangePreset.last6Months': 'Last 6 months',
  'reportsDomain.normalizedReports.dateRangePreset.custom': 'Custom date range...',

  'reportsDomain.normalizedReports.list.pageTitle': 'All reports',
  'reportsDomain.normalizedReports.list.download': 'Download',
  'reportsDomain.normalizedReports.list.download.error':
    'An error has occurred, please try again later.',
  'reportsDomain.normalizedReports.list.download.unauthorizedError':
    "You don't have the necessary permissions to download this report.",
  'reportsDomain.normalizedReports.list.headers.name': 'Report Name',
  'reportsDomain.normalizedReports.list.headers.reportType': 'Report Type',
  'reportsDomain.normalizedReports.list.headers.fileType': 'File Format',
  'reportsDomain.normalizedReports.list.headers.createdAt': 'Date Created',
  'reportsDomain.normalizedReports.list.headers.banner': 'Banner',
  'reportsDomain.normalizedReports.list.noResults': 'No reports for the selected filters',
  'reportsDomain.normalizedReports.list.isHeavyQuery':
    'Filter by retailers or a report type to view reports',
  'reportsDomain.normalizedReports.list.reportTypes.WEEKLY_RETAILER_UPDATE':
    'Weekly Retailer Update',
  'reportsDomain.normalizedReports.list.reportTypes.OUT_OF_STOCK': 'Out of Stock Dashboard',
  'reportsDomain.normalizedReports.list.reportTypes.ORDER_RATINGS': 'Order Ratings Dashboard',
  'reportsDomain.normalizedReports.list.reportTypes.LIVE_ISSUE': 'Live Issue',
  'reportsDomain.normalizedReports.list.reportTypes.UNATA_EXPRESS_WEEKLY': 'Unata Express Weekly',
  'reportsDomain.normalizedReports.list.reportTypes.RUNNER_HOURS': 'Runner Hours',
  'reportsDomain.normalizedReports.list.reportTypes.REPLACEMENTS_AND_REFUNDS':
    'Replacements and Refunds',
  'reportsDomain.normalizedReports.list.reportTypes.STORE_LEVEL_PARTNER_PERFORMANCE_REPORT':
    'Store Level Performance',
  'reportsDomain.normalizedReports.list.reportTypes.ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT':
    'Associate Level Performance',
  'reportsDomain.normalizedReports.list.reportTypes.DLOG_MONTHLY': 'DLog Monthly',
  'reportsDomain.normalizedReports.list.reportTypes.MLOG_MONTHLY': 'MLog Monthly',
  'reportsDomain.normalizedReports.list.reportTypes.UUID_COMPLIANCE': 'UUID Compliance',
  'reportsDomain.normalizedReports.list.reportTypes.DISTRIBUTION_SYSTEM_REPORT':
    'Distribution System Report',
  'reportsDomain.normalizedReports.list.merge': 'Merge',
  'reportsDomain.normalizedReports.list.reportTypes.SHOPPER_BREAKS': 'Shopper Breaks',
  'reportsDomain.normalizedReports.list.reportTypes.RETAILER_PICKUP': 'Retailer Pickup',
  'reportsDomain.normalizedReports.list.reportTypes.RETAILER_TAX_LOGS': 'Retailer Tax Logs',
  'reportsDomain.normalizedReports.list.reportTypes.TAXES_PAID_AT_POS': 'Taxes paid at POS',
  'reportsDomain.normalizedReports.list.reportTypes.MPF_TAXES_SEMI_MONTHLY':
    'MPF Taxes Semi Monthly',
  'reportsDomain.normalizedReports.list.reportTypes.ADS_DAILY': 'Ads Daily',
  'reportsDomain.normalizedReports.list.reportTypes.ADS_WEEKLY': 'Ads Weekly',
  'reportsDomain.normalizedReports.list.reportTypes.ADS_MONTHLY': 'Ads Monthly',
  'reportsDomain.normalizedReports.list.reportTypes.DISPLAY_PREVIEW_DAILY': 'Display Preview Daily',
  'reportsDomain.normalizedReports.list.reportTypes.DAILY_ULTRAFAST_DASHBOARD':
    'Daily Ultrafast Dashboard',
  'reportsDomain.normalizedReports.list.reportTypes.DAILY_ULTRAFAST_ORDER_REPORTING':
    'Daily Ultrafast Order Reporting',
  'reportsDomain.normalizedReports.list.reportTypes.MERGED_RETAILER_TAX_LOGS':
    'Merged: Retailer Tax Logs',
  'reportsDomain.normalizedReports.list.reportTypes.MERGED_TAXES_PAID_AT_POS':
    'Merged: Taxes paid at POS',
  'reportsDomain.normalizedReports.list.reportTypes.MERGED_SHOPPER_REIMBURSEMENTS_MONTHLY':
    'Merged: Shopper Reimbursements Monthly',
  'reportsDomain.normalizedReports.list.reportTypes.NEW_VERTICALS_WEEKLY_RETAILER_UPDATE':
    'New Verticals - Weekly Retailer Update',
  'reportsDomain.normalizedReports.list.reportTypes.DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT':
    'Delivery Now By Marianos - Daily Adjustment Report',
  'reportsDomain.normalizedReports.list.reportTypes.SCAN_AND_PAY': 'Scan & Pay',
  'reportsDomain.normalizedReports.list.reportTypes.CAPER_DAILY_TRANSACTIONS_SUMMARY':
    'Caper Daily Transactions - Summary',
  'reportsDomain.normalizedReports.list.reportTypes.CAPER_DAILY_TRANSACTIONS_DETAILS':
    'Caper Daily Transactions - Details',
  'reportsDomain.normalizedReports.list.reportTypes.ALDI_ACI_DAILY_STORE_LEVEL_REPORT':
    'Aldi ACI Daily Store Level Report',
  'reportsDomain.normalizedReports.list.reportTypes.ALDI_ACI_DAILY_REPORT': 'Aldi ACI Daily Report',
  'reportsDomain.normalizedReports.list.reportTypes.GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT':
    'Giant Eagle Loyalty Perks Attribution Report',
  'reportsDomain.normalizedReports.list.reportTypes.CAPER_INCENTIVES_REPORT':
    'Caper Incentives Report',
  'reportsDomain.normalizedReports.list.reportTypes.BYPASS_ORDERS_FEEDBACK_OUTSTANDING_FILES_REPORT':
    'Bypass Orders Feedback Outstanding Files Report',
  'reportsDomain.normalizedReports.list.reportTypes.SHOPPER_REIMBURSEMENTS_MONTHLY':
    'Shopper Reimbursements Monthly',
  'reportsDomain.normalizedReports.list.reportTypes.STORE_IN_STORE_PERFORMANCE_AGG':
    'Store in Store Report Aggregate',
  'reportsDomain.normalizedReports.list.reportTypes.STORE_IN_STORE_PERFORMANCE_DTL':
    'Store in Store Report Detail',
  'reportsDomain.normalizedReports.list.reportTypes.ALL': 'All report types',
  'reportsDomain.normalizedReports.merge.result.success.message':
    'Merge initiated! An email will be sent to you when the merge has completed.',
  'reportsDomain.normalizedReports.merge.result.failure.message':
    'Failed to merge reports. Please contact support.',
  'reportsDomain.normalizedReports.merge.confirmPrompt.header': 'Merge reports',
  'reportsDomain.normalizedReports.merge.confirmPrompt.message.question':
    'Would you like to merge these reports into a new file?',
  'reportsDomain.normalizedReports.merge.confirmPrompt.message.reportCount':
    'Number of reports: {reportCount}',
  'reportsDomain.normalizedReports.merge.confirmPrompt.message.duration':
    'Range of data: {reportTime}',
  'reportsDomain.normalizedReports.merge.confirmPrompt.confirmMerge': 'Merge',
  'reportsDomain.normalizedReports.merge.confirmPrompt.cancelMerge': 'Cancel',
  'reportsDomain.normalizedReports.merge.beginningOfTime': 'Beginning of time',
  'reportsDomain.normalizedReports.merge.today': 'Today',
  'reportsDomain.instacartManagedReports.merge.result.success.message':
    'Merge initiated! An email will be sent to you in the next few minutes when the merge has completed.',
  // Pickup Analytics
  'reportsDomain.pickupAnalytics.title': 'Store labor performance',
  'reportsDomain.pickupAnalytics.pageTitle': 'Store Labor Performance',
  'reportsDomain.pickupAnalytics.errorLoading': 'Unexpected error loading data',
  'reportsDomain.pickupAnalytics.cta.toShiftManagement': 'Schedule Shifts',
  'reportsDomain.pickupAnalytics.section.overallPerformance': 'Overall Performance',
  'reportsDomain.pickupAnalytics.metric.pickups': 'Picking batches',
  'reportsDomain.pickupAnalytics.metric.batchAcceptance': 'Batch acceptance',
  'reportsDomain.pickupAnalytics.section.customerSatisfaction': 'Pickup Customer Satisfaction',
  'reportsDomain.pickupAnalytics.metric.p75WaitTime': '75th Percentile wait time',
  'reportsDomain.pickupAnalytics.section.additionalPerformanceMetrics': 'Additional metrics',
  'reportsDomain.pickupAnalytics.metric.secondsPerItem': 'Seconds per item',
  'reportsDomain.pickupAnalytics.metric.latePercentage': 'Late staged percentage',
  'reportsDomain.pickupAnalytics.metric.utilizationPercentage': 'Utilization percentage',
  'reportsDomain.pickupAnalytics.metric.shopperRating': 'Shopper rating',
  'reportsDomain.pickupAnalytics.chart.pickup.title': 'Daily trend',
  'reportsDomain.pickupAnalytics.chart.p75WaitTime.title': '75th Percentile wait time trend',
  'reportsDomain.pickupAnalytics.tooltip.pickups':
    'The total number of pickup orders completed at a particular store.',
  'reportsDomain.pickupAnalytics.tooltip.secondsPerItem':
    'Calculated as the total amount of time shoppers spend on an order, divided by the unique item count.',
  'reportsDomain.pickupAnalytics.tooltip.batchAcceptance':
    'The percentage of batched orders to your stores that shoppers have accepted within the allotted four minute timeframe.',
  'reportsDomain.pickupAnalytics.tooltip.p75WaitTime':
    'The time it takes to hand off pickup orders to customers, measured in minutes. Wait-times start when runner batch is assigned and end when handoff is completed.',
  'reportsDomain.pickupAnalytics.tooltip.experienceRating':
    'Customer’s rating of the overall pickup experience from 1-5.',
  'reportsDomain.pickupAnalytics.tooltip.shopperRating':
    'Customer’s rating of the shopping experience from 1-5.',
  'reportsDomain.pickupAnalytics.tooltip.latePercent':
    'Percentage of orders staged after the start of the customer’s pickup window.',
  'reportsDomain.pickupAnalytics.tooltip.utilization':
    'Percentage of time shoppers are actively shopping, staging, or running an order.',
  'reportsDomain.pickupAnalytics.dataAvailabilityNotice':
    "Yesterday's information may not be ready before 10:00 AM EST",
  'reportsDomain.pickupAnalytics.overallPerformanceToggle.all': 'All',
  'reportsDomain.pickupAnalytics.overallPerformanceToggle.pickup': 'Pickup',
  'reportsDomain.pickupAnalytics.overallPerformanceToggle.delivery': 'Delivery',
  'reportsDomain.instacartManagedReportsList.nav': 'Instacart managed reports',
} as const

export default REPORTS_EN_US_TRANSLATIONS
