import { useEffect } from 'react'
import { useNavContext } from '../../../gin-and-tonic/containers/nav/utils/NavContext'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import { useOptionalPartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'

export const useNavV2Visibility = () => {
  const { navV2RouletteEnabled, setNavV2RouletteEnabled } = useNavContext()

  const partnerContext = useOptionalPartnerContext()
  const { account: currentAccount } = useCurrentAccountDetails()
  const accountHasNavV2 = !!currentAccount?.featureToggles?.find(
    feature => feature.name === 'rt_ia_nav_v2'
  )

  const enabled = accountHasNavV2 || partnerContext?.hasIaNavV2

  useEffect(() => {
    if (navV2RouletteEnabled !== enabled) {
      setNavV2RouletteEnabled(enabled)
    }
  })
}
