import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_CONNECTED_STORES = `${URL_APP_ADMIN}/connected-stores`
const URL_CONNECTED_STORES_CAPER = `${URL_CONNECTED_STORES}/caper`
const URL_CONNECTED_STORES_UPOS = `${URL_CONNECTED_STORES}/upos`
const URL_CONNECTED_STORES_SHELF_LABELS = `${URL_CONNECTED_STORES}/shelf-labels`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-connected-stores'>
  | DashRouteType<'app-admin-connected-stores-caper'>
  | DashRouteType<'app-admin-connected-stores-upos'>
  | DashRouteType<'app-admin-connected-stores-shelf-labels'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-connected-stores': URL_CONNECTED_STORES,
  'app-admin-connected-stores-caper': URL_CONNECTED_STORES_CAPER,
  'app-admin-connected-stores-upos': URL_CONNECTED_STORES_UPOS,
  'app-admin-connected-stores-shelf-labels': URL_CONNECTED_STORES_SHELF_LABELS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
