import React, { Fragment, type FunctionComponent, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  spacing,
  fontSize,
  fontWeight,
  StatusCircle,
  colors,
  Tooltip,
} from '@retailer-platform/shared-components'
import { InformationIcon } from '@instacart/ids-core'
import { type SVGIconProps } from '@instacart/ids-core'
import { ChevronDownIcon } from '@instacart/ids-core'
import { css } from '@emotion/react'
import { NewBadge } from '../../../common/badges/NewBadge'
import { hexToRgba } from '../../../../legacy/common/utils/colors/hexToRGBA'
import usePartnerAccessControl from '../../../../legacy/components/AccessControl/usePartnerAccessControl'
import { type AccessControlConfig } from '../../../../legacy/components/AccessControl/accessControl.utils'
import { useHasAccessibleChildren } from '../utils/navFolder.utils'
import { type NavItem as NavEntryPoint } from '../../../../legacy/components/Nav/nav.types'
import { useNavContext } from '../utils/NavContext'
import { type AdminNavEntryPoint } from '../utils/adminNavConfig'
import { useDashMessage } from '../../../../intl/intl.hooks'
import { type DashMessages } from '../../../../intl/intl.types'
import { useRPPTour } from '../../../../utils/tour/tour.hooks'
import { useAdminControls } from '../../../../utils/contexts/admin-controls/AdminControlsContext'
import { NavItem, type NavItemProps } from './NavItem'
import { NavIconWrapper } from './NavIconWrapper'

type Props = {
  title: DashMessages
  selected?: boolean
  Icon?: (props: Omit<SVGIconProps, 'component'>) => JSX.Element
  accessControl?: AccessControlConfig | AccessControlConfig[]
  navEntryPoint?: NavEntryPoint | AdminNavEntryPoint
  showNotification?: boolean
  isNewNav?: boolean
}

const Container = styled.div<{ color: string }>(
  {
    height: 48,
    borderRadius: 4,
    fontSize: fontSize.X15,
    boxSizing: 'border-box',
    width: 256,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    fontWeight: fontWeight.SEMIBOLD,
    userSelect: 'none',
  },
  ({ color }) => ({
    color,
  })
)

const FolderHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 256,
  '&:hover': {
    backgroundColor: hexToRgba('#358266', 0.2),
    cursor: 'pointer',
  },
})

const chevronStyles = (isFolderOpen: boolean) =>
  css({
    marginRight: spacing.X16,
    transition: 'transform 0.15s',
    transform: isFolderOpen ? 'rotate(180deg)' : 'none',
  })

const ChildrenContainer = styled.div<{ isFolderOpen: Boolean }>(
  {
    transition: 'max-height 0.3s',
    overflow: 'hidden',
  },
  ({ isFolderOpen }) => ({
    // can't animate from 0 to auto height, so animating maxHeight
    // children items shouldn't need to get bigger than 800px
    // but this can be increased if needed
    maxHeight: isFolderOpen ? 800 : 0,
  })
)

export const NavFolder: FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  children,
  Icon,
  accessControl,
  navEntryPoint,
  showNotification,
  isNewNav,
}) => {
  const hasAccess = usePartnerAccessControl()

  const { isNavOpen, navigationEntries, contentColor, iconColor, folderControlledByTour } =
    useNavContext()
  const [isFolderOpenByUser, setIsFolderOpenByUser] = useState(false)
  const { isOpen: isTourOpen } = useRPPTour()
  const toggleFolder = () => setIsFolderOpenByUser(!isFolderOpen)
  const isFolderOpen = isTourOpen ? folderControlledByTour === title : isFolderOpenByUser
  const hasShowAccessControls = useHasShowAccessControls()

  const domainNavItems = useMemo(
    () =>
      navigationEntries[navEntryPoint]
        ?.map(navEntry => {
          if (navEntry.accessControl && !hasAccess(navEntry.accessControl)) return null
          const isCustomWrapper = !!navEntry.NavItemWrapper
          const NavItemWrapper = navEntry.NavItemWrapper || React.Fragment
          const navItemProps: NavItemProps = {
            route: navEntry.route,
            title: navEntry.labelId,
            accessControl: navEntry.accessControl,
            id: navEntry.id,
            position: navEntry.position,
            navBadge: navEntry.navBadge,
            href: navEntry.href,
            environment: navEntry.environment,
          }
          return (
            <NavItemWrapper
              key={`${navEntry.labelId}-wrapper`}
              {...(isCustomWrapper ? navItemProps : {})}
            >
              <NavItem key={navEntry.labelId} {...navItemProps} />
            </NavItemWrapper>
          )
        })
        .filter(Boolean) || [],
    [navigationEntries, hasAccess, navEntryPoint]
  )

  const allChildren = useMemo(() => {
    const allChildren = [...React.Children.toArray(children), ...domainNavItems]
    if (!hasShowAccessControls) {
      return allChildren
    }

    // this allChildren mapping is a way to inject ToolTipForAccessControl badges into each NavItem
    return allChildren.map(element => {
      const badges = (
        <>
          {element.props?.navBadge}
          {<ToolTipForAccessControl accessControl={element.props?.accessControl} />}
        </>
      )

      return React.cloneElement(element, { navBadge: badges }) as React.ReactElement
    })
  }, [children, domainNavItems, hasShowAccessControls])

  const hasAccessibleChildren = useHasAccessibleChildren(allChildren)

  const titleMessage = useDashMessage(title)

  const sortedChildren = useMemo(() => {
    const defaultPos = 9999 //if a position isn't provided it gets sorted to the end

    return allChildren.sort(
      (childA, childB) =>
        (childA.props.position || defaultPos) - (childB.props.position || defaultPos)
    )
  }, [allChildren])
  // access control MAY be undefined. if it is, the user has access.
  // otherwise, give access if at least one element has access.
  const atLeastOneAccess = !accessControl || hasAccess(accessControl)
  // don't render the folder if it's own access control is inaccessible
  if (!atLeastOneAccess) return null

  // don't render the folder at all if none if it's children are accessible
  if (!hasAccessibleChildren) return null

  return (
    <Fragment>
      <FolderHeader tabIndex={0} role="button" onClick={toggleFolder}>
        <Container color={contentColor}>
          <NavIconWrapper>{Icon && <Icon size={24} color={iconColor} />}</NavIconWrapper>
          {titleMessage} {isNewNav && <NewBadge />}
          <ToolTipForAccessControl accessControl={accessControl} />
          {showNotification && (
            <StatusCircle
              css={{ marginLeft: spacing.X8 }}
              size="medium"
              color={colors.HIGHLIGHT.REGULAR}
            />
          )}
        </Container>
        <ChevronDownIcon css={chevronStyles(isFolderOpen)} size={14} color={iconColor} />
      </FolderHeader>
      <ChildrenContainer isFolderOpen={isFolderOpen && isNavOpen}>
        {sortedChildren}
      </ChildrenContainer>
    </Fragment>
  )
}

const ToolTipForAccessControl = (props: { accessControl: unknown }) => {
  const hasAccess = useHasShowAccessControls()
  if (!hasAccess) {
    return null
  }

  return (
    <Tooltip
      target={<InformationIcon color="systemGrayscale00" size={22} style={{ paddingLeft: 8 }} />}
      containerStyles={{ maxWidth: null }}
    >
      <div>
        <pre>{JSON.stringify(props.accessControl || {}, null, 2)}</pre>
      </div>
    </Tooltip>
  )
}

const useHasShowAccessControls = () => {
  const { showNavAccessControlSettings } = useAdminControls()
  return showNavAccessControlSettings
}
