import { useCallback, useMemo } from 'react'
import { type QueryResult } from '@apollo/react-common'
import React from 'react'
import { ApolloError } from 'apollo-client'
import { useNotifications } from '../../legacy/common/utils/notifications/notifications.hooks'
import { FetchErrorNotification } from './FetchErrorNotification'

type Keys<T> = keyof T
type NodeType<T, A extends Keys<T>> = T extends Pick<QueryResult, 'data'>
  ? T['data'][A]['edges'][0]['node']
  : never

interface RenderErrorProps {
  error: ApolloError | Error
}

interface PaginatedQueryOptions {
  /** Whether to display a notification when something goes wrong when fetching items or paginating, defaults to true */
  bubbleErrorsToUI?: boolean
  /**
   * A component or render function to render when `bubbleErrorsToUI` is true, not required and
   * defaults to a generic message.
   *
   * The component will receive an `error` prop containing the ApolloError, which
   * can be used to further customize the messages
   */
  ErrorNotification?: React.ComponentType<React.PropsWithChildren<RenderErrorProps>>
}

interface DerivedData<T> {
  /** The unwrapped results of the query */
  apiResult: T[] | null
  /** Whether there are more pages available to load */
  canLoadMore: boolean
  /** The current end cursor for the page */
  endCursor: string | null
  /** Whether or not the query succeeded -- Keep in mind this is currently just the absence of an error */
  apiSuccess: boolean
}

/**
 * Helper function for deriving data based on the query results
 *
 * @param queryResult the query results from useQuery
 * @param accessorKey the key where the data is stored
 */
export function getDerivedData<
  TQueryResults extends Pick<QueryResult, 'data' | 'error'>,
  TQueryResultAccessor extends Keys<TQueryResults['data']>,
  TNode extends NodeType<TQueryResults, TQueryResultAccessor>
>(queryResult: TQueryResults, accessorKey: TQueryResultAccessor): DerivedData<TNode> {
  const { data, error } = queryResult

  if (!data) {
    return {
      apiResult: null,
      canLoadMore: true,
      endCursor: null,
      apiSuccess: false,
    }
  }

  const { edges, pageInfo } = data[accessorKey]

  return {
    apiResult: edges.map(edge => edge.node),
    endCursor: pageInfo.endCursor,
    canLoadMore: pageInfo.hasNextPage,
    apiSuccess: !error,
  }
}

/**
 * A higher order function that decorates an apollo query
 * hook with additional functionality.
 *
 * This is intended specifically for queries that use Connection
 * types in our graphql server, and exposes a `loadMore` function
 * along with a couple other properties that can help reduce boilerplate.
 *
 * The resulting function also exposes all properties returned by the original
 * query, in case there's something that's out of the scope of this particular
 * hook.
 *
 * @param useQueryFunction the original query hook to decorate -- Keep in mind it MUST adhere to the pagination rules we've set up in the application (Connection type queries)
 * @param queryName the query name which will be used to access results
 */
export function withPaginationDetails<
  TOriginalFn extends (args: any) => any,
  TOriginalReturn extends ReturnType<TOriginalFn>,
  TOriginalBaseOptions extends Parameters<TOriginalFn>[0],
  TQueryResultAccessor extends Exclude<Keys<TOriginalReturn['data']>, '__typename'>,
  TNode extends NodeType<TOriginalReturn, TQueryResultAccessor>
>(
  useQueryFunction: TOriginalFn,
  queryName: TQueryResultAccessor
): (
  args: TOriginalBaseOptions,
  options?: PaginatedQueryOptions
) => QueryResult<TOriginalReturn['data']> &
  DerivedData<TNode> & {
    loadMore: () => void
  } {
  return (args, options = {}) => {
    const { bubbleErrorsToUI = true, ErrorNotification = FetchErrorNotification } = options
    const { fetchMore, data, error, ...restQuery } = useQueryFunction(args)
    const { notifyError } = useNotifications()

    // only recalculate derived data if apollo returns new data or error objects
    // otherwise returning a new apiResult each render will cause performance issues
    const { endCursor, ...restDerived } = useMemo(
      () => getDerivedData({ data, error }, queryName),
      [data, error]
    )

    const handleError = useCallback(
      (error: ApolloError | Error) => {
        if (!bubbleErrorsToUI) return

        notifyError(<ErrorNotification error={error} />, 'modern')
      },
      [notifyError, bubbleErrorsToUI, ErrorNotification]
    )

    const loadMore = useCallback(
      () =>
        fetchMore({
          variables: { after: endCursor },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult

            const previousQuery = previousResult[queryName]
            const currentQuery = fetchMoreResult[queryName]

            return {
              [queryName]: {
                ...currentQuery,
                edges: [...previousQuery.edges, ...currentQuery.edges],
              },
            }
          },
        })
          .then(result => {
            if (!result.errors?.length) return result

            handleError(
              new ApolloError({
                errorMessage: 'Something went wrong while paginating',
                graphQLErrors: result.errors,
              })
            )
            return result
          })
          .catch(err => {
            handleError(err)
            // Error is rethrown in case any additional handling needs to happen upstream.
            throw err
          }),
      [endCursor, fetchMore, handleError]
    )

    return {
      // Original apollo query stuff
      data,
      error,
      ...restQuery,
      fetchMore,
      // Derived data
      ...restDerived,
      endCursor,
      // A query-bound loadMore function to retrieve next pages
      loadMore,
    }
  }
}
