import { onError } from 'apollo-link-error'
import { type ServerError, type ServerParseError } from 'apollo-link-http-common'
import { setContext } from 'apollo-link-context'
import localforage from 'localforage'
import { ApolloError } from 'apollo-client'
import instacart from '../common/instacart'
import { errors } from '../../utils/error-handling/errors'
import clientEnv from '../../utils/global/clientEnv'
import { isUndeterminedAccountError } from '../../api/utils/apollo/errors/isError.hooks'
import { logout, LogoutReason } from '../../gin-and-tonic/containers/log-out/utils/logout'
import { type EnterpriseGraphQLError } from './errors'
import { ExtensionsCode } from './errorExtensions.types'

const reportableGraphQLErrorCodes = [
  ExtensionsCode.MissingRequiredArguments,
  ExtensionsCode.ArgumentLiteralsIncompatible,
  ExtensionsCode.UndefinedField,
  ExtensionsCode.ArgumentNotUnique,
]

const fetchToken = async () => {
  const session = await localforage.getItem<{ token?: string }>('session')
  if (!session) return null

  return session.token
}

export const authLink = setContext(async (_, { headers }) =>
  fetchToken()
    .then(token => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        ...(clientEnv.PUBLIC_CLIENT_CYPRESS_TOKEN && {
          'x-cypress-token': clientEnv.PUBLIC_CLIENT_CYPRESS_TOKEN,
        }),
      },
    }))
    .catch(err =>
      // eslint-disable-next-line no-console
      console.error(err)
    )
)

export const errorLink = onError(error => {
  const { networkError } = error

  if (networkError) {
    if ((networkError as ServerError | ServerParseError).statusCode === 401) {
      instacart.logoutAndRedirect()
    } else {
      errors.captureException(networkError)
    }
  }

  if (error.graphQLErrors) {
    const graphQLErrors = error.graphQLErrors as EnterpriseGraphQLError[]

    const apolloError = new ApolloError({ graphQLErrors })

    if (isUndeterminedAccountError(apolloError)) {
      logout(LogoutReason.TokenExpired)
      return
    }

    const { operationName, query, variables } = error.operation

    graphQLErrors.forEach(graphQLError => {
      const { message, extensions } = graphQLError
      const meta = { query, variables, error: graphQLError }

      if (extensions && extensions.code) {
        if (reportableGraphQLErrorCodes.includes(extensions.code)) {
          errors.captureMessage(
            `GraphQL Error ${extensions.code} performing ${operationName}: ${message}`,
            { level: 'error', extra: meta }
          )
        }
      } else {
        errors.captureMessage(`Unknown GraphQL Error performing ${operationName}: ${message}`, {
          level: 'warning',
          extra: meta,
        })
      }
    })
  }
})
