import {
  type DomainAccessControlConfig,
  type DomainAccessControlConfigItem,
} from '../utils/domain/accessControl'
import { storeHoursEditAccessControl } from './storeHoursEditAccess.configuration'

// Give the user access only if they have edit access
const holidaysListWarehouseAccessControl: DomainAccessControlConfig = {
  ...storeHoursEditAccessControl,
}

export const holidaysListAccessControl: DomainAccessControlConfig = [
  /**
   * As long as the user has access to one of them, the menu entry and page
   * should be visible.
   *
   * The reason why these feature toggles are declared this way is because the
   * `DomainAccessControlConfig` instances use && logic across their feature toggle
   * declarations. In this case we want to use || logic. Which is what this
   * `DomainAccessControlConfigItem[]` does for us.
   */
  holidaysListWarehouseAccessControl,
] as DomainAccessControlConfigItem[]
