/* eslint-disable @typescript-eslint/no-explicit-any, max-classes-per-file */

import Bluebird from 'bluebird'
import clientEnv from '../../utils/global/clientEnv'
import { storeRedirectUrl } from '../../routes/logged-in/utils/loggedIn.utils'
import { errors } from '../../utils/error-handling/errors'
import localforage from './localforage'
import deprecatedAnalytics from './deprecatedAnalytics'
import request, { type RequestOpts, type HttpMethod } from './request'
import { ROUTE_ROOT } from './constants'
import HttpError from './errors/HttpError'
import ApiResponseError from './errors/ApiResponseError'
import { type User } from './types'

Bluebird.config({
  cancellation: true,
})

export type ApiRequestOpts<TData = {}, TQuery = {}> = Omit<RequestOpts<TData, TQuery>, 'method'>

// @TODO: kill bluebird
export type GoFnBluebird<TMethod extends HttpMethod> = TMethod extends 'get'
  ? <TResponse, TQuery = {}>(
      path: string,
      apiRequestOpts?: ApiRequestOpts<undefined, TQuery>
    ) => Bluebird<TResponse>
  : <TResponse, TData = {}, TQuery = {}>(
      path: string,
      apiRequestOpts?: ApiRequestOpts<TData, TQuery>
    ) => Bluebird<TResponse>

export type Go = {
  get: GoFnBluebird<'get'>
  post: GoFnBluebird<'post'>
  put: GoFnBluebird<'put'>
  delete: GoFnBluebird<'delete'>
}

interface LoginResponse {
  token: string
}

function createGo({ env, onUnauthorized }: { env: Env; onUnauthorized: () => void }): Go {
  const go: Go = {
    get: createGoFn('get'),
    post: createGoFn('post'),
    put: createGoFn('put'),
    delete: createGoFn('delete'),
  }

  function createGoFn<TMethod extends HttpMethod>(method: TMethod) {
    function goRequest<TResponse, TOpts extends ApiRequestOpts<any, any>>(
      path: string,
      apiRequestOpts?: TOpts
    ) {
      return Bluebird.resolve(getRequestParams<TOpts>(method, path, apiRequestOpts))
        .then(({ url, opts }) => request<TResponse>(url, opts))
        .catch(async e => {
          // fetch will throw an error if there is a network issue (cannot reach server)
          // otherwise, fetch checks the response status and throw an HttpError for non 2xx 3xx responses
          if (!(e instanceof HttpError)) {
            // fetch threw
            // error has no http status associated with it, likely a CORS or network issue
            errors.captureApiError(e)
            throw e
          }

          if (e.status === 401 && !path.match(/auth\/(\w+)/)) {
            // current filters ignore 401 but still make the attempt
            errors.captureApiError(e)
            await onUnauthorized()
            throw e
          }

          const error = await getApiResponseError(e)

          errors.captureApiError(error, {
            extra: {
              url: e.url,
              requestId: e.headers.get('x-request-id'),
            },
          })

          throw error
        })
    }

    return goRequest
  }

  async function getRequestParams<TOpts extends ApiRequestOpts<any, any>>(
    method: HttpMethod,
    path: string,
    apiRequestOpts?: TOpts
  ): Promise<{ url: string; opts: RequestOpts<any, any> }> {
    const url = env.apiRoot + path
    const session = await env.get<{ token: string }>('session')
    let headers

    if (session) {
      headers = {
        Authorization: `Bearer ${session.token}`,
      }
    }

    const opts: RequestOpts<any, any> = {
      method,
      headers,
      credentials: 'include',
      ...apiRequestOpts,
    }

    return { url, opts }
  }

  return go
}

async function getApiResponseError(e: HttpError) {
  let message = `API Error: ${e.status}`
  let apiErrors

  if (e.body && Object.prototype.hasOwnProperty.call(e.body, 'errors')) {
    const { errors } = e.body

    if (Array.isArray(errors) && errors.length) {
      message = errors.toString()
      apiErrors = errors
    } else if (typeof errors === 'string') {
      message = errors
      apiErrors = [errors]
    }
  }

  return new ApiResponseError(message, e.status, apiErrors)
}

class Env {
  mode: NodeEnv

  constructor(opts: { mode: NodeEnv }) {
    this.mode = opts.mode
  }

  get apiRoot(): string {
    return `${clientEnv.PUBLIC_CLIENT_RPP_URL}/api`
  }

  async get<T>(key: string): Promise<T | null> {
    try {
      return await localforage.getItem<T>(key)
    } catch (e) {
      return null
    }
  }

  async set(key: string, data: object | undefined) {
    return localforage.setItem(key, data)
  }

  async del(key: string) {
    return localforage.removeItem(key)
  }
}

class Instacart {
  env!: Env

  go!: Go

  auth: User | null = null

  init(opts: { mode: NodeEnv } = { mode: 'development' }) {
    this.env = new Env(opts)
    this.go = createGo({
      env: this.env,
      onUnauthorized: () => this.logoutAndRedirect(),
    })
    this.auth = null

    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.log(`[Instacart client] initiated in ${opts.mode} mode`)
    }
  }

  async getAuthInfo(): Promise<User | null> {
    if (this.auth) return this.auth
    const token = await this.env.get('session')
    if (!token) return null

    try {
      const { data: auth } = await this.go.get<{ data: User }>('/v1/auth/user')
      this.auth = auth
    } catch (e) {
      // noop
    }

    return this.auth
  }

  async isLoggedIn() {
    const session = await this.env.get('session')

    return !!session
  }

  async login(email: string, password: string) {
    const { data } = await this.go.post<{ data: LoginResponse }>('/v1/auth/login', {
      data: { email, password },
    })
    await this.env.set('session', data)
    const user = await this.getAuthInfo()
    deprecatedAnalytics.track('login.login_complete', {
      user_id: user && user.uuid,
    })

    return data
  }

  async logout() {
    await this.go.post('/v1/auth/logout')
    await this.env.del('session')
    this.auth = null

    return this
  }

  async logoutAndRedirect() {
    const { pathname, search, hash } = window.location

    storeRedirectUrl({ pathname, search, hash })

    try {
      await this.logout()
      window.location.href = ROUTE_ROOT
    } catch (e) {
      // noop
    }
  }
}

export default (() => new Instacart())()
