import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import ReactModal from 'react-modal'
import { createRoot } from 'react-dom/client'
import { SHARED_COMPONENTS_EN_US } from '@retailer-platform/shared-components'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { instacart } from '@retailer-platform/dashboard/legacy'
import { initSentry } from '@retailer-platform/dashboard/errors'
import { initAnalytics } from '@retailer-platform/dashboard/analytics'
import { RPPCore } from '@retailer-platform/dashboard/core'
import FeatureEntitlementsManagementDomain from '@retailer-platform/domain-feature-entitlements-management/domainDefinition'
import IntegrationsDomain from '@retailer-platform/domain-integrations/domainDefinition'
import AuditLogsDomain from '@retailer-platform/domain-audit-logs/domainDefinition'
import StoreHoursBulkDomain from '@retailer-platform/domain-store-hours-bulk/domainDefinition'
import DraftManagementDomain from '@retailer-platform/domain-draft-management/domainDefinition'
import StoreConfigurationDomain from '@retailer-platform/domain-store-configuration/domainDefinition'
import RoleManagementDomain from '@retailer-platform/domain-role-management/domainDefinition'
import NotificationsDomain from '@retailer-platform/domain-notifications/domainDefinition'
import PickupSettingsDomain from '@retailer-platform/domain-pickup-settings/domainDefinition'
import AccountManagementDomain from '@retailer-platform/domain-account-management/domainDefinition'
import InsightsPortalDomain from '@retailer-platform/domain-insights-portal/domainDefinition'
import ShoppingListDomain from '@retailer-platform/domain-shopping-list/domainDefinition'
import AuthenticationDomain from '@retailer-platform/domain-authentication/domainDefinition'
import BusinessDomain from '@retailer-platform/domain-business/domainDefinition'
import StorefrontOnboardingDomain from '@retailer-platform/domain-storefront-onboarding/domainDefinition'
import ConfigurableProductsDomain from '@retailer-platform/domain-configurable-products/domainDefinition'
import CustomersDomain from '@retailer-platform/domain-customers/domainDefinition'
import FulfillmentTestingCenterDomain from '@retailer-platform/domain-fulfillment-testing-center/domainDefinition'
import WebhookConfigurationDomain from '@retailer-platform/domain-webhook-configuration/domainDefinition'
import RetailerCollectionsDomain from '@retailer-platform/domain-retailer-collections/domainDefinition'
import RecipesAdminDomain from '@retailer-platform/domain-recipes-admin/domainDefinition'
import OnboardingDomain from '@retailer-platform/domain-onboarding/domainDefinition'
import EmergingBrandsPortalDomain from '@retailer-platform/domain-emerging-brands-portal/domainDefinition'
import StoreInterventionsDomain from '@retailer-platform/domain-store-interventions/domainDefinition'
import RetailerFundedMarketingDomain from '@retailer-platform/domain-retailer-funded-marketing/domainDefinition'
import LandingDomain from '@retailer-platform/domain-landing/domainDefinition'
import ScanAndPayDomain from '@retailer-platform/domain-scan-and-pay/domainDefinition'
import ConnectDomain from '@retailer-platform/domain-connect/domainDefinition'
import MerchandisingDomain from '@retailer-platform/domain-merchandising/domainDefinition'
import FoodstormDomain from '@retailer-platform/domain-foodstorm/domainDefinition'
import ApprovalsManagementLibraryDomain from '@retailer-platform/domain-approvals-management-library/domainDefinition'
import WidgetsDomain from '@retailer-platform/domain-widgets/domainDefinition'
import InstacartDeveloperPlatformDomain from '@retailer-platform/domain-instacart-developer-platform/domainDefinition'
import CredentialsManagementDomain from '@retailer-platform/domain-credentials-management/domainDefinition'
import CashflowDomain from '@retailer-platform/domain-cashflow/domainDefinition'
import BannersDomain from '@retailer-platform/domain-banners/domainDefinition'
import AggregateStorePerformanceDomain from '@retailer-platform/domain-aggregate-store-performance/domainDefinition'
import DataIngestionDomain from '@retailer-platform/domain-data-ingestion/domainDefinition'
import CaperDomain from '@retailer-platform/domain-caper/domainDefinition'
import ShiftsDomain from '@retailer-platform/domain-shifts/domainDefinition'
import PartnerOnboardingDomain from '@retailer-platform/domain-partner-onboarding/domainDefinition'
import ConnectOrdersDomain from '@retailer-platform/domain-connect-orders/domainDefinition'
import EversightDomain from '@retailer-platform/domain-eversight/domainDefinition'
import OrdersOperationalDashboardDomain from '@retailer-platform/domain-orders-operational-dashboard/domainDefinition'
import CatalogAdminDomain from '@retailer-platform/domain-catalog-admin/domainDefinition'
import FulfillmentEventsDomain from '@retailer-platform/domain-fulfillment-events/domainDefinition'
import PartnerPickDevicesDomain from '@retailer-platform/domain-partner-pick-devices/domainDefinition'
import StorefrontDomain from '@retailer-platform/domain-storefront/domainDefinition'
import ConnectedStoresDomain from '@retailer-platform/domain-connected-stores/domainDefinition'
import CampaignManagementDomain from '@retailer-platform/domain-campaign-management/domainDefinition'
import ProductsDomain from '@retailer-platform/domain-products/domainDefinition'
import SiteManagementDomain from '@retailer-platform/domain-site-management/domainDefinition'
import ReportsDomain from '@retailer-platform/domain-reports/domainDefinition'
// @hygen:inject import-domain

initSentry()
initAnalytics()

/* Vendor Setup */
ReactModal.setAppElement('#root')

instacart.init({ mode: (process.env.NODE_ENV || 'development') as NodeEnv })
/**
 * We create an instance of the application,
 * this will allow us to register domains
 */
const app = new RPPCore({})

app.addTranslations(SHARED_COMPONENTS_EN_US)

/**
 * Add some domains
 */
app.attachDomain(AuthenticationDomain)
app.attachDomain(NotificationsDomain)
app.attachDomain(InsightsPortalDomain)
app.attachDomain(IntegrationsDomain)
app.attachDomain(PickupSettingsDomain)
app.attachDomain(StoreHoursBulkDomain)
app.attachDomain(ShoppingListDomain)
app.attachDomain(ConfigurableProductsDomain)
app.attachDomain(FulfillmentTestingCenterDomain)
app.attachDomain(RetailerCollectionsDomain)
app.attachDomain(StorefrontDomain)
app.attachDomain(OnboardingDomain)
app.attachDomain(CampaignManagementDomain)
app.attachDomain(WebhookConfigurationDomain)
app.attachDomain(RetailerFundedMarketingDomain)
app.attachDomain(RoleManagementDomain)
app.attachDomain(AuditLogsDomain)
app.attachDomain(CustomersDomain)
app.attachDomain(StoreConfigurationDomain)
app.attachDomain(EmergingBrandsPortalDomain)
app.attachDomain(ScanAndPayDomain)
app.attachDomain(FulfillmentEventsDomain)
app.attachDomain(DraftManagementDomain)
app.attachDomain(FeatureEntitlementsManagementDomain)
app.attachDomain(StorefrontOnboardingDomain)
app.attachDomain(LandingDomain)
app.attachDomain(AccountManagementDomain)
app.attachDomain(BusinessDomain)
app.attachDomain(RecipesAdminDomain)
app.attachDomain(StoreInterventionsDomain)
app.attachDomain(ConnectDomain)
app.attachDomain(MerchandisingDomain)
app.attachDomain(FoodstormDomain)
app.attachDomain(ApprovalsManagementLibraryDomain)
app.attachDomain(BannersDomain)
app.attachDomain(WidgetsDomain)
app.attachDomain(InstacartDeveloperPlatformDomain)
app.attachDomain(CredentialsManagementDomain)
app.attachDomain(CashflowDomain)
app.attachDomain(AggregateStorePerformanceDomain)
app.attachDomain(DataIngestionDomain)
app.attachDomain(CaperDomain)
app.attachDomain(ShiftsDomain)
app.attachDomain(PartnerOnboardingDomain)
app.attachDomain(ConnectOrdersDomain)
app.attachDomain(EversightDomain)
app.attachDomain(OrdersOperationalDashboardDomain)
app.attachDomain(CatalogAdminDomain)
app.attachDomain(PartnerPickDevicesDomain)
app.attachDomain(ConnectedStoresDomain)
app.attachDomain(ProductsDomain)
app.attachDomain(SiteManagementDomain)
app.attachDomain(ReportsDomain)
// @hygen:inject attach-domain

/**
 * Actually start the application
 */
app.start(tree => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(tree)
})
