import React from 'react'
import { type IntlShape, useIntl } from 'react-intl'
import {
  type NavV2EducationDataType,
  type NavV2EducationSectionEntry,
  NavV2EducationSectionTypes,
  NavV2EducationPlaceholderTypes,
} from './NavV2EducationData'
import styled from '@emotion/styled'
import { Button, spacing, colors } from '@retailer-platform/shared-components'
import { typography } from '@instacart/tds'
import LinkBase from '../../../../legacy/components/LinkBase'

const ModalContainer = styled.div({
  display: 'flex',
  gap: spacing.X32,
  width: '100%',
  height: '100%',
})

const ModalColumn = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '50%',
})

const ModalContentSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
})

const ModalButtonFooter = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
})

const SplashImageContainer = styled.div({
  width: '100%',
  height: '100%',
  maxHeight: '424px',
  maxWidth: '427px',
})

const SplashImage = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const Headline = styled.div({
  marginTop: '24px',
  ...typography.headline,
})

const Subheading = styled.div({
  ...typography.subtitle,
})

const BodyItem = styled.div({
  ...typography.bodyRegular,
})

const UnorderedList = styled.ul({
  ...typography.bodyRegular,
  paddingLeft: spacing.X20,
})

const ListItem = styled.li({
  ...typography.bodyRegular,
  listStyleType: 'disc',
})

const BodyItemWrapper = styled.div({
  display: 'inline',
})

const LinkItem = styled(LinkBase)({
  ...typography.bodyLink,
  color: colors.PRIMARY_TDS.REGULAR,
})

type Props = {
  content: NavV2EducationDataType
  onClickPrimary: () => void
  onClickSecondary: () => void
}

const formatMessageBodyText = (intl: IntlShape, entry: NavV2EducationSectionEntry) => {
  const values = {}
  for (const key in entry.placeholders) {
    const placeHolder = entry.placeholders[key]
    const placeHolderText = intl.formatMessage({ id: placeHolder.text })

    if (placeHolder.placeHolderType === NavV2EducationPlaceholderTypes.LINK) {
      values[key] = (
        <LinkItem href={placeHolder.link} target="_blank">
          {placeHolderText}
        </LinkItem>
      )
    } else {
      values[key] = placeHolderText
    }
  }

  return intl.formatMessage({ id: entry.text }, values)
}

const NavV2EducationContent = ({ content, onClickPrimary, onClickSecondary }: Props) => {
  const intl = useIntl()

  if (!content) {
    return null
  }

  const { image, subheading, title, body, primaryButtonText, secondaryButtonText } = content

  return (
    <ModalContainer>
      <ModalColumn>
        <SplashImageContainer>
          <SplashImage src={image} />
        </SplashImageContainer>
      </ModalColumn>
      <ModalColumn>
        <ModalContentSection>
          <Headline data-testid="nav-v2-education-title">
            {intl.formatMessage({ id: title })}
          </Headline>
          <Subheading>{intl.formatMessage({ id: subheading })}</Subheading>
          {body.map((section, index) => {
            const { contents, sectionType } = section
            {
              const key = `${sectionType}-${index}`

              return sectionType === NavV2EducationSectionTypes.LIST ? (
                <UnorderedList key={key}>
                  {contents.map(content => (
                    <ListItem key={content.text}>{formatMessageBodyText(intl, content)}</ListItem>
                  ))}
                </UnorderedList>
              ) : (
                <BodyItemWrapper key={key}>
                  {contents.map(content => (
                    <BodyItem key={content.text}>{formatMessageBodyText(intl, content)}</BodyItem>
                  ))}
                </BodyItemWrapper>
              )
            }
          })}
        </ModalContentSection>
        <ModalButtonFooter>
          {secondaryButtonText && (
            <Button
              data-testid="nav-v2-education-secondary-btn"
              onClick={onClickSecondary}
              variant="tertiary"
            >
              {intl.formatMessage({ id: secondaryButtonText })}
            </Button>
          )}
          <Button data-testid="nav-v2-education-primary-btn" onClick={onClickPrimary}>
            {intl.formatMessage({ id: primaryButtonText })}
          </Button>
        </ModalButtonFooter>
      </ModalColumn>
    </ModalContainer>
  )
}

export default NavV2EducationContent
