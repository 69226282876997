import {
  type DomainAccessControlConfig,
  type DomainAccessControlConfigItem,
} from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const productsViewWarehouseAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.ProductsViewPage],
}

export const productsViewInternalAccessControl: DomainAccessControlConfig = {
  permissions: Permission.InstacartInternal,
}

export const productsViewVBOAccessControl: DomainAccessControlConfig = {
  permissions: productsViewInternalAccessControl.permissions,
}

export const productsViewAccessControl: DomainAccessControlConfig = [
  productsViewWarehouseAccessControl,
  productsViewInternalAccessControl,
] as DomainAccessControlConfigItem[]

export const productsViewItemAnomalyAccessControl: DomainAccessControlConfig = {
  permissions: Permission.ItemAnomalyView,
}

export const productsViewInAssortmentAccessControl: DomainAccessControlConfig = {
  permissions: Permission.ProductAttributesEdit,
}

export const collectionV2AccessControl: DomainAccessControlConfig = {
  permissions: [Permission.RetailerCollectionsV2],
  warehouseFeatureToggles: [FeatureToggle.RetailerCollectionsV2],
}
