import { InsightsPortalSchema } from '../../api'
import { type createSchemaConfig } from '../helpers/schema.helpers'
import { schemaConfig as orderDeliverySchemaConfig } from '../order-delivery'
import { schemaConfig as orderItemSchemaConfig } from '../order-item'
import { schemaConfig as searchTermSchemaConfig } from '../search-term'
import { schemaConfig as imageCoverageSchemaConfig } from '../image-coverage'
import { schemaConfig as inventoryIntelligenceOosSignalsAggSchemaConfig } from '../inventory-intelligence-oos-signals-agg'
import { schemaConfig as searchTermStitchSearchTermByTermBenchmarkSchemaConfig } from '../search-term-stitch-search-term-by-term-benchmark'
import { schemaConfig as orderItemStitchProductByCategoryBenchmarkSchemaConfig } from '../order-item-stitch-product-by-category-benchmark'
import { schemaConfig as factCampaignPerformanceStitchOrderDeliverySchemaConfig } from '../fact-campaign-performance-stitch-order-delivery'
import { schemaConfig as factCampaignPerformanceStitchFactCampaignImpressionsPerformanceSchemaConfig } from '../fact-campaign-performance-stitch-fact-campaign-impressions-performance'
import { schemaConfig as aggMerchCollectionsSchemaConfig } from '../agg-merch-collections'
import { schemaConfig as aggMerchCollectionsRollupSchemaConfig } from '../agg-merch-collections-rollup'
import { schemaConfig as aggMerchPlacementsSchemaConfig } from '../agg-merch-placements'
import { schemaConfig as missingImage90DayRetailerInsightsSchemaConfig } from '../missing-image-90-day-retailer-insights'
import { schemaConfig as aggWarehouseLocationPerformanceSchemaConfig } from '../agg-warehouse-location-performance'
import { schemaConfig as factCampaignPerformanceSchemaConfig } from '../fact-campaign-performance'
import { schemaConfig as adsDisplayBannerSchemaConfig } from '../ads-display-banner'
import { schemaConfig as adsSponsoredProductSchemaConfig } from '../ads-sponsored-product'
import { schemaConfig as futureScheduledOrdersSchemaConfig } from '../future-scheduled-orders'
import { schemaConfig as adsSponsoredProductV2SchemaConfig } from '../ads-sponsored-product-v2'
import { schemaConfig as adsDisplaySchemaConfig } from '../ads-display'
import { schemaConfig as adsPromotionsSchemaConfig } from '../ads-promotions'
import { schemaConfig as adsOverviewSchemaConfig } from '../ads-overview'

/*
  Each individual file in this directory corresponds to the configuration for a specific schema used to build
  insights widgets. This makes it easier for engineers to work with the schema they are interested in, as
  configuration specifics are clearly encapsulated in their respective files.

  All these configuration files are exported through an index file.
*/
export const insightsSchemaConfigs: Partial<
  Record<InsightsPortalSchema, ReturnType<typeof createSchemaConfig>>
> = {
  [InsightsPortalSchema.AdsDisplayBanner]: adsDisplayBannerSchemaConfig,
  [InsightsPortalSchema.AdsSponsoredProduct]: adsSponsoredProductSchemaConfig,
  [InsightsPortalSchema.AdsSponsoredProductV2]: adsSponsoredProductV2SchemaConfig,
  [InsightsPortalSchema.AdsDisplay]: adsDisplaySchemaConfig,
  //[InsightsPortalSchema.AdsPromotions]: adsPromotionsSchemaConfig,
  [InsightsPortalSchema.AdsOverview]: adsOverviewSchemaConfig,

  [InsightsPortalSchema.AggMerchCollections]: aggMerchCollectionsSchemaConfig,

  [InsightsPortalSchema.AggMerchCollectionsRollup]: aggMerchCollectionsRollupSchemaConfig,

  [InsightsPortalSchema.AggMerchPlacements]: aggMerchPlacementsSchemaConfig,

  [InsightsPortalSchema.AggWarehouseLocationPerformance]:
    aggWarehouseLocationPerformanceSchemaConfig,

  [InsightsPortalSchema.FactCampaignPerformance]: factCampaignPerformanceSchemaConfig,

  [InsightsPortalSchema.FactCampaignPerformanceStitchFactCampaignImpressionsPerformance]:
    factCampaignPerformanceStitchFactCampaignImpressionsPerformanceSchemaConfig,

  [InsightsPortalSchema.FactCampaignPerformanceStitchOrderDelivery]:
    factCampaignPerformanceStitchOrderDeliverySchemaConfig,

  [InsightsPortalSchema.ImageCoverage]: imageCoverageSchemaConfig,

  [InsightsPortalSchema.InventoryIntelligenceOosSignalsAgg]:
    inventoryIntelligenceOosSignalsAggSchemaConfig,

  [InsightsPortalSchema.MissingImage_90DayRetailerInsights]:
    missingImage90DayRetailerInsightsSchemaConfig,

  [InsightsPortalSchema.OrderDelivery]: orderDeliverySchemaConfig,

  [InsightsPortalSchema.OrderItem]: orderItemSchemaConfig,

  [InsightsPortalSchema.OrderItemStitchProductByCategoryBenchmark]:
    orderItemStitchProductByCategoryBenchmarkSchemaConfig,

  [InsightsPortalSchema.SearchTerm]: searchTermSchemaConfig,

  [InsightsPortalSchema.SearchTermStitchSearchTermByTermBenchmark]:
    searchTermStitchSearchTermByTermBenchmarkSchemaConfig,

  [InsightsPortalSchema.FutureScheduledOrders]: futureScheduledOrdersSchemaConfig,
}

export type InsightsSchemaConfigs = typeof insightsSchemaConfigs
