import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { searchTermSchemaFilterParamsConfig } from '../search-term/insightsSearchTermSchema.hooks'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
} from '../helpers/schema.helpers'
import { InsightsFormatterType, InsightsGroupByTypes } from '../types/schema.types'
import { Metrics, Dimensions, GroupBys } from './searchTermStitchSearchTermByTermBenchmark.types'

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.SEARCH_CONVERSION]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.searchDashboard.metrics.searchConversion',
    },
    [Metrics.COUNT_SEARCH]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.metrics.countSearch',
    },
    [Metrics.SEARCH_CONVERSION_PCT_DIFF]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.searchDashboard.metrics.searchConversion',
      private: true,
    },
    [Metrics.COUNT_NULL_SEARCH]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.metrics.countNullSearch',
    },
    [Metrics.COUNT_SEARCH_PCT_DIFF]: {
      private: true,
    },
    [Metrics.SEARCH_CONVERSION_TERM_BENCHMARK_CONVERSION_GAP]: {
      private: true,
    },
    [Metrics.DIFF_SEARCH_CONVERSION_TERM_BENCHMARK]: {
      private: true,
    },
    [Metrics.BENCHMARK_SEARCH_CONVERSION]: {
      private: true,
    },
    [Metrics.COUNT_UNCONVERTED_SEARCH]: {
      private: true,
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.termPerformance.header.unconvertedSearches',
    },
    [Metrics.COUNT_CONVERTED_SEARCH]: {
      private: true,
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.termPerformance.convertedSearches',
    },
  },
  dimensionsConfig: {
    [Dimensions.SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
    [Dimensions.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [Dimensions.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.ZONE_STATE]: {
      type: InsightsGroupByTypes.Info,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [Dimensions.POPULAR_SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
    [Dimensions.REGION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [Dimensions.GUEST_USER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      label: 'insightsPortalDomain.filters.guestUser.label',
    },
    ...buildTimeIntervalDimensionsConfig,
  },

  groupBys: GroupBys,
  filterConfigs: searchTermSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    searchTermSchemaFilterParamsConfig
  ),
  groupBysConfig: {
    ...buildTimeIntervalGroupBysConfig,
    [GroupBys.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.SEARCH_TERM]: {
      type: InsightsGroupByTypes.Program,
      label: 'insightsPortalDomain.filters.popularSearchTerm.label',
    },
  },
})
