import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { storeHoursBulkAccessControl } from '../access-control/storeHoursBulkAccess.configuration'

const StoreHoursBulkListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreHoursBulkListPage" */
      '../pages/list/StoreHoursBulkListPage'
    )
)
const StoreHoursBulkEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreHoursBulkEditPage" */
      '../pages/edit/StoreHoursBulkEditPage'
    )
)
const StoreHoursBulkUpdateHistoryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreHoursBulkUpdateHistoryPage" */
      '../pages/update-history/StoreHoursBulkUpdateHistoryPage'
    )
)
const StoreHoursBulkHolidaysList = lazy(
  () =>
    import(
      /* webpackChunkName: "HolidayListPage" */
      '../pages/holidays-list/HolidaysListPage'
    )
)
const StoreHoursBulkHolidaysStoresConfirmedListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreHoursBulkHolidaysStoresConfirmedListPage" */
      '../pages/holidays-stores-confirmed-list/StoreHoursBulkHolidaysStoresConfirmedListPage'
    )
)
// @hygen:inject page-import

export const storeHoursBulkRouter = (
  <DomainRoute route="store-hours-bulk" accessControl={storeHoursBulkAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="store-hours-bulk-list"
        component={StoreHoursBulkListPage}
        scopePicker={{
          titles: ['storeHoursBulkDomain.breadcrumbs.root'],
        }}
      />
      <DomainRoute exact route="store-hours-bulk-edit" component={StoreHoursBulkEditPage} />
      <DomainRoute
        exact
        route="store-hours-bulk-update-history"
        component={StoreHoursBulkUpdateHistoryPage}
      />
      <DomainRoute
        exact
        route="holiday-list"
        component={StoreHoursBulkHolidaysList}
        scopePicker={{
          titles: [
            'storeHoursBulkDomain.breadcrumbs.root',
            'storeHoursBulkDomain.breadcrumbs.holidayHours',
          ],
        }}
      />
      <DomainRoute
        exact
        route="store-hours-bulk-holidays-stores-confirmed-list"
        component={StoreHoursBulkHolidaysStoresConfirmedListPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
