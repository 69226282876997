import React, { useCallback } from 'react'
import { SwitchBase } from '@instacart/ids-core'
import { Accordion, Text } from '@retailer-platform/shared-components'
import { useDashMessages } from '../../../../intl/intl.hooks'
import { useAdminControls } from '../../../../utils/contexts/admin-controls/AdminControlsContext'

export const ShowContactHeader = () => {
  const { showContactHeader, setAdminControl } = useAdminControls()
  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAdminControl('showContactHeader', event.target.checked)
    },
    [setAdminControl]
  )

  const i18n = useDashMessages({
    showNavAccessControlsTitle: 'app.admin.controlPanel.setting.showContactHeader.title',
    showNavAccessControlsDesc: 'app.admin.controlPanel.setting.showContactHeader.description',
  })

  const title = (
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {i18n.showNavAccessControlsTitle}

      <div css={{ flex: 1 }} />

      <div css={{ overflow: 'hidden' }}>
        <SwitchBase
          name="optOutSwitch"
          id="optOutSwitch"
          checked={showContactHeader}
          onClick={evt => evt.stopPropagation()}
          onChange={handleSwitchChange}
        />
      </div>
    </div>
  )

  return (
    <Accordion showContentBackground={true} title={title}>
      <Text size="small">{i18n.showNavAccessControlsDesc}</Text>
    </Accordion>
  )
}
