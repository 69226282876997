export const NAV_V2_OPT_OUT_SURVEY_INPUT_TYPE = {
  CHECKBOX: 0,
  TEXT_INPUT: 1,
} as const

type NavV2OptOutSurveyInput = {
  inputType: ValueOf<typeof NAV_V2_OPT_OUT_SURVEY_INPUT_TYPE>
  subheading: string
  subheadingLabel?: string
  checkboxLabels?: string[]
}

type NavV2OptOutSurveyData = {
  surveyInputs: NavV2OptOutSurveyInput[]
  primaryButtonText: string
  infoBannerText: string
}

export const navV2OptOutSurveyData: NavV2OptOutSurveyData = {
  surveyInputs: [
    {
      inputType: NAV_V2_OPT_OUT_SURVEY_INPUT_TYPE.CHECKBOX,
      subheading: 'navV2.optOutSurvey.checkbox.subheading',
      checkboxLabels: [
        'navV2.optOutSurvey.checkbox.option1',
        'navV2.optOutSurvey.checkbox.option2',
        'navV2.optOutSurvey.checkbox.option3',
        'navV2.optOutSurvey.checkbox.option4',
      ],
    },
    {
      inputType: NAV_V2_OPT_OUT_SURVEY_INPUT_TYPE.TEXT_INPUT,
      subheading: 'navV2.optOutSurvey.textFeedback.subheading',
      subheadingLabel: 'navV2.optOutSurvey.textFeedback.subheading.label',
    },
  ],
  primaryButtonText: 'navV2.optOutSurvey.primaryButton.text',
  infoBannerText: 'navV2.optOutSurvey.disclaimer',
}
