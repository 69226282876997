export enum FeatureToggle {
  InsightsPortalAdsDashboard = 'rt_insights_portal_ads_dashboard',
  InsightsPortalAdsDashboardV2 = 'rt_insights_portal_ads_dashboard_v2',
  InsightsPortalAdsDashboardPromotions = 'rt_insights_portal_ads_dashboard_promotions',
  InsightsPortalDisplayReview = 'rt_insights_portal_display_review',
  InsightsPortalAdsDashboardRetailers = 'rt_insights_portal_ads_dashboard_retailers',
  InsightsCampaignInsights = 'rt_insights_campaign_insights',
  InsightsCampaignInsightsDisabledRetailers = 'rt_insights_campaign_insights_disabled_retailers',
  InsightsHideICStoreConfiguration = 'rt_insights_hide_ic_store_configuration',
  InsightsCollectionInsights = 'rt_insights_collection_insights',
  InsightsCreativeInsights = 'rt_insights_creative_insights',
  InsightsDataExplorerMilestone2 = 'rt_insights_data_explorer_milestone_2',
  InsightsShowCampaignsEducationalBanner = 'rt_show_campaigns_educational_banner',
  InsightsDashboardBuilder = 'rt_insights_dashboard_builder',
  InsightsEntitlementIntegration = 'rt_insights_entitlement_integration',
  InsightsCampaignOffersUXMilestone2Enabled = 'rt_insights_campaign_offers_ux_milestone_2_enabled',
  InsightsCreativesUXMilestone2Enabled = 'rt_insights_creatives_ux_milestone_2_enabled',
  InsightsCollectionsUXMilestone2Enabled = 'rt_insights_collections_ux_milestone_2_enabled',
  InsightsPortalIAMilestone2 = 'rt_insights_portal_ia_milestone_2',
  InsightsPortalSavedViews = 'rt_insights_portal_saved_views',
  InsightsPortalDataExplorerV3 = 'rt_insights_portal_data_explorer_v3',

  // Clickhouse
  ClickhousePerformance = 'rt_insights_clickhouse_performance',
  ClickhouseOutOfStock = 'rt_insights_clickhouse_out_of_stock',
  ClickhouseBasket = 'rt_insights_clickhouse_basket',
  ClickhouseSearch = 'rt_insights_clickhouse_search',
  ClickhouseDataExplorer = 'insights_clickhouse_data_explorer',
  ClickhouseExecutiveSummary = 'rt_insights_clickhouse_executive_summary',

  // Connected Stores' Inventory Intelligence
  InsightsPortalInventoryIntelligence = 'rt_insights_portal_inventory_intelligence',

  // collections
  RetailerCollectionsV2Ux = 'rt_retailer_collections_ux_v2',

  // moments
  campaignV2TemplateMomentEnabled = 'rt_campaigns_v2_template_moment_enabled',

  // Future Scheduled Orders
  FutureScheduledOrders = 'rt_future_scheduled_orders',
}
