import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Button, ServerError, colors, Text } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import Modal from '../../../../../components/Modal'
import Dropdown from '../../../../../components/Dropdown'
import { errors } from '../../../../../../utils/error-handling/errors'
import instacart from '../../../../../common/instacart'
import Slash from '../../../../../components/Slash'
import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import TextField from '../../../../../components/Forms/TextField'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { YES_NO_OPTIONS } from '../../../../../common/constants'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { COST_UNIT_OPTIONS } from '../../../../../common/utils/catalog/units'
import FormField from '../form-components/FormField'

const buttonStyle = {
  marginLeft: '10px',
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 320px;
`

interface Props extends NotificationsContextValue {
  onClose: () => void
  itemIds: number[]
  partnerId: string
  warehouseId: string
  productId: string
}

interface State {
  isBusy: boolean
  isError: boolean
  taxable: boolean
  available: boolean
  priced_by: string
  costPricePerUnit: string
}

class BulkEditProductLocationsModal extends Component<Props, State> {
  readonly state: State = {
    isBusy: false,
    isError: false,
    taxable: false,
    available: false,
    priced_by: '',
    costPricePerUnit: '',
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = async () => {
    const { available, taxable, costPricePerUnit, priced_by } = this.state
    const { partnerId, warehouseId, productId, itemIds } = this.props

    // Cast empty string as undefined so it doesn't serialize.
    const cost =
      costPricePerUnit != null && costPricePerUnit.length > 0 ? costPricePerUnit : undefined

    this.setState({
      isBusy: true,
      isError: false,
    })

    try {
      await instacart.go.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/items/bulk_update`,
        {
          data: {
            item_ids: itemIds,
            available,
            taxable,
            cost_price_per_unit: cost,
            priced_by,
          },
        }
      )
      deprecatedAnalytics.track('product.bulk_edit', {
        productId,
        itemIds,
      })
      this.props.notify(<FormattedMessage id="catalog.products.locations.requestSubmitted" />)
      this.props.onClose()
    } catch (e) {
      errors.captureException(e)

      this.setState({
        isError: true,
        isBusy: false,
      })
    }
  }

  handleAvailableChange = (available: boolean) => {
    this.setState({
      available,
    })
  }

  handleTaxableChange = (taxable: boolean) => {
    this.setState({
      taxable,
    })
  }

  handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      costPricePerUnit: e.target.value,
    })
  }

  handlePricedByChange = (priced_by: string) => {
    this.setState({
      priced_by,
    })
  }

  render() {
    return (
      <Modal height={560} onClose={this.handleClose}>
        {(
          Header: React.ComponentType<React.PropsWithChildren<unknown>>,
          Body: React.ComponentType<React.PropsWithChildren<unknown>>,
          Footer: React.ComponentType<React.PropsWithChildren<unknown>>
        ) => [
          <Header>
            <div>
              Bulk Edit
              <Text
                elementType="span"
                variant="T.18"
                fontWeight="light"
                style={{ color: colors.GRAY_74, marginLeft: 5 }}
              >
                ({this.props.itemIds.length} store locations)
              </Text>
            </div>
          </Header>,
          <Body>
            <Container>
              <InnerContainer>
                {this.state.isError && (
                  <ServerError style={{ width: '100%' }} text="Please try again later" />
                )}
                <FormField label="Available">
                  <Dropdown
                    theme="dark"
                    value={this.state.available}
                    onChange={this.handleAvailableChange}
                    options={YES_NO_OPTIONS}
                  />
                </FormField>
                <FormField label="Taxable">
                  <Dropdown
                    theme="dark"
                    value={this.state.taxable}
                    onChange={this.handleTaxableChange}
                    options={YES_NO_OPTIONS}
                  />
                </FormField>
                <FormField label="Price">
                  <TextField
                    name="costPricePerUnit"
                    prefix="$"
                    value={this.state.costPricePerUnit}
                    onChange={this.handlePriceChange}
                  />
                  <Slash />
                  <Dropdown
                    width="130px"
                    theme="dark"
                    defaultText="Select Unit"
                    value={this.state.priced_by}
                    onChange={this.handlePricedByChange}
                    options={COST_UNIT_OPTIONS}
                  />
                </FormField>
              </InnerContainer>
            </Container>
          </Body>,
          <Footer>
            <Button
              style={buttonStyle}
              onClick={this.handleClose}
              disabled={this.state.isBusy}
              snacksStyle="secondary"
            >
              Cancel
            </Button>
            <Button
              style={buttonStyle}
              onClick={this.onSubmit}
              disabled={this.state.isBusy}
              snacksStyle="primary"
            >
              Save
            </Button>
          </Footer>,
        ]}
      </Modal>
    )
  }
}

export default withTrackEventOnMount({
  id: 'catalog.products.store_locations.bulk_edit.viewed',
  description: 'Viewed the bulk edit section of the product store locations page',
})(withNotificationsContext(BulkEditProductLocationsModal))
