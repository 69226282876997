import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { connectedStoresAccessControl } from '../access-control/connectedStoresAccess.configuration'

const ConnectedStoresAdminCaperPage = lazy(
  () => import('../pages/caper/ConnectedStoresAdminCaperPage')
)
const ConnectedStoresAdminUposPage = lazy(
  () => import('../pages/upos/ConnectedStoresAdminUposPage')
)
const ConnectedStoresAdminShelfLabelsPage = lazy(
  () => import('../pages/shelf-labels/ConnectedStoresAdminShelfLabelsPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const connectedStoresAdminRouter = (
  <DomainRoute route="app-admin-connected-stores" accessControl={connectedStoresAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper"
        component={ConnectedStoresAdminCaperPage}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-shelf-labels"
        component={ConnectedStoresAdminShelfLabelsPage}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-upos"
        component={ConnectedStoresAdminUposPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
